import { useState, Fragment, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useTournamentStore } from '../../../zustand/TournamentZustand';
import useBaseZustand from '../../../zustand/BaseZustand';
import useAuthZustand from '../../../zustand/AuthZustand';


import { Root } from '../../../config/config';

import {
    MainSidebarChapterWrapper,
    MainSidebarItem,
    MainSidebarItemList,
    MainSidebarItemTitle,
    MainSidebarTitle,
    OpenCloseButtonWrapper,
    MainSidebarScroll,
    MainSidebarItemWithBadge
} from './style';

import { ISidebarProps } from './interface';

import TournamentItemImage from '../../../assets/img/tournaments2.svg';
import { useTheme } from 'styled-components';
import { Theme } from '../../../theme';

const ExtendedSidebar = ({ currentItem, handleChooseItem, handleChooseTournament }: ISidebarProps) => {
    const theme = useTheme() as Theme;
    const { t, i18n } = useTranslation();
    const { sidebarTournaments, setAllLocalTournaments, localTournaments } = useTournamentStore((state) => state);
    const { isDesktopSidebar, setDesktopSidebar } = useBaseZustand(
        (state) => state
    );
    const { mainMenuData } = useAuthZustand((state) => state);
    const [showMore, setShowMore] = useState(false);

    const handleOpen = () => {
        if (isDesktopSidebar === true) {
            setDesktopSidebar(false);
        } else {
            setDesktopSidebar(!isDesktopSidebar);
        }
    };

    const handleShowMore = () => {
        if (showMore === false) {
            setShowMore(true);
        } else {
            setShowMore(!showMore);
        }
    };

    useEffect(() => {
        const init = async() => {
            await setAllLocalTournaments()
        }
        init()
    }, [])

    return (
        <>
            <OpenCloseButtonWrapper open={isDesktopSidebar}>
                <Tooltip title={t('Collapse Menu')} placement="right-start">
                    <ArrowForward
                        onClick={handleOpen}
                        sx={{ color: theme.text }}
                    />
                </Tooltip>
            </OpenCloseButtonWrapper>
            <MainSidebarScroll open={isDesktopSidebar}>
                {mainMenuData.map((item, key) => (
                    <Fragment key={key}>
                        <MainSidebarChapterWrapper>
                            <MainSidebarTitle open={isDesktopSidebar}>
                                {t(item.name)}
                            </MainSidebarTitle>
                        </MainSidebarChapterWrapper>
                        {item.data
                            .filter((it) => it.sidebar === true)
                            .map((menuItem, menuKey) => (
                                <Fragment key={menuKey}>
                                    {menuItem.name === 'Tournaments' ? (
                                        <>
                                            <MainSidebarItem
                                                url={
                                                    currentItem ===
                                                        menuItem.url &&
                                                    currentItem
                                                }
                                            >
                                                <MainSidebarItemWithBadge
                                                    onClick={() =>
                                                        handleChooseItem(
                                                            menuItem.url
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={`${Root.adminWebsiteUrl}${menuItem.image}`}
                                                        alt=""
                                                    />
                                                    <Badge
                                                        badgeContent={
                                                            sidebarTournaments.length
                                                        }
                                                    >
                                                        <MainSidebarItemTitle
                                                            open={
                                                                isDesktopSidebar
                                                            }
                                                        >
                                                            {t(menuItem.name)}
                                                        </MainSidebarItemTitle>
                                                    </Badge>
                                                </MainSidebarItemWithBadge>
                                                <ExpandLessIcon
                                                    onClick={handleShowMore}
                                                    sx={{
                                                        transform: showMore
                                                            ? 'rotateX(180deg)'
                                                            : 'unset',
                                                        color: theme.text
                                                    }}
                                                />
                                            </MainSidebarItem>
                                            {showMore ? (
                                                <MainSidebarItemList
                                                    open={isDesktopSidebar}
                                                >
                                                    {sidebarTournaments.map(
                                                        (tournament, tkey) => (
                                                            <MainSidebarItem
                                                                url={
                                                                    currentItem ===
                                                                        `${menuItem.url}/${tournament.ID}` &&
                                                                    currentItem
                                                                }
                                                                key={tkey}
                                                                onClick={() =>
                                                                    handleChooseItem(
                                                                        `${menuItem.url}/${tournament.ID}`
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        TournamentItemImage
                                                                    }
                                                                    alt=""
                                                                />
                                                                <MainSidebarItemTitle
                                                                    open={
                                                                        isDesktopSidebar
                                                                    }
                                                                >
                                                                    {tournament
                                                                        .Name[
                                                                        i18n
                                                                            .language
                                                                    ] ||
                                                                        tournament
                                                                            .Name
                                                                            .en}
                                                                </MainSidebarItemTitle>
                                                            </MainSidebarItem>
                                                        )
                                                    )
                                                    
                                                    }

                                                    {localTournaments.map(
                                                        (tournament, tkey) => (
                                                            <MainSidebarItem
                                                                url={
                                                                    currentItem ===
                                                                        `${menuItem.url}/static/${tournament.ID}` &&
                                                                    currentItem
                                                                }
                                                                key={tkey}
                                                                onClick={() =>
                                                                    handleChooseTournament(
                                                                        `${menuItem.url}/static/${tournament.ID}`, {id: tournament._id} 
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        TournamentItemImage
                                                                    }
                                                                    alt=""
                                                                />
                                                                <MainSidebarItemTitle
                                                                    open={
                                                                        isDesktopSidebar
                                                                    }
                                                                >
                                                                    {t(tournament
                                                                        .Name)}
                                                                </MainSidebarItemTitle>
                                                            </MainSidebarItem>
                                                        )
                                                    )
                                                    
                                                    }

                                                    
                                                </MainSidebarItemList>
                                            ) : null}
                                        </>
                                    ) : (
                                        <MainSidebarItem
                                            url={
                                                currentItem === menuItem.url &&
                                                currentItem
                                            }
                                            onClick={() =>
                                                handleChooseItem(menuItem.url)
                                            }
                                        >
                                            <img
                                                src={`${Root.adminWebsiteUrl}${menuItem.image}`}
                                                alt=""
                                            />
                                            <MainSidebarItemTitle
                                                open={isDesktopSidebar}
                                            >
                                                {t(menuItem.name)}
                                            </MainSidebarItemTitle>
                                        </MainSidebarItem>
                                    )}
                                </Fragment>
                            ))}
                    </Fragment>
                ))}
            </MainSidebarScroll>
        </>
    );
};

export default ExtendedSidebar;
