import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const MobileSubItem = styled(Box).attrs((props: any) => ({
    selected: props.selected
}))`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.8rem 0.5rem 0.5rem 0.5rem;
    cursor: pointer;
    user-select: none;
    line-height: 1.25;
    color: ${(props: any) =>
        props.selected ? '#fa0' : props.theme.text} !important;
    i {
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 25px;
    }
    p {
        font-family: Poppins, sans-serif;
        transition: all 0.2s;
    }
    img {
        width: 25px;
        height: 25px;
    }
`;

export const MobileSubName = styled(Typography)`
    user-select: none !important;
    font-size: 12px !important;
    @media only screen and (max-width: 350px) {
        font-size: 0.7rem !important;
    }
`;

export const MobileFooter = styled(Box)`
    position: fixed;
    bottom: 0;
    background: ${(props) => props.theme.background.secondary};
    width: calc(100% - 2rem);
    height: 4rem;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    z-index: 100;
    left: 0;
    ${(props) =>
        props.theme.mode === 'light'
            ? css`
                  box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2),
                      0px -4px 5px 0px rgba(0, 0, 0, 0.14),
                      0px -1px 10px 0px rgba(0, 0, 0, 0.12);
              `
            : null}
`;
