import { GameItemData } from '../pages/game/types';
import GameZustand from '../zustand/GameZustand';
import { Dispatch, SetStateAction, useState } from 'react';
// import { providerconfig } from '../config/mainConfig';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthZustand from '../zustand/AuthZustand';
import BaseZustand from '../zustand/BaseZustand';
import i18n, { t } from 'i18next';

interface PlayGameProps {
    selectCurrentGame: (gameItem: GameItemData, id: string) => void;
    playGame: (data: any) => void;
    OpenGame: (gameItem: GameItemData) => Promise<void>;
    openDemo: (gameItem: GameItemData) => Promise<void>;
    setShowButtons: Dispatch<SetStateAction<boolean>>;
    url: string;
    showButtons: boolean;
    currentGame: GameItemData | null;
    setCurrentGame: Dispatch<SetStateAction<GameItemData>>
}

const usePlayGame = (): PlayGameProps => {
    const history = useHistory();
    const { openGame } = GameZustand((state) => state);
    const { user, balanceData, isAuth, addtionalProviderConfig } = AuthZustand((state) => state);
    const { setAuthModal } = BaseZustand((state) => state);
    const [game, setGame] = useState<GameItemData | null>(null);
    const [url, setUrl] = useState('');
    const [elementId, setElementId] = useState('');
    const [showButtons, setShowButtons] = useState(false);

    const selectCurrentGame = (gameItem: GameItemData, id: string) => {
        setGame(gameItem);
        setElementId(id);
    };

    const playGame = (data: any) => {
        if (!data) return;
        if (data.status === 1) {
            if (game?.gameType !== addtionalProviderConfig.Poker) {
                if (
                    window.innerWidth >= 1250 &&
                    game?.gameType !== addtionalProviderConfig.Poker
                ) {
                    if (window.innerWidth < 1250) {
                        window.location.replace(data.data);
                    } else {
                        setUrl(data.data);
                    }
                }
            }
        } else {
            if (
                game?.detail.system === '992' ||
                game?.detail.system === '944'
            ) {
                const { gameHtml, gameScript } = data.data;
                if (gameHtml || gameScript) {
                    document.getElementById(elementId).innerHTML = gameHtml
                        .split('amp;')
                        .join('');
                    eval(gameScript);
                } else {
                    history.goBack();
                }
            } else {
                if (window.innerWidth < 1250) {
                    if (
                        game?.provider === '6202af80aa8da4b634fd472d' ||
                        game?.provider === '633d6fa71819bcaba63ddc07'
                    ) {
                        const newData = data.data.split('amp;').join('');
                        const findex = newData.indexOf('src="');
                        const fstring = newData.slice(
                            findex + 5,
                            newData.length
                        );
                        const sindex = fstring.indexOf('"');
                        const sstring = fstring.slice(0, sindex);
                        window.location.replace(sstring);
                    } else {
                        const newData = data.data;
                        const findex = newData.indexOf('text/javascript">');
                        const sindex = newData.indexOf('</script>');
                        const fstring = newData.slice(findex + 18, sindex);
                        eval(fstring);
                    }
                } else {
                    document.getElementById(elementId).innerHTML = data.data
                        .split('amp;')
                        .join('');
                }
            }
        }
    };

    const OpenGame = async (gameItem: GameItemData) => {
        if (!isAuth) {
            toast.warn(String(t('Please login')));
            setAuthModal({ login: true, register: false }, history);
            return;
        }
        if (
            (balanceData.balance[user.currency] && balanceData.balance[user.currency] > 0) ||
            (balanceData.bonus_balance[user.dis_currency] &&
                balanceData.bonus_balance[user.dis_currency] > 0)
        ) {
            const data = await openGame(
                { ...gameItem, locale: i18n.language },
                true
            );
            if (data && data.status >= 0) {
                playGame(data);
                setShowButtons(false);
            } else {
                toast.error(String(t('Failed to open game')));
            }
        } else {
            toast.warn(String(t('Please deposit money')));
        }
    };

    const openDemo = async (gameItem: GameItemData) => {
        const data = await openGame(
            { ...gameItem, locale: i18n.language },
            false
        );
        if (data) {
            playGame(data);
            setShowButtons(false);
        } else {
            toast.error(String(t('Failed to open demo')));
        }
    };

    return {
        selectCurrentGame,
        playGame,
        OpenGame,
        openDemo,
        setShowButtons,
        url,
        showButtons,
        currentGame: game,
        setCurrentGame: setGame
    };
};

export default usePlayGame;
