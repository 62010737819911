import { useContext } from 'react';
import Box from '@mui/material/Box';
import { LoadingContext } from '../../layout/context/loading';
import * as S from './style';

const ContextLoading = () => {
    const { loadingCount } = useContext(LoadingContext);
    return (
        <Box>
            {loadingCount > 0 ? (
                <S.B8866Loading>
                    <S.Load5>
                        <S.Ring2>
                            <S.BallHolder>
                                <S.Ball />
                            </S.BallHolder>
                        </S.Ring2>
                    </S.Load5>
                </S.B8866Loading>
            ) : null}
        </Box>
    );
};

export default ContextLoading;
