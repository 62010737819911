import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFullScreenHandle } from 'react-full-screen';
import IconButton from '@mui/material/IconButton';
import CropFree from '@mui/icons-material/CropFree';
import Close from '@mui/icons-material/Close';
import ZoomInMap from '@mui/icons-material/ZoomInMap';
import { useTranslation } from 'react-i18next';

import AuthZustand from '../../../zustand/AuthZustand';
import BaseZustand from '../../../zustand/BaseZustand';
import GameZustand from '../../../zustand/GameZustand';
import GamePage from './gamePage';
import * as S from '../style';
import { Root } from '../../../config/config';
import GameWidget from './Widgets/GameWidget';
import TournamentLeaderboardWidget from './Widgets/TournamentLeaderboardWidget';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import { useSplitScreen } from '../../../hooks/useSplitScreen';
import { MultipleGamesWrapper } from './MultipleGames/style';
import { RouterContext } from '../../../context/RouterContext';
import { useTheme } from 'styled-components';
import { Theme } from '../../../theme';
import { generateBackgroundImageByThemeMode } from '../../../helpers';
// import WalletZustand from '../../../zustand/WalletZustand';

const GameModal: React.FC = () => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();
    const handle = useFullScreenHandle();
    const history = useHistory();
    const { user, balanceData, logoImage, currencies, isAuth, priceData } =
        AuthZustand((state) => state);
    const { RtFixed, CalcRtFixed, gamemode, setGameModal } = BaseZustand(
        (state) => state
    );
    const { game, setCurrentProviders, removeCurrentProviders, resetGame } =
        GameZustand((state) => state);
    // const { setPraxisModalOpen } = WalletZustand((state) => state);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [openWidget, setOpenWidget] = useState(false);
    const { handleAddScreen, renderComponents, renderCount, clearScreens } =
        useSplitScreen();

    const routerHistory = useContext(RouterContext);
    const backgroundImage = generateBackgroundImageByThemeMode(theme.mode);
    const goBack = () => {
        if (history.location.pathname !== '/game') {
            history.goBack();
        } else {
            const previousLocation = routerHistory
                .reverse()
                .find(({ from, to }) => to === '/game' && from !== '/game');
            history.push(previousLocation.from);
        }
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            handle.enter();
        } else {
            handle.exit();
        }
        setIsFullScreen(!isFullScreen);
    };

    useEffect(() => {
        if (game?.provider.length) {
            setCurrentProviders(game?.provider);
        }
    }, []);

    useEffect(() => {
        if (history.location.pathname !== '/game') {
            setGameModal(false);
        }
    }, [history.location.pathname]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight === window.screen.height) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const closeFunc = () => {
        clearScreens();
        setGameModal(false);
        setIsFullScreen(false);
        handle.exit();
        goBack();
        resetGame();
        removeCurrentProviders();
    };

    return (
        <>
            {gamemode ? (
                <S.FullScreenWrapp handle={handle}>
                    <S.GameBox
                        onDoubleClick={toggleFullScreen}
                        img={backgroundImage}
                    >
                        <S.GameHeader allScreen={String(isFullScreen)}>
                            <S.HeaderBox2>
                                <S.HeaderBox isAuth={isAuth} onClick={closeFunc}>
                                    <S.Logo
                                        src={`${Root.adminWebsiteUrl}${logoImage}`}
                                        onClick={() => history.push('/')}
                                        alt=""
                                    />
                                </S.HeaderBox>
                                {isAuth ? (
                                    <>
                                        <S.User>
                                            <S.BonusBalanceShowPart>
                                                <S.BalanceIn
                                                    style={{
                                                        color: theme.text
                                                    }}
                                                >
                                                    {t('Balance')}
                                                </S.BalanceIn>
                                                <S.BalancePart>
                                                    <S.BalanceImg src={`https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/${user.dis_currency}.svg`} />
                                                    <S.Balance
                                                        style={{
                                                            color: theme.text
                                                        }}
                                                    >
                                                        {user.currency !==
                                                        user.dis_currency
                                                            ? CalcRtFixed(
                                                                  balanceData.balance[
                                                                      user.currency
                                                                  ],
                                                                  user.currency,
                                                                  user.dis_currency,
                                                                  priceData
                                                              )
                                                            : RtFixed(
                                                                  balanceData
                                                                      .balance[
                                                                      user
                                                                          .currency
                                                                  ],
                                                                  user.currency,
                                                                  currencies
                                                              )}
                                                    </S.Balance>
                                                    &nbsp;
                                                    <S.Balance
                                                        style={{
                                                            color: theme.text
                                                        }}
                                                    >
                                                        {user.dis_currency}
                                                    </S.Balance>
                                                </S.BalancePart>
                                            </S.BonusBalanceShowPart>
                                        </S.User>
                                        <S.User>
                                            <S.BonusBalanceShowPart>
                                                <S.BalanceIn
                                                    style={{
                                                        color: theme.text
                                                    }}
                                                >
                                                    {t('Bonus')}
                                                </S.BalanceIn>
                                                <S.BalancePart>
                                                    <S.BalanceImg src={`https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/${user.dis_currency}.svg`}/>
                                                    <S.Balance
                                                        style={{
                                                            color: theme.text
                                                        }}
                                                    >
                                                        {RtFixed(
                                                            balanceData.bonus_balance &&
                                                                balanceData
                                                                    .bonus_balance[
                                                                    user
                                                                        .dis_currency
                                                                ]
                                                                ? balanceData
                                                                      .bonus_balance[
                                                                      user
                                                                          .dis_currency
                                                                  ]
                                                                : 0,
                                                            user.dis_currency,
                                                            currencies
                                                        )}
                                                    </S.Balance>
                                                    &nbsp;
                                                    <S.Balance
                                                        style={{
                                                            color: theme.text
                                                        }}
                                                    >
                                                        {user.dis_currency}
                                                    </S.Balance>
                                                </S.BalancePart>
                                            </S.BonusBalanceShowPart>
                                            <S.DepositBtn
                                                variant="contained"
                                                // onClick={() => setPraxisModalOpen(true)}
                                                onClick={() => {
                                                    history.push('/wallet')
                                                }}
                                            >
                                                {t('Deposit')}
                                            </S.DepositBtn>
                                        </S.User>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <S.BonusBalanceShowPart
                                    onClick={handleAddScreen}
                                >
                                    <S.BalanceIn>
                                        <SplitscreenIcon
                                            sx={{ color: theme.text }}
                                        />
                                    </S.BalanceIn>
                                    {t('Split')}
                                </S.BonusBalanceShowPart>
                                {!isFullScreen ? (
                                    <IconButton onClick={() => handle.enter()}>
                                        <CropFree sx={{ color: theme.text }} />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => handle.exit()}>
                                        <ZoomInMap sx={{ color: theme.text }} />
                                    </IconButton>
                                )}
                                <IconButton onClick={closeFunc}>
                                    <Close sx={{ color: theme.text }} />
                                </IconButton>
                            </S.HeaderBox2>
                        </S.GameHeader>
                        <S.TopIdHeader allScreen={String(isFullScreen)}>
                            <MultipleGamesWrapper renderCount={renderCount} isFullScreen={isFullScreen}>
                                <GamePage
                                    setGameModal={setGameModal}
                                    setAllScreen={setIsFullScreen}
                                    handle={handle}
                                    allScreen={isFullScreen}
                                    renderCount={renderCount}
                                />
                                {renderComponents(isFullScreen)}
                            </MultipleGamesWrapper>
                        </S.TopIdHeader>
                        {!isFullScreen ? (
                            <S.AccordionsWrapper openWidget={openWidget}>
                                <S.ShowMoreBtn
                                    onClick={() => setOpenWidget(!openWidget)}
                                >
                                    {openWidget
                                        ? t('Hide more')
                                        : t('Show more')}
                                </S.ShowMoreBtn>
                                <S.WidgetsWrapper>
                                    <GameWidget />
                                    <TournamentLeaderboardWidget />
                                </S.WidgetsWrapper>
                            </S.AccordionsWrapper>
                        ) : null}
                    </S.GameBox>
                </S.FullScreenWrapp>
            ) : (
                <></>
            )}
        </>
    );
};

export default GameModal;
