import create from 'zustand';
import ConfigApi from '../api/ConfigApi';

const useStore = create(() => ({
    // SliderList in Dashboard
    getHomePageContentData: async () => {
        const res = await ConfigApi.getHomePageContentData();
        if (res) {
            return res.data;
        } else {
            return [];
        }
    },

    // Promo Image list in dashboard
    getHomePageGameData: async () => {
        const res = await ConfigApi.getHomePageGameData();
        if (res) {
            return res.data;
        } else {
            return [];
        }
    },

    // getPokerSliderList in Poker Page
    getPokerSliderList: async () => {
        const res = await ConfigApi.getPokerSliderList();
        if (res) {
            return res.data;
        } else {
            return [];
        }
    },

    // Support Data in Support Page
    getSupportData: async (data) => {
        const res = await ConfigApi.getSupportData(data);
        if (res) {
            return res.data;
        } else {
            return [];
        }
    },
    getSupportTypes: async () => {
        const res = await ConfigApi.getSupportTypes();
        if (res) {
            return res.data;
        } else {
            return [];
        }
    },

    //  ------------------------------------------------------------------

    // Notification Management Part
    readAllNotification: async () => {
        const res = await ConfigApi.readAllNotification();
        if (res) {
            return true;
        } else {
            return false;
        }
    },

    //  ------------------------------------------------------------------

    // Site Url Check (Activate accouont)
    getUrlParseData: async (data) => {
        const res = await ConfigApi.getUrlParseData(data);
        if (res) {
            return res.data;
        } else {
            return false;
        }
    },

    // Show Our site Payment List to payment page
    getAllGatewayListForPayment: async () => {
        const rdata = await ConfigApi.getAllGatewayListForPayment();
        if (rdata) {
            return rdata.data;
        } else {
            return [];
        }
    },

    // Get Promotion general terms and condition data
    getPromotionTCList: async () => {
        const rdata = await ConfigApi.getPromotionTCList();
        if (rdata) {
            return rdata.data;
        } else {
            return [];
        }
    },

    getMultilang: async () => {
        const rdata = await ConfigApi.getMultilang();
        if (rdata) {
            return rdata.data;
        } else {
            return [];
        }
    },

    getManifest: async () => {
        const rdata = await ConfigApi.getManifest();
        return rdata || {};
    }
}));

export default useStore;
