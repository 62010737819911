import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    CurrentUserProgression,
    TournamentLeaderboardPaginationWrapper,
    TournamentLeaderBoardTableCell,
    TournamentLeaderBoardTableRow,
    TournamentLeaderBoardTableWrapper,
    TournamentLeaderBoardWrapper
} from './style';
import { TournamentItemTitle } from '../../style';
import { ITournamentLeaderboard } from './types';
import FirstPlaceSVG from '../../../../assets/img/1st-place.svg';
import SecondPlaceSVG from '../../../../assets/img/2nd-place.svg';
import ThirdPlaceSVG from '../../../../assets/img/3rd-place.svg';
import {
    handleNextPage,
    useTournamentStore
} from '../../../../zustand/TournamentZustand';
import LeaderboardPagination from './LeaderboardPagination';
import { IUser } from '../../../../types';
import AuthZustand from '../../../../zustand/AuthZustand';
import { useTranslation } from 'react-i18next';

export interface ILeaderboard {
    data: ITournamentLeaderboard[];
    title: string;
    showPlaceColor?: boolean;
    pagination?: boolean;
    currentUserProgression?: any;
}
const TournamentLeaderBoard = ({
    data,
    title,
    showPlaceColor,
    pagination,
    currentUserProgression
}: ILeaderboard) => {
    const { t } = useTranslation();
    const { totalCount, limit } = useTournamentStore((state) => state);
    const { user }: IUser | any = AuthZustand((state) => state);

    const checkUserPlace = (place) => {
        if (place === 1) {
            return <img width={50} src={FirstPlaceSVG} alt="" />;
        } else if (place === 2) {
            return <img width={50} src={SecondPlaceSVG} alt="" />;
        } else if (place === 3) {
            return <img width={50} src={ThirdPlaceSVG} alt="" />;
        } else {
            return <p style={{ margin: 10, fontSize: 14 }}>{place}</p>;
        }
    };

    let proggression = null;

    if (currentUserProgression) {
        proggression = (
            <CurrentUserProgression>
                <p>{currentUserProgression.Place}</p>
                <p>{user.username}</p>
                <p>{currentUserProgression.Points}</p>
            </CurrentUserProgression>
        );
    }

    return (
        <TournamentLeaderBoardWrapper
            className="TournamentLeaderBoardWrapper"
            style={{
                maxWidth: showPlaceColor === false ? '400px' : '600px'
            }}
        >
            {data.length > 0 ? (
                <>
                    <TournamentItemTitle>{title}</TournamentItemTitle>
                    <br />
                    <TournamentLeaderBoardTableWrapper>
                        <TableContainer
                            component={Paper}
                            sx={{
                                background: 'transparent',
                                boxShadow: 'none',
                                overflow: 'hidden'
                            }}
                        >
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TournamentLeaderBoardTableCell align="center">
                                            {t('Place')}
                                        </TournamentLeaderBoardTableCell>
                                        <TournamentLeaderBoardTableCell align="center">
                                            {t('Player')}
                                        </TournamentLeaderBoardTableCell>
                                        <TournamentLeaderBoardTableCell align="center">
                                            {t('Points')}
                                        </TournamentLeaderBoardTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map(
                                        (
                                            row: ITournamentLeaderboard,
                                            idx: number
                                        ) => (
                                            <TournamentLeaderBoardTableRow
                                                key={idx}
                                            >
                                                <TournamentLeaderBoardTableCell
                                                    align="center"
                                                    place={parseInt(
                                                        row.IDUserPlace
                                                    )}
                                                >
                                                    {row.IDUserPlace === null
                                                        ? '-'
                                                        : checkUserPlace(
                                                              parseInt(
                                                                  row.IDUserPlace
                                                              )
                                                          )}
                                                </TournamentLeaderBoardTableCell>
                                                <TournamentLeaderBoardTableCell
                                                    align="center"
                                                    place={parseInt(
                                                        row.IDUserPlace
                                                    )}
                                                >
                                                    {row.Login}
                                                </TournamentLeaderBoardTableCell>
                                                <TournamentLeaderBoardTableCell
                                                    align="center"
                                                    place={parseInt(
                                                        row.IDUserPlace
                                                    )}
                                                >
                                                    {row.Points}
                                                </TournamentLeaderBoardTableCell>
                                            </TournamentLeaderBoardTableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {data.find(
                            (item) => Number(item.IDUser) === user.softId
                        )
                            ? null
                            : proggression}
                        <br />
                        {pagination ? (
                            <TournamentLeaderboardPaginationWrapper>
                                <LeaderboardPagination
                                    handlePageClick={(e) =>
                                        handleNextPage(e, totalCount, limit)
                                    }
                                    totalCount={totalCount}
                                    itemsPerPage={5}
                                />
                            </TournamentLeaderboardPaginationWrapper>
                        ) : null}
                    </TournamentLeaderBoardTableWrapper>
                </>
            ) : (
                <>
                    <TournamentItemTitle>
                        {t('Be the first one to join the tournament!')}
                    </TournamentItemTitle>
                </>
            )}
        </TournamentLeaderBoardWrapper>
    );
};
export default TournamentLeaderBoard;
