import { ITournament } from './types';

export const currentTimeFormatter = (
    date: string,
    isToIsoString?: boolean
): string | Date => {
    const currentDate: Date = new Date(date + 'Z');
    if (isToIsoString) {
        return currentDate.toISOString();
    } else {
        return currentDate;
    }
};

export const staticTournament: ITournament = {
    ID: 'static/1',
    Series: '',
    Name: {
        en: 'Drops and Wins',
        '{}': 'May tournament event'
    },
    Description: {
        en: ''
    },
    Selected: 0,
    Qualified: 0,
    WinnerBy: 'bets',
    PointsTotal: '0.00',
    PointsLimit: 0,
    PointsLimitMin: 0,
    FeeType: 'balance',
    FeeAmount: {
        EUR: '0',
        Currency: 0
    },
    Qualification: '0',
    BetMin: {
        BRL: '',
        CZK: '',
        PLN: '',
        RUB: '',
        USD: '',
        EUR: ''
    },
    BetMax: {
        BRL: '',
        CZK: '',
        PLN: '',
        RUB: '',
        USD: '',
        EUR: ''
    },
    Repeat: '1 day',
    Target: 'balance',
    Type: 'absolute',
    Status: '1',
    Weight: '99999',
    Games: {
        Games: [],
        Categories: [16],
        Merchants: [960],
        GamesBL: [],
        CategoriesBL: [],
        MerchantsBL: []
    },
    FreeroundGames: '',
    WinToBetRatio: '0.00',
    WinningBonusesID: [],
    RemainingTime: 81652,
    CurrentTime: 1684247948,
    WinningSpread: {
        Percent: ['60', '30', '10'],
        EUR: [600, 300, 100],
        Currency: [600, 300, 100]
    },
    Terms: {
        en: ''
    },
    Image: {
        en: 'https://admin.slotmaniax.com/image/site/tournament/83c665f0c3192fa8be9602e6f6fa8706.png'
    },
    Image_promo: {
        en: 'https://admin.slotmaniax.com/image/site/tournament/99bf9f1cdf3d404e75f64671ea2e325f.png'
    },
    Image_dashboard: {
        en: 'https://res.cloudinary.com/yoju/image/upload/network%20tournaments/drops_and_wins_slider.webp'
    },
    Image_description: {
        en: 'https://res.cloudinary.com/yoju/image/upload/network%20tournaments/drops_and_wins_slider.webp'
    },
    Image_other: {
        en: 'https://res.cloudinary.com/yoju/image/upload/network%20tournaments/drops_and_wins_slider.webp'
    }
};
