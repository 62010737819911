import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';

const projectId = 'b9d644fb397b68d6ce9c7dd79082aacd';

const chains = [
    {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com'
    },
    {
        chainId: 11155111,
        name: 'Ethereum Sepolia Testnet',
        currency: 'ETH',
        explorerUrl: 'https://sepolia.etherscan.io',
        rpcUrl: 'https://sepolia.drpc.org'
    }
];

const metadata = {
    name: 'Slotmaniax',
    description: 'Blockchain Casino platform',
    url: 'https://alphatest.live',
    icons: ['https://alphatest.live/']
};

createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains,
    projectId
});
