import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TournamentItemTitle, TournamentPageContainer } from '../style';
import { useTournamentStore } from '../../../zustand/TournamentZustand';
import {
    CurrentTournamentWrapper,
    CurrentTournamentInfoTextWrapper,
    CurrentTournamentInfoWrapper
} from '../CurrentTournament/style';
import { currentTimeFormatter } from '../heplers';
import TournamentGames from '../CurrentTournament/TournamentGame';
import TournamentLeaderBoard from '../CurrentTournament/TournamentLeaderBoard';
import { IParams } from '../CurrentTournament/types';
import { RandomTournamentHeaderBtn } from '../../dashboard/RandomTournament/style';
import TournamentCard from '../TournamentCard';
import { useTranslation } from 'react-i18next';
import AuthZustand from '../../../zustand/AuthZustand';
import { WinningInfoWrapper } from './style';
import useTournamentGamesInfiniteScroll from '../../../hooks/useTournamentGames';

const PastCurrentTournament = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { user } = AuthZustand((state) => state);
    const {
        pastCurrentTournament: tournament,
        getPastTournament,
        resetPastCurrentTournament,
        getTournamentGames,
        getTournamentLeaderboard,
        tournamentLeaderboard,
        limit,
        offset
    } = useTournamentStore((state) => state);

    const params: IParams = useParams();
    const now = new Date();
    const winningsInfo = tournament?.Leaderboard;

    const {
        hasMoreGames,
        setCurrentPage,
        currentTournamentGames
    } = useTournamentGamesInfiniteScroll({
        tournament,
        getTournamentGames
    });

    useEffect(() => {
        if (parseInt(params.id) !== tournament?.ID) {
            resetPastCurrentTournament();
        }
        getPastTournament(params.id);
    }, [params.id, tournament?.ID]);

    useEffect(() => {
        getTournamentLeaderboard(params.id, limit, offset);
    }, [params.id, limit, offset]);

    const handleBackToTournamentList = () => {
        history.push('/tournament');
    };

    if (!tournament) {
        return (
            <TournamentPageContainer maxWidth="xl">
                <CurrentTournamentWrapper>
                    <TournamentItemTitle>
                        Tournament description missing
                    </TournamentItemTitle>
                    <span>
                        We have a problems on server. Please wait when we fix
                        it!
                    </span>
                </CurrentTournamentWrapper>
            </TournamentPageContainer>
        );
    }

    return (
        <TournamentPageContainer maxWidth="xl">
            <RandomTournamentHeaderBtn
                variant="contained"
                onClick={handleBackToTournamentList}
            >
                {t('BACK')}
            </RandomTournamentHeaderBtn>
            <CurrentTournamentWrapper>
                {winningsInfo
                    .filter((item) => Number(item.IDUser) === user.softId)
                    .map((item) => (
                        <WinningInfoWrapper key={item.IDUser}>
                            {
                                tournament.WinningSpread[item.IDUserPlace.split('-')[0] - 1] ? <>
                                    <TournamentItemTitle>
                                        You are winner of this tournament
                                    </TournamentItemTitle>
                                    <span>Your winnings on the tournament card</span>
                                </> : <></>
                            }
                        </WinningInfoWrapper>
                    ))}
                <TournamentCard
                    isPastTournament={true}
                    tournament={tournament}
                    fullSize={true}
                    hasSubscribeButton={false}
                    hasShowMoreButton={false}
                />
                <CurrentTournamentInfoWrapper>
                    <CurrentTournamentInfoTextWrapper>
                        <TournamentItemTitle>
                            {tournament.Description.en || ''}
                        </TournamentItemTitle>
                        {tournament &&
                            now >
                            currentTimeFormatter(tournament.Starts, false)}
                    </CurrentTournamentInfoTextWrapper>
                    <TournamentLeaderBoard
                        pagination={true}
                        showPlaceColor={true}
                        data={tournamentLeaderboard}
                        title={'Tournament leaderboard'}
                    />
                </CurrentTournamentInfoWrapper>
                <TournamentGames
                    tournamentGames={currentTournamentGames}
                    title={'Tournament games'}
                    setCurrentPage={setCurrentPage}
                    hasMoreGames={hasMoreGames}
                />
            </CurrentTournamentWrapper>
        </TournamentPageContainer>
    );
};

export default PastCurrentTournament;
