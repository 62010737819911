import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TabPanel from '../../../../pages/base/tab';

export const User = styled(Box)`
    background: ${(props) => props.theme.background.secondary};
    border-radius: 8px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
    ${(props) =>
        props.theme.mode === 'light'
            ? css`
                  border: 1px solid ${(props) => props.theme.borderColor};
              `
            : null}
`;
export const Balance = styled(Box)`
    font-family: PT Sans !important;
    font-weight: bold;
    color: ${(props) => props.theme.text};
    font-size: 1rem;
    user-select: none;
    line-height: unset;
    display: flex;
    align-items: center;
    padding: 0.7rem 0.5rem 0.7rem 1rem;
    cursor: pointer;
    transition: all 0.5s;
    p {
        margin: 0;
        text-align: center;
        @media only screen and (max-width: 500px) {
            font-size: 14px !important;
        }
    }
    svg {
        font-size: 2rem;
    }
`;
export const BalanceImg = styled.img`
    width: 25px;
    height: 25px;
    border-radius: 100%;
`;
export const DepositBtn = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px;
    width: auto;
    margin-right: 0.5rem !important;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryButton};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
        font-size: 15px;
        background-color: ${({ theme }) => theme.primaryButton};
    }
    @media only screen and (max-width: 500px) {
        font-size: 10px !important;
        margin-right: 0.5rem;
        padding: 0.5rem 0.5rem !important;
        &:hover {
            font-size: 10px;
        }
    }
    @media only screen and (max-width: 400px) {
        margin-right: 0.2rem;
        padding: 0.5rem 0.2rem;
        &:hover {
            font-size: 10px;
        }
    }
`;
export const ProfileHeader = styled(Box)`
    .MuiTabs-scroller {
        border-bottom: 1px solid #949494 !important;
    }
    .MuiTabs-indicator {
        background-color: ${({ theme }) => theme.primaryButton} !important;
        height: 3px;
    }
    button {
        color: ${({ theme }) => theme.text} !important;
        font-family: League Spartan, Cyrillic !important;
    }
`;
export const TabPanelWrapp = styled(TabPanel)`
    border-bottom: 1px solid #949494 !important;
    max-height: 50vh;
    overflow-y: scroll;
`;
export const BalancePart = styled(Box)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text};
    cursor: pointer;
    padding: 0.2rem 1rem;
    &:hover {
        background: ${({ theme }) => theme.background.secondary};
    }
`;
export const BalanceAmount = styled(Typography)`
    width: 60%;
    padding: 5px 0;
    user-select: none;
    font-family: PT Sans !important;
`;
export const BalanceName = styled(Typography)`
    width: 40%;
    padding: 5px 0;
    user-select: none;
    font-family: PT Sans !important;
`;
export const SwitchBox = styled(Box)`
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 0;
    cursor: pointer;
    .Mui-checked {
        color: ${({ theme }) => theme.primaryButton} !important;
    }
    .MuiSwitch-track {
        background-color: #d8d8d8 !important;
    }
`;

export const SwitchName = styled(Typography)`
    color: ${({ theme }) => theme.text};
    user-select: none;
    font-family: PT Sans !important;
`;
