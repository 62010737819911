import create from 'zustand';
import GameApi from '../api/GameApi';
import { GameItemData } from '../pages/game/types';

const defaultGame = {
    _id: '',
    LAUNCHURLID: 1,
    currencys: [],
    gameId: '',
    gameName: '',
    gameType: '',
    imageUrl: '',
    optimizedImageUrl: '',
    featuredImageUrl: '',
    optimizedFeaturedImageUrl: '',
    tournamentImageUrl: '',
    isdesktop: true,
    ismobile: true,
    isonline: true,
    order: 0,
    provider: '',
    status: true,
    isJackpot: false,
    isNew: false,
    isTop: false,
    detail: {
        HasDemo: false,
        system: '',
        MobilePageCode: '',
        PageCode: '',
        RTP: 95
    }
};

const useGameStore = create((set: any) => ({
    game: defaultGame,
    provider: {
        currency: [],
        providerName: '',
        RTP: 95,
        _id: ''
    },
    searchValue: '',
    currentProviders: [],

    setSearchValue: (value) => {
        set({ searchValue: value });
    },

    setCurrentProviders: (value) => {
        set((state) => ({
            currentProviders: [...state.currentProviders, value]
        }));
    },

    removeProviderById: (provider) => {
        set((state) => ({
            currentProviders: state.currentProviders.filter(
                (item) => item !== provider
            )
        }));
    },

    removeCurrentProviders: () => {
        set((state) => ({
            currentProviders: (state.currentProviders = [])
        }));
    },

    // Get Game Provider Information
    getProviders: async (reqData: string | object) => {
        const rdata = await GameApi.getProviders(reqData);
        if (rdata) {
            return rdata.data;
        }
    },

    //  ------------------------------------------------------------------

    // Game Relate Part
    getGames: async (reqData: object) => {
        const rdata = await GameApi.getGames(reqData);
        if (rdata) {
            return rdata.data;
        }
    },

    // Get game by id
    getGamesById: async (gameId) => {
        const response = await GameApi.getGamesById(gameId);
        return response;
    },

    getGamesCategories: async (gameType) => {
        return GameApi.getGamesCategories(gameType);
    },

    resetGame: () => {
        set({ game: defaultGame })
    },

    selectGame: (game, provider) => {
        set({ game, provider });
    },
    openGame: async (gameItem: GameItemData, type: boolean) => {
        let rdata;
        if (type) {
            rdata = await GameApi.openGame(gameItem);
        } else {
            rdata = await GameApi.openDemoGame(gameItem);
        }
        if (rdata) {
            if (gameItem.LAUNCHURLID === 1) {
                return { status: 0, data: rdata.data };
            } else {
                return { status: 1, data: rdata.data.url };
            }
        } else {
            return false;
        }
    },
    openSportsGame: async (data) => {
        const rdata = await GameApi.openSportsGame(data);
        if (rdata) {
            return rdata.data.url;
        } else {
            return false;
        }
    },

    //  ------------------------------------------------------------------

    // Game Bet History Part
    getGameHistory: async (data: object) => {
        const rdata = await GameApi.getGameHistory(data);
        if (rdata) {
            return rdata.data;
        } else {
            return [];
        }
    },
    getSportHistory: async (data: object) => {
        const rdata = await GameApi.getSportHistory(data);
        if (rdata) {
            return rdata.data;
        } else {
            return [];
        }
    },
    getBonusGameHistory: async (data: object) => {
        const rdata = await GameApi.getBonusGameHistory(data);
        if (rdata) {
            return rdata.data;
        } else {
            return [];
        }
    },
    getProviderList: async () => {
        const res = await GameApi.getProviderList();
        if (res) {
            return res.data;
        } else {
            return [];
        }
    }
}));

export default useGameStore;
