import styled from 'styled-components';
import { PromotionsHeaderBtn } from '../Promotions/style';

export const RandomTournamentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    @media (max-width: 800px) {
        width: unset;
        padding: 0;
    }
`;

export const RandomTournamentHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const RandomTournamentHeaderBtn = styled(PromotionsHeaderBtn)`
    width: unset;
    color: ${(props) => props.theme.text} !important;
    padding: 6px 16px !important;
`;

export const RandomTournamentItemWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const RandomTournamentTitle = styled.p`
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.text};
    font-weight: 700;
    font-size: 17px;
    font-family: 'League Spartan', 'Cyrillic', sans-serif;
`;
