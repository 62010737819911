import { createContext } from 'react';
import { ThemeContextProps } from '../types';
import { Theme } from '../theme';

export const ThemeContext = createContext<ThemeContextProps>({
    theme: 'light',
    toggleTheme: () => {},
    setHeaderHeight: () => {},
    setCustomMainTheme: () => {},
    currentTheme: {} as Theme,
    customTheme: {} as Theme
});
