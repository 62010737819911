import styled from 'styled-components';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dehaze from '@mui/icons-material/Dehaze';

export const AppBarWrapp = styled(AppBar).attrs((props: any) => ({
    open: props.open,
    page: props.page
}))`
    width: ${(props) =>
        props.page === 'sport'
            ? `calc(100% - ${(props) => (props.open ? '240px' : '60px')})`
            : '100%'};
    transition: all 0.1s;
    background: ${(props) => props.theme.background.primary} !important;
    z-index: 100 !important;
    box-shadow: none;
    @media only screen and (max-width: 500px) {
        .MuiContainer-root {
            padding-left: 4px !important;
            padding-right: 4px !important;
        }
    }
`;
export const MainHeader = styled(Box)`
    display: flex !important;
    justify-content: space-between !important;
    padding: 0 1rem !important;
    min-height: 64px !important;
    position: relative;
    @media only screen and (max-width: 500px) {
        padding: 0 0.5rem !important;
    }
`;
export const HeaderBox = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
export const Logo = styled.img`
    max-width: 150px;
    cursor: pointer;
`;
export const HeaderMenuBox = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;
export const DehazeWrapp = styled(Dehaze)`
    color: ${(props) => props.theme.text};
    font-size: 1.5rem;
    cursor: pointer;
`;
export const AuthBtn = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 63px !important;
    position: absolute;
    right: 0;
    top: 0;
    @media only screen and (max-width: 500px) {
        width: 100%;
        justify-content: flex-end;
        position: unset;
    }
`;
export const LoginBtn = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px !important;
    width: auto;
    margin: 0;
    cursor: pointer;
    background-color: transparent !important;
    border: 2px solid ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    //color: #fff;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
        font-size: 15px !important;
        background-color: ;
    }
    @media only screen and (max-width: 500px) {
        font-size: 12px !important;
    }
`;
export const RegisterBtn = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px !important;
    width: auto;
    margin-left: 0.5rem !important;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryButton};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
        font-size: 15px !important;
        background-color: ${({ theme }) => theme.primaryButton};
    }
    @media only screen and (max-width: 500px) {
        font-size: 12px !important;
        margin-right: 0.5rem;
    }
`;
export const IntlContent = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    @media only screen and (max-width: 600px) {
        display: none;
    }
`;
export const BackIcon = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 12px !important;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const BackBtn = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 63px !important;
    position: absolute;
    left: 0;
    top: 0;
    @media only screen and (max-width: 500px) {
        width: 100%;
        justify-content: flex-end;
        position: unset;
    }
`;
