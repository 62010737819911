import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import { ExtendedLetterInput } from '../userDashboard/history/style';

export const AuthContent = styled(Box)`
    width: 450px;
    margin: auto;
    @media (max-width: 500px) {
        width: 100% !important;
    }
`;
export const RegisterContent = styled(Box)`
    width: 800px;
    margin: auto;
    & .MuiAutocomplete-popper .MuiAutocomplete-listbox {
        color: ${(props) => props.theme.borderColor} !important;
        background: ${(props) => props.theme.background.secondary} !important;
    }
    & .MuiAutocomplete-popper li {
        color: ${(props) => props.theme.borderColor} !important;
        background: ${(props) => props.theme.background.secondary} !important;
    }
    @media (max-width: 900px) {
        width: 100% !important;
    }
    img {
        width: 100%;
    }
`;
export const Letter = styled(Typography)`
    text-align: center;
    margin: 2rem 0 0 !important;
    user-select: none;
    font-size: 20px !important;
    font-weight: 700;
    color: ${({ theme }) => theme.text};
    font-family: 'PT Sans' !important;
`;
export const InputHeader = styled(Box)`
    width: 100% !important;
    position: relative !important;
    color: #bdbdbd !important;
    text-align: center !important;
    font-size: 14px !important;
    display: flex !important;
`;
export const Condition = styled(Box)`
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    color: rgb(216, 216, 216);
    margin-top: 0.5rem;
    span {
        padding: 0;
        color: rgb(216, 216, 216);
    }
    .Mui-checked {
        color: #fa0 !important;
    }
`;
export const Condition1 = styled(Box)`
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    color: rgb(216, 216, 216);
    margin-bottom: 0.5rem;
    span {
        padding: 0;
        color: rgb(216, 216, 216);
    }
    .Mui-checked {
        color: #fa0 !important;
    }
`;
export const LetterInput = styled(ExtendedLetterInput)``;
export const LetterInput1 = styled(TextField)({
    width: '100%',
    '& input': {
        color: 'rgb(216,216,216)',
        fontFamily: 'PT Sans !important'
    },
    '& input:-webkit-autofill': {
        background: 'transparent'
    },
    '& label': {
        color: 'rgb(216,216,216)',
        fontFamily: 'PT Sans !important'
    },
    '& label.Mui-focused': {
        color: 'rgb(216,216,216)'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgb(216,216,216)'
        },
        '&:hover fieldset': {
            borderColor: 'rgb(216,216,216)'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgb(216,216,216)'
        }
    }
});
export const AccessButton = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    width: 100%;
    margin-top: 1rem !important;
    font-size: 16px;
    cursor: pointer;
    background-color: #fa0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const AccessButton1 = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    margin-top: 1rem;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const FooterText = styled(Typography)`
    font-family: PT Sans !important;
    margin-top: 1rem !important;
    padding-top: 1rem !important;
    text-align: center;
    user-select: none;
    border-top: 1px solid gray !important;
    color: ${({ theme }) => theme.text};
    @media (max-width: 768px) {
        font-size: 1rem;
    }
    @media (max-width: 500px) {
        text-align: left;
    }
`;
export const Redirect = styled.span`
    font-family: PT Sans !important;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    color: ${({ theme }) => theme.primaryButton};
`;
export const CapchaContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
`;
export const LetterSelect = styled(Select)`
    overflow-y: auto !important;
    svg {
        color: rgb(216, 216, 216);
    }
    .MuiSelect-select {
        display: flex;
        img {
            width: 24px !important;
        }
    }
    fieldset {
        border-color: rgb(216, 216, 216) !important;
    }
`;
export const FormControlBirthDay = styled(FormControl)({
    width: '100%',
    marginTop: '1rem !important',
    '& svg, label, input': {
        color: 'rgb(216,216,216) !important'
    },
    '& fieldset': {
        borderColor: 'rgb(216,216,216) !important'
    }
});
export const Subscriptions = styled.div`
    display: flex;
    @media (max-width: 500px) {
        justify-content: space-between;
    }
`;
export const SubscriptionsLetter = styled(Typography)`
    font-family: PT Sans !important;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.65;
    margin-bottom: 23px;
    margin: 0;
    user-select: none;
    color: ${({ theme }) => theme.text};
`;
export const SubscripContent = styled(Box)`
    display: flex !important;
    flex-direction: column !important;
`;
export const SubscripEmail = styled(Typography)`
    font-family: PT Sans !important;
    user-select: none;
    margin: 0;
    color: ${({ theme }) => theme.text};
`;
export const SubscripTerm = styled(Typography)`
    font-family: PT Sans !important;
    user-select: none;
    margin: 0;
    color: ${({ theme }) => theme.text};
    cursor: pointer;
    &:hover {
        span {
            text-decoration: underline;
            color: ${({ theme }) => theme.primaryButton};
        }
    }
    span {
        text-decoration: underline;
    }
`;
export const CheckboxWrapp = styled(Checkbox)`
    user-select: none;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    svg {
        color: ${({ theme }) => theme.primaryButton};
    }
`;
export const Popover1 = styled(Popover)`
    z-index: 9999;
    .MuiPaper-elevation {
        background: #41444f;
    }
`;
export const LinearProgressWarp = styled(LinearProgress).attrs(
    (props: any) => ({
        status: props.status
    })
)`
    margin-top: 0.5rem !important;
    height: 0.5rem;
    border-radius: 10px;
    span {
        background: ${(props: any) => props.status} !important;
    }
`;
export const ReactCodeInputWrapp = styled.div`
    display: flex;
    justify-content: center;
    input {
        outline: unset !important;
    }
`;
export const RadioGroupWrapp = styled(RadioGroup)`
    color: white !important;
    font-family: League Spartan, Cyrillic !important;
    svg {
        color: #fa0 !important;
    }
`;
export const Captcha = styled(Box)`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`;
export const WrappInputLabel = styled.p.attrs((props: any) => ({
    color: props.color
}))`
    font-family: PT Sans !important;
    color: ${(props) => props.color} !important;
    font-size: 1rem;
    margin: 0;
    user-select: none;
    @media (max-width: 500px) {
        font-size: 0.8rem;
    }
`;

export const RegisterMobile = styled(Box)`
    padding: 2rem;
    position: absolute;
    z-index: 102 !important;
    z-index: 102;
    @media (max-width: 500px) {
        padding: 1rem;
    }
`;
export const RegisterClose = styled(Box)`
    display: flex;
    justify-content: flex-end;
    svg {
        color: ${({ theme }) => theme.text};
    }
`;
export const CurrencyClose = styled(Box)`
    display: flex;
    justify-content: flex-end;
    background: transparent;
    position: fixed;
    right: 32px;
    padding: 8px;
    svg {
        color: white !important;
    }
`;
