const site = 'alphatest.live'
const sitekeys = {
    'alphatest.live': '6Lf2cUIhAAAAAHVBDaJvBkguUWQCx5Vfpt8sXd-c'
};

export const Root =
    process.env.NODE_ENV === 'development'
        ? {
            adminWebsiteUrl: `https://admin.alphatest.live`,
            serverUrl: `https://alphatest.live`,
            sportUrl: `https://sport.alphatest.live`,
            token: `player-alphatest.live-token`,

            // adminWebsiteUrl: 'http://localhost:2000',
            // serverUrl: 'http://localhost:2002',
            // sportUrl: 'http://localhost:2004',
            // token: '8866-player-token',

            site,
            siteKey: sitekeys[site],
            soliticsBrand: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsPopupToken: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsBranch: 'slotmaniax',
            socket: null
        }
        : {
            adminWebsiteUrl: `https://admin.alphatest.live`,
            serverUrl: `https://alphatest.live`,
            sportUrl: `https://sport.alphatest.live`,
            token: `player-alphatest.live-token`,
            site,
            siteKey: sitekeys[site],
            soliticsBrand: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsPopupToken: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsBranch: 'slotmaniax',
            socket: null
        };
