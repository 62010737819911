import { useState } from 'react';

//create your forceUpdate hook
function useForceUpdate() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
}

export { useForceUpdate };
