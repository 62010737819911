import { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import Media from 'react-media';
import SwiperCore, { Parallax, Pagination, Navigation, Autoplay } from 'swiper';
import Container from '@mui/material/Container';

import BaseZustand from '../../../../zustand/BaseZustand';
import AuthZustand from '../../../../zustand/AuthZustand';

import { Root } from '../../../../config/config';

import {
    Footer,
    FooterContent,
    FooterLink,
    Logo,
    FooterText,
    FooterProvider,
    FooterProvider1,
    FooterVerify,
    FooterRule,
    FooterRule1
} from './style';

SwiperCore.use([Parallax, Pagination, Navigation, Autoplay]);

const MainFooterPage = ({ show = true }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { setSidebar, pushUrl } = BaseZustand((state) => state);
    const { logoImage, proImgData, footerMenuData } = AuthZustand(
        (state) => state
    );

    const changePageOnFooter = useCallback(async (url: string) => {
        const flag = await pushUrl(history, url);
        if (flag) {
            setSidebar(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Footer show={String(show)}>
            <Container maxWidth="xl">
                <FooterContent container spacing={1}>
                    {footerMenuData.map((item, key) => (
                        <FooterLink
                            item
                            key={key}
                            num={key}
                            md={3}
                            sm={6}
                            xs={12}
                            onClick={() => changePageOnFooter(item.url)}
                        >
                            {t(item.name)}
                        </FooterLink>
                    ))}
                    <Media
                        queries={{
                            large: '(min-width: 769px)',
                            small: '(max-width: 768px)'
                        }}
                    >
                        {(matches) => (
                            <>
                                {matches.small && (
                                    <FooterLink>
                                        <Logo
                                            src={`${Root.adminWebsiteUrl}${logoImage}`}
                                            onClick={() =>
                                                pushUrl(history, '/')
                                            }
                                            alt=""
                                        />
                                    </FooterLink>
                                )}
                            </>
                        )}
                    </Media>
                </FooterContent>
                <FooterContent>
                    <FooterText>
                        {Root.site}&nbsp;{t('FooterLetter1')}
                    </FooterText>
                </FooterContent>
                <FooterContent container>
                    <Swiper
                        speed={600}
                        loop={true}
                        autoplay={{ delay: 2000, disableOnInteraction: false }}
                        breakpoints={{
                            1500: { slidesPerView: 8 },
                            900: { slidesPerView: 6 },
                            500: { slidesPerView: 4 },
                            300: { slidesPerView: 4 }
                        }}
                        style={{ width: '100%' }}
                    >
                        {proImgData.map((provider, key) => {
                            if (provider.image) {
                                return (
                                    <SwiperSlide key={key}>
                                        {provider._id ===
                                        '6202b0f2aa8da4b634fd4785' ? (
                                            <FooterProvider1>
                                                <img
                                                    src={`${Root.serverUrl}${provider.image}`}
                                                    alt=""
                                                />
                                            </FooterProvider1>
                                        ) : (
                                            <FooterProvider>
                                                <img
                                                    src={`${Root.serverUrl}${provider.image}`}
                                                    alt=""
                                                />
                                            </FooterProvider>
                                        )}
                                    </SwiperSlide>
                                );
                            } else {
                                return <Fragment key={key}></Fragment>;
                            }
                        })}
                    </Swiper>
                </FooterContent>

                <FooterContent>
                    <FooterText>{t('FooterLetter2')}</FooterText>
                </FooterContent>

                <FooterContent container>
                    <FooterRule1>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/18plus.svg`}
                            alt=""
                        />
                    </FooterRule1>
                    <FooterRule>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/gamecare.png`}
                            alt=""
                        />
                    </FooterRule>
                    <FooterRule>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/111.png`}
                            alt=""
                        />
                    </FooterRule>
                    <FooterVerify>
                        {/* eslint-disable-next-line */}
                        <a
                            href="https://licensing.gaming-curacao.com/validator/?lh=6adc3cd6833fbc1f293fee70eb5e38bf"
                            target="_blank"
                            rel={Root.site}
                        >
                            <img
                                // src={`${Root.serverUrl}/image/site/rule/Gaming-Curacao-ClickToVerify.png`}
                                src="https://licensing.gaming-curacao.com/validator/images/Gaming-Curacao-ClickToVerify.png"
                                width="150"
                                height="50"
                                alt="Gaming Curacao: Click to Validate"
                            />
                        </a>
                    </FooterVerify>
                </FooterContent>

                <FooterContent container>
                    <FooterText>
                        @2020 - {new Date().getFullYear()} {t('FooterLetter3')}
                    </FooterText>
                </FooterContent>
            </Container>
        </Footer>
    );
};

export default MainFooterPage;
