import styled from 'styled-components';
import Box from '@mui/material/Box';

export const AvatarBox = styled(Box)`
    display: flex;
    user-select: none;
    img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        border-radius: 50%;
        user-select: none;
    }
`;
export const ListItem = styled(Box)`
    padding: 0.8rem 1rem;
    cursor: pointer;
    user-select: none;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    //color: rgb(216, 216, 216);
    font-family: PT Sans !important;
    display: flex;
    align-items: center;
    span {
        margin-left: 5px;
        color: #fa0;
    }
    &:hover {
        background: ${({ theme }) => theme.background.secondary};
    }
`;
