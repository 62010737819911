import styled from 'styled-components';

export const MultipleGamesWrapper = styled.div.attrs(
    (props: { renderCount: number, isFullScreen: boolean }) => ({
        renderCount: props.renderCount,
        isFullScreen: props.isFullScreen,
    })
)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: ${(props) => props.isFullScreen? '0' : '5px' };
    ${(props) => props.renderCount > 0 && 'align-items: center'}
`;

export const MultipleGameCurrentItem = styled.div.attrs(
    (props: { mainScreen: any; fullSize: any; isFullScreen: boolean }) => ({
        mainScreen: props.mainScreen,
        fullSize: props.fullSize,
        isFullScreen: props.isFullScreen,
    })
)`
    width: ${(props) => (props.mainScreen ? '50%' : '100%')};
    max-width: ${(props) => (props.fullSize ? 'unset' : '50%')};
    height: ${(props) => (props.fullSize ? '100%' : '50%')};
    flex: ${(props) => props.isFullScreen ? '1 1 50%;': '1 1 49%;'}
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 1250px) {
        display: none;
    }
`;

export const MultipleGameCurrentItemHeader = styled.header<{
    expanded?: boolean;
    fullSize?: boolean;
}>`
    width: 100%;
    position: absolute;
    height: 10px;
    top: ${(props) => (props.expanded ? '0px' : '5px')};
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    z-index: 2;
    transition: all 0.3s;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    backface-visibility: hidden;
    background: transparent;

    & > * {
        transition: all 0.3s;
        transform: scale(0);
    }

    &:hover {
        top: 0;

        height: 60px;
        padding-bottom: 10px;
        & > * {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media (max-width: 1250px) {
        display: none;
    }
`;

export const MultipleGameCurrentItemHeaderInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const MultipleGameCurrentItemGameName = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'PT Sans', sans-serif;
    & > h1 {
        width: 100%;
        margin: 0 10px;
        font-size: 20px;
        text-align: center;
        color: ${({ theme }) =>
            theme.mode === 'white' ? theme.background.primary : theme.text};
    }
    & > h3 {
        width: 100%;
        margin: 0 10px;
        font-size: 15px;
        text-align: center;
        color: ${({ theme }) => theme.primaryButton};
    }
    & span {
        color: ${({ theme }) =>
            theme.mode === 'white' ? theme.background.primary : theme.text};
        font-weight: 500;
    }
`;

export const MultipleGameItem = styled(MultipleGameCurrentItem)`
    width: 100%;
    max-width: unset;
    height: 100%;
    margin: 0;
    flex: unset;
    flex-direction: column;
`;

export const MultipleGameCloseIconWrapper = styled.div`
    margin: 10px;
    cursor: pointer;
    & svg {
        color: ${({ theme }) =>
            theme.mode === 'white' ? theme.background.primary : theme.text};
    }
`;

export const StyledOption = styled('li')`
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
`;

export const Image = styled('img')`
    width: 100px;
    height: 70px;
`;

export const OptionText = styled('span')`
    color: white;
    margin: 0 10px;
`;

export const TextOptionText = styled('span')`
    color: white;
    padding: 10px;
    font-weight: normal;
`;

export const SearchAutocompleteWrapper = styled.div`
    width: 100%;
    & .MuiPaper-root {
        background: ${(props) => props.theme.background.primary};
    }
    & .MuiAutocomplete-popper .MuiAutocomplete-listbox {
        color: ${(props) => props.theme.text} !important;
        //padding: 0;
        background: ${(props) => props.theme.background.primary} !important;
        & li {
            color: ${(props) => props.theme.text} !important;
            //padding: 0;
            background: ${(props) => props.theme.background.primary} !important;
            & span {
                color: unset;
            }
        }
    }

    & .MuiAutocomplete-option {
        color: ${(props) => props.theme.text};
        background: ${(props) => props.theme.background.primary} !important;
    }
`;
