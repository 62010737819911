import i18n from 'i18next';
// import axios from 'axios';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import { Root } from './config';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en',
        resources: {},
        // backend: {
        //   /* translation file path */
        //   loadPath: '/assets/locales/{{lng}}.json'
        // },
        fallbackLng: 'en',
        debug: false,
        keySeparator: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        }
    });

// axios.post(`${Root.serverUrl}/api/config/getMultilang`).then((res) => {
//     const data = res.data;
//     for (let i = 0; i < data.langs.length; i++) {
//         i18n.addResourceBundle(
//             data.langs[i].language,
//             'translation',
//             data.data[data.langs[i].language]
//         );
//     }
// });

export default i18n;
