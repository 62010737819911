import { toast } from 'react-toastify'
import create from 'zustand'
import WalletApi from '../api/WalletApi'
import { ToastBox } from '../pages/base/toast'

const useStore = create((set) => ({

    praxisModalOpen: false,

    setPraxisModalOpen: (status) => {
        set({ praxisModalOpen: status });
    },

    // This is for show available Gateway show for this user
    getGatewayList: async (data) => {
        const rdata = await WalletApi.getGatewayList(data)
        if (rdata) {
            return rdata.data
        } else {
            return []
        }
    },

    // Transaction Detail Page
    getAllGatewayList: async () => {
        const rdata = await WalletApi.getAllGatewayList()
        if (rdata) {
            return rdata.data
        } else {
            return []
        }
    },
    getTransactionHistory: async (data) => {
        const rdata = await WalletApi.getTransactionHistory(data)
        if (rdata) {
            return rdata.data
        } else {
            return {
                data: [],
                totalCount: 0,
                totalDWData: []
            }
        }
    },
    cancelWithdraw: async (data) => {
        const rdata = await WalletApi.cancelWithdraw(data)
        if (rdata) {
            return rdata.data
        } else {
            return false
        }
    },

    // Get Saved Card Info
    getCardData: async () => {
        const rdata = await WalletApi.getCardData()
        if (rdata) {
            return rdata.data
        } else {
            return {}
        }
    },

    // Player Transaction Excute
    bankWithdraw: async (data) => {
        const rdata = await WalletApi.bankWithdraw(data)
        if (rdata) {
            toast.success(<ToastBox letter='Please wait for confirm' />)
        }
    },
    getPraxisUrl: async (data) => {
        const rdata = await WalletApi.getPraxisUrl(data)
        if (rdata) {
            return rdata.data
        } else {
            return false
        }
    }
    
}))

export default useStore
