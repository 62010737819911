import Typography from '@mui/material/Typography';
import * as S from '../other/style';

const BlockedPage = () => {
  return (
    <S.ProfileContent maxWidth="xl">
      <S.IntroContent>
        <Typography variant="h5">
          Access restricted
        </Typography>
        <Typography variant="body2" className="content-letter ft-bold">
          Sorry, your IP address appears to be blocked
        </Typography>
      </S.IntroContent>
    </S.ProfileContent>
  )
}
export default BlockedPage
