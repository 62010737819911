export const zeroVipLevel = '6318ad9fcb3f87e7e4208b98';

export const depositSuccessNotify = '638b19bc028f425896680023';

export const providerconfig = {
    LiveCasino: '603e6b45e10dfc1b1c7e548b',
    Slot: '6011f42064c3343290b99fd5',
    Table: '6011f41964c3343290b99fd4',
    Tvbet: '62c6fe145280d80781531f83',
    Virtual: '640e2325ae64a6ef475e786d',
    Aviator: '62a767b82a8996a5bac44a59',
    Poker: '628dd741e6401a05e1001d22',
    All: '',
    Top: 'Top',
    New: 'New'
};

export const PGConfig = {
    Ikajo: '62aae423184e2f7686b479a6',
    Skrill: '62aae48b184e2f7686b479f3',
    SkrillW: '62f445f1594c9c0ff6d8726a',
    Getapay: '62cd466dd68be4877aa2371b',
    GetaWpay: '62cd50afa56eac6402264436',
    Coinpay: '62b5ec18184e2f7686ccf706',
    CoinWpay: '62b5ec82184e2f7686ccf742',
    PayRetailer: '62d259f6a946df03d8d23db2',
    PayRetailerW: '62d259f6a946df03d8d23db6',
    Onramper: '632da24000da125417d516a8',
    Bank: '635e7efc906745012918198c',
    PayAdmit: '6389cd9b1522ee4d06d4d3e9',
    PayAdmitW: '638c59a8b6a4632794aa962b',
    Gumball: '639cdd008ed977e0bdbf7723',
    Jeton: '639f3118069c582460974051',
    JetonW: '639f3a0110686ec68c7ba1c3',
    CoryPayIn: '63fcc5d72250bdf9e9ea9de8',
    CoryPayOut: '6401e0e7bd4bf18434f9fd6e',
    PraxisDeposit: '6421c749bb8ac7ea8a5df4fd'
};

export const genderData = [
    { value: '', label: 'Please Select' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
];

export const mainGameTypeData = [
    {
        name: 'All games',
        value: 'All'
    },
    {
        name: 'Live Casino',
        value: 'LiveCasino',
        icon: 'fa-mg-livecasino',
        image: '/image/site/mobileheader/live casino-2.ico'
    },
    {
        name: 'Slot Games',
        value: 'Slot',
        icon: 'fa-mg-casino',
        image: '/image/site/mobileheader/slot games.ico'
    },
    {
        name: 'Table Games',
        value: 'Table',
        icon: 'fa-mg-tablegame',
        image: '/image/site/mobileheader/table game.ico'
    },
    {
        name: 'TVgames',
        value: 'Tvbet',
        icon: 'fa-mg-tv',
        image: '/image/site/mobileheader/tv bet.ico'
    },
    {
        name: 'Virtual Games',
        value: 'Virtual',
        icon: 'fa-mg-sportvari',
        image: '/image/site/mobileheader/virtual.ico'
    }
    // {
    //     name: 'Jackpot Games',
    //     value: 'Jackpot',
    //     icon: 'fa-mg-sisal',
    //     image: '/image/site/mobileheader/virtual.ico'
    // }
];

export const IQSportStatus = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Uncalculated' },
    { value: 2, label: 'Won' },
    { value: 3, label: 'Lost' },
    { value: 4, label: 'Deleted' },
    { value: 5, label: 'Cashouted' },
    { value: 6, label: 'Returned' },
    { value: 7, label: 'NotAccepted' }
];

export const IQSportType = [
    { value: '', label: 'All' },
    { value: 'true', label: 'Live' },
    { value: 'false', label: 'Pre' }
];

export const IQSportBetType = {
    1: 'Single',
    2: 'Multi',
    3: 'System'
};

export const BetStatusList = [
    { value: '', label: 'All' },
    { value: 'WIN', label: 'WIN' },
    { value: 'BET', label: 'BET' }
];

export const BonusTypes = [
    { value: '', label: 'All' },
    { value: 'WIN', label: 'Credit' },
    { value: 'BET', label: 'Debit' }
];

export const PayTypes = [
    { value: '', label: 'All' },
    { value: 'DEPOSIT', label: 'Deposit' },
    { value: 'WITHDRAWL', label: 'Withdrawal' }
];

export const PayStatus = [
    { value: '', label: 'All' },
    { value: 'success', label: 'Success' },
    { value: 'pending', label: 'Pending' },
    { value: 'failure', label: 'Failure' },
    { value: 'waiting_confirm', label: 'Waiting' }
];

export const tokenABI = [
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'to',
                'type': 'address'
            },
            {
                'internalType': 'uint256',
                'name': 'value',
                'type': 'uint256'
            }
        ],
        'name': 'transfer',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'owner',
                'type': 'address'
            }
        ],
        'name': 'balanceOf',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
]
