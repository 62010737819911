import React, { Suspense, lazy, useEffect } from 'react';
import {
    Switch,
    Route,
    BrowserRouter as Router,
    Redirect
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MainLayout from './layout/layoutList/main';
import SportLayout from './layout/layoutList/sport';
import GameLayout from './layout/layoutList/game';

import RegisterLayout from './layout/layoutList/register/registerLayout';
import ProfileLayout from './layout/layoutList/profile';
import PromotionLayout from './layout/layoutList/promotion';
import AuthZustand from './zustand/AuthZustand';
import StaticTournament from './pages/Tournament/StaticTournament';
import PastCurrentTournament from './pages/Tournament/PastCurrentTournament';
import BonusTournamentDescription from './pages/Tournament/BonusTournamentDescription';
import { RouterProvider } from './context/RouterContext';
import LocationObserver from './components/LocationObserver';
import BlockedPage from './pages/other/blocked';

const Tournament = lazy(() => import('./pages/Tournament'));
const CurrentTournament = lazy(
    () => import('./pages/Tournament/CurrentTournament')
);

const Dashboard = lazy(() => import('./pages/dashboard'));
const SportDashboard = lazy(() => import('./pages/game/GameOpen/sports'));

const Games = lazy(() => import('./pages/game'));
const NicehandPoker = lazy(() => import('./pages/game/GameOpen/nicehand'));

const AboutUs = lazy(() => import('./pages/other/aboutus'));
const CookiePage = lazy(() => import('./pages/other/cookie'));
const AmlKyc = lazy(() => import('./pages/other/amlkyc'));
const PrivacyPolice = lazy(() => import('./pages/other/privacypolice'));
const TermsCondition = lazy(() => import('./pages/other/termcondition'));
const ResponsibleGame = lazy(() => import('./pages/other/resgame'));
const ContactUs = lazy(() => import('./pages/other/contactus'));
const PromotionsGeneralTermsAndCondition = lazy(
    () => import('./pages/other/PromotionsGeneralTermsAndCondition')
);

const Payments = lazy(() => import('./pages/payment'));
const Support = lazy(() => import('./pages/support'));
const VipPage = lazy(() => import('./pages/vip'));
const Promotion = lazy(() => import('./pages/promotion'));

const ProfilePage = lazy(() => import('./pages/userDashboard/profile'));
const WalletPage = lazy(() => import('./pages/userDashboard/wallet'));
const DepositPage = lazy(() => import('./pages/dashboard/index'));
const HistoryPage = lazy(() => import('./pages/userDashboard/history'));

const ActivateSuccess = lazy(() => import('./pages/auth/activate/success'));
const ActivateFailure = lazy(() => import('./pages/auth/activate/failure'));
const ActivatePending = lazy(() => import('./pages/auth/activate/pending'));
const ActivateWaiting = lazy(() => import('./pages/auth/activate/waiting'));

const TestPage = lazy(() => import('./pages/test/iframe'));

const RegisterPage = lazy(
    () => import('./pages/auth/auth/register/registerpage')
);

const IconPage = lazy(() => import('./pages/other/icon'));
const EmptyPage = lazy(() => import('./pages/other/empty'));

const Authmiddleware = (props: any) => {
    const { component: Component, layout, isLogin, path, ...rest } = props;
    const { isAuth, user, useBonus } = AuthZustand((state) => state);

    return (
        <Route
            {...rest}
            render={(props: any) => {
                if (isLogin) {
                    if (!isAuth) {
                        return <Redirect to="/" />;
                    }
                }

                if (isAuth && !user.activated && path !== '/activate/pending' && path !== '/activate/waiting' && path !== '/activate/success') {
                    return <Redirect to='/activate/pending' />
                }

                if (!useBonus && (
                    path.includes('/vip') || path.includes('/promotion') || path.includes('/tournament')
                )) {
                    return <Redirect to="/" />;
                }

                if (layout === 'MainLayout') {
                    return (
                        <MainLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </MainLayout>
                    );
                } else if (layout === 'RegisterLayout') {
                    return (
                        <RegisterLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </RegisterLayout>
                    );
                } else if (layout === 'SportLayout') {
                    return (
                        <SportLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </SportLayout>
                    );
                } else if (layout === 'GameLayout') {
                    return (
                        <GameLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </GameLayout>
                    );
                } else if (layout === 'ProfileLayout') {
                    return (
                        <ProfileLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </ProfileLayout>
                    );
                } else {
                    return (
                        <PromotionLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </PromotionLayout>
                    );
                }
            }}
        />
    );
};

Authmiddleware.propTypes = {
    component: PropTypes.any,
    layout: PropTypes.string,
    path: PropTypes.string,
    exact: PropTypes.bool,
    isLogin: PropTypes.bool
};

const AppRouter: React.FC = () => {
    const { i18n } = useTranslation();
    const { isAuth, user, myData, languages } = AuthZustand((state) => state);

    useEffect(() => {
        setTimeout(() => {
            document.getElementById('init-loading').style.display = 'none';
        }, 2000);
    }, []);

    useEffect(() => {
        if (languages.length) {
            if (isAuth) {
                i18n.changeLanguage(user.language);
                localStorage.setItem('player-langauge', user.language);
            } else {
                let lang = localStorage.getItem('player-langauge');
                if(!lang) {
                    lang = String(myData.countryCode).toLowerCase();
                }

                const flag = languages.findIndex(
                    (item) => item.language === lang
                );
                if (flag < 0) {
                    i18n.changeLanguage('en');
                } else {
                    i18n.changeLanguage(lang);
                }
            }
        }
        // eslint-disable-next-line
    }, [myData, languages]);

    return (
        <Router>
            <RouterProvider>
                <LocationObserver />
                <Switch>
                    <Authmiddleware
                        path="/"
                        exact
                        component={Dashboard}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/sports"
                        exact
                        component={SportDashboard}
                        layout="SportLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/home"
                        exact
                        component={Dashboard}
                        layout="MainLayout"
                        isLogin={false}
                    />

                    <Authmiddleware
                        path="/games"
                        exact
                        component={Games}
                        layout="GameLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/game" // when close game have to check it
                        exact
                        component={EmptyPage}
                        layout="MainLayout"
                    />
                    <Authmiddleware
                        path="/pokermaniax"
                        exact
                        component={NicehandPoker}
                        layout="GameLayout"
                        isLogin={false}
                    />

                    <Authmiddleware
                        path="/aboutus"
                        exact
                        component={AboutUs}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/cookie"
                        exact
                        component={CookiePage}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/aml-kyc"
                        exact
                        component={AmlKyc}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/privacy-police"
                        exact
                        component={PrivacyPolice}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/terms-condition"
                        exact
                        component={TermsCondition}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/responsible-game"
                        exact
                        component={ResponsibleGame}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/contact-us"
                        exact
                        component={ContactUs}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/payment"
                        exact
                        component={Payments}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/support"
                        exact
                        component={Support}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/vip"
                        exact
                        component={VipPage}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/promotion"
                        exact
                        component={Promotion}
                        layout="PromotionLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/promotion/:id"
                        exact
                        component={Promotion}
                        layout="PromotionLayout"
                        isLogin={false}
                    />

                    <Authmiddleware
                        path="/tournament"
                        exact
                        component={Tournament}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/tournament/:id"
                        exact
                        component={CurrentTournament}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/tournament/past/:id"
                        exact
                        component={PastCurrentTournament}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/tournament/static/:id"
                        exact
                        component={StaticTournament}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/tournament-bonus/:id"
                        exact
                        component={BonusTournamentDescription}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/promotions-general-terms-and-conditions"
                        exact
                        component={PromotionsGeneralTermsAndCondition}
                        layout="MainLayout"
                        isLogin={false}
                    />

                    <Authmiddleware
                        path="/profile"
                        exact
                        component={ProfilePage}
                        layout="ProfileLayout"
                        isLogin={true}
                    />
                    <Authmiddleware
                        path="/wallet"
                        exact
                        component={WalletPage}
                        layout="ProfileLayout"
                        isLogin={true}
                    />
                    <Authmiddleware
                        path="/deposit"
                        exact
                        component={DepositPage}
                        layout="MainLayout"
                        isLogin={true}
                    />
                    <Authmiddleware
                        path="/history"
                        exact
                        component={HistoryPage}
                        layout="ProfileLayout"
                        isLogin={true}
                    />

                    <Authmiddleware
                        path="/icon"
                        exact
                        component={IconPage}
                        layout="MainLayout"
                    />
                    <Authmiddleware
                        path="/blocked"
                        exact
                        component={BlockedPage}
                        layout="MainLayout"
                    />

                    <Authmiddleware
                        path="/activate/success"
                        exact
                        component={ActivateSuccess}
                        layout="MainLayout"
                    />
                    <Authmiddleware
                        path="/activate/failure"
                        exact
                        component={ActivateFailure}
                        layout="MainLayout"
                    />
                    <Authmiddleware
                        path="/activate/pending"
                        exact
                        component={ActivatePending}
                        layout="MainLayout"
                    />
                    <Authmiddleware
                        path="/activate/waiting"
                        exact
                        component={ActivateWaiting}
                        layout="MainLayout"
                    />

                    <Authmiddleware
                        path="/register"
                        exact
                        component={RegisterPage}
                        layout="RegisterLayout"
                    />
                    <Authmiddleware
                        path="/testiframe"
                        exact
                        component={TestPage}
                        layout="MainLayout"
                    />

                    <Authmiddleware
                        component={Dashboard}
                        layout="MainLayout"
                        isLogin={false}
                    />
                </Switch>
            </RouterProvider>
        </Router>
    );
};

export default AppRouter;
