import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import { ExtendedLetterInput } from 'pages/userDashboard/history/style';
import TextField from '@mui/material/TextField';

export const AuthContent = styled(Box)`
    width: 450px;
    margin: auto;
    @media (max-width: 500px) {
        width: 100% !important;
    }
`;
export const Letter = styled(Typography)`
    text-align: center;
    margin: 2rem 0 0 !important;
    user-select: none;
    font-size: 20px !important;
    font-weight: 700;
    color: ${(props) => props.theme.text};
    font-family: 'PT Sans' !important;
`;

export const PasswordInput = styled(TextField)`
    width: 100%;
    margin-top: 1rem !important;
    color: ${(props) => props.theme.borderColor} !important;
    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${(props) => props.theme.borderColor};
    }
    & textarea {
        color: ${(props) => props.theme.borderColor};
    }

    & .MuiInputBase-input:-webkit-autofill {
        color: ${(props) => props.theme.borderColor};
        font-family: 'PT Sans' !important;
        & :-webkit-autofill:active {
            -webkit-box-shadow: unset;
            color: ${(props) => props.theme.borderColor};
        }
        & :-webkit-autofill {
            background-color: transparent;
        }
        -webkit-text-fill-color: ${(props) => props.theme.borderColor};
    }

    & label.Mui-focused {
        color: ${(props) => props.theme.borderColor};
    }

    &.Mui-focused {
        color: ${(props) => props.theme.borderColor};
    }

    & label {
        color: ${(props) => props.theme.borderColor};
        font-family: 'PT Sans' !important;
    }

    & svg {
        color: ${(props) => props.theme.borderColor};
    }

    & input {
        color: ${(props) => props.theme.borderColor};
        &:focus {
            color: ${(props) => props.theme.borderColor};
        }

        & input:-internal-autofill-selected {
            background-color: transparent;
            color: ${(props) => props.theme.borderColor};
        }

        & input:-webkit-autofill {
            background: transparent;
        }
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: ${(props) => props.theme.borderColor};
        }

        &:hover fieldset {
            border-color: ${(props) => props.theme.borderColor};
        }

        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.borderColor};
        }
    }
`;

export const LetterInput = styled(PasswordInput)`
    width: 100%;
    margin-top: 0.5rem !important;
    -webkit-appearance: none;
    -moz-appearance: none;
`;
export const Captcha = styled(Box)`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`;
export const AccessButton = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    width: 100%;
    margin-top: 1rem !important;
    font-size: 16px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const Redirect = styled.span`
    font-family: PT Sans !important;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    color: ${({ theme }) => theme.primaryButton};
`;
export const FooterText = styled(Typography)`
    font-family: PT Sans !important;
    margin-top: 1rem !important;
    padding-top: 1rem !important;
    text-align: center;
    user-select: none;
    border-top: 1px solid gray !important;
    color: ${({ theme }) => theme.text};
    @media (max-width: 768px) {
        font-size: 1rem;
    }
    @media (max-width: 500px) {
        text-align: left;
    }
`;
