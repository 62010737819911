import { createGlobalStyle, ThemeProps } from 'styled-components';
import { Theme } from '../theme';

const GlobalStyles = createGlobalStyle<ThemeProps<Theme>>`
  .MuiPopover-paper {
    background-color: ${({ theme }) => theme.background.primary};
  }
  
  body {
    background-color: ${({ theme }) => theme.background.primary}
  }

  .MuiTablePagination-menuItem {
    color: ${({ theme }) => theme.text};
  }
`;

export default GlobalStyles;
