import React, { useCallback, useEffect } from 'react';
import {
    TournamentComingItem,
    TournamentImageContainer,
    TournamentItemBtn,
    TournamentItemBtnWrapper,
    TournamentItemTextWrapper,
    TournamentItemTitle,
    TournamentNameWrapper,
    TournamentPrizeWrapper,
    TournamentTimerContainer
} from '../../style';
import { currentTimeFormatter } from '../../heplers';
import { Link, useHistory } from 'react-router-dom';
import TournamentPageTimer from '../../TournamentTimer';
import TournamentPrizePool from '../../TournamentPrizePool';
import TournamentSubscribeButton from '../../TournamentSubscribeButton';
import TournamentLeaderBoard from '../../CurrentTournament/TournamentLeaderBoard';
import { ITournamentCardProps } from './types';
import { useTournamentStore } from '../../../../zustand/TournamentZustand';
import { IUser } from '../../../../types';
import AuthZustand from '../../../../zustand/AuthZustand';
import { useTranslation } from 'react-i18next';
import TournamentResults from '../../TournamentResults';
import { useForceUpdate } from '../../../../hooks/useForceUpdate';
import useIntervalTrigger from '../../../../hooks/useIntervalTrigger';
import { Root } from '../../../../config/config'

const TEN_SECONDS = 10000;

const TournamentCard = ({
    tournament,
    hasSubscribeButton,
    hasShowMoreButton,
    fullSize,
    showLeaderboard,
    isPastTournament,
    isStatic = false
}: ITournamentCardProps) => {
    const now = new Date();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    let leaderboard = null;
    const { user }: IUser | any = AuthZustand((state) => state);

    const {
        getTournamentCurrentUserLeaderboard,
        tournamentProgression,
        getUserTournamentProgression,
        userProgression
    } = useTournamentStore((state) => state);

    if (showLeaderboard || isPastTournament === false) {
        leaderboard = (
            <TournamentLeaderBoard
                title={''}
                data={tournamentProgression}
                showPlaceColor={false}
                pagination={false}
                currentUserProgression={userProgression}
            />
        );
    } else {
        leaderboard = null;
    }

    const trigger = useIntervalTrigger(tournament.Selected ? TEN_SECONDS : 0);

    useEffect(() => {
        if (tournament.Selected > 0) {
            getTournamentCurrentUserLeaderboard(tournament.ID, 5, 0);
            getUserTournamentProgression(tournament.ID);
        }
    }, [tournament.Selected, trigger]);

    const forceUpdate = useForceUpdate();
    const onStartExpire = useCallback(() => {
        forceUpdate();
    }, []);
    const onFinishExpire = useCallback(() => {
        if (
            !isPastTournament &&
            /tournament\/(\d*)/.test(history.location.pathname)
        ) {
            history.push('/tournament');
        }
    }, [history]);

    const startTimeFormatted = currentTimeFormatter(tournament.Starts, false);
    const endTimeFormatted = currentTimeFormatter(tournament.Ends, false);

    const pastTournamentResults =
        isPastTournament &&
        tournament.Leaderboard.find(
            (results) => Number(results.IDUser) === user.softId
        );

    const image = window.innerWidth > 1000 ? (tournament.Image['desktop']['en'] || tournament.Image['desktop'][i18n.language]
        ? tournament.Image['desktop'][i18n.language]
            ? `${Root.adminWebsiteUrl}${tournament.Image['desktop'][i18n.language]}`
            : `${Root.adminWebsiteUrl}${tournament.Image['desktop']['en']}`
        : 'https://www.onlineunitedstatescasinos.com/app/uploads/2020/04/Online-Casino-Tournaments.jpg') : (tournament.Image['mobile'].en || tournament.Image['mobile'][i18n.language]
            ? tournament.Image['mobile'][i18n.language]
                ? `${Root.adminWebsiteUrl}${tournament.Image['mobile'][i18n.language]}`
                : `${Root.adminWebsiteUrl}${tournament.Image['mobile']['en']}`
            : 'https://www.onlineunitedstatescasinos.com/app/uploads/2020/04/Online-Casino-Tournaments.jpg')

    return (
        <TournamentComingItem key={tournament.ID} fullSize={fullSize} isStatic={isStatic}>
            <TournamentItemTextWrapper>
                <TournamentPrizeWrapper>
                    {isStatic ? (
                        <TournamentTimerContainer>
                            <TournamentItemTitle
                                style={{ color: 'rgb(207, 211, 228)' }}
                            >
                                {`${new Date(tournament.Starts).toLocaleString()} - ${new Date(tournament.Ends).toLocaleString()}`} 
                            </TournamentItemTitle>
                        </TournamentTimerContainer>
                    ) : (
                        <>
                            {!isPastTournament || now < endTimeFormatted ? (
                                now > startTimeFormatted ? (
                                    <TournamentTimerContainer>
                                        <TournamentItemTitle
                                            style={{
                                                color: 'rgb(207, 211, 228)'
                                            }}
                                        >
                                            {t('Finishes in')}
                                        </TournamentItemTitle>
                                        <TournamentPageTimer
                                            key={'end-time'}
                                            onExpire={onFinishExpire}
                                            time={endTimeFormatted}
                                        />
                                    </TournamentTimerContainer>
                                ) : (
                                    <TournamentTimerContainer>
                                        <TournamentItemTitle
                                            style={{
                                                color: 'rgb(207, 211, 228)'
                                            }}
                                        >
                                            {t('Starts in')}
                                        </TournamentItemTitle>
                                        <TournamentPageTimer
                                            key={'start-time'}
                                            onExpire={onStartExpire}
                                            time={startTimeFormatted}
                                        />
                                    </TournamentTimerContainer>
                                )
                            ) : (
                                <TournamentTimerContainer>
                                    <TournamentItemTitle
                                        style={{ color: 'rgb(207, 211, 228)' }}
                                    >
                                        {t('Was finish')}
                                    </TournamentItemTitle>
                                </TournamentTimerContainer>
                            )}
                        </>
                    )}
                    <br />
                    <TournamentNameWrapper>
                        {/* <TournamentItemTitle>
                            {tournament.Name[i18n.language] ||
                                tournament.Name.en}
                        </TournamentItemTitle> */}
                    </TournamentNameWrapper>
                    {isPastTournament && pastTournamentResults ? (
                        <TournamentResults
                            tournament={tournament}
                            tournamentResults={pastTournamentResults}
                        />
                    ) : tournament.TotalFounds || tournament.Value ? (
                        <TournamentPrizePool tournament={tournament} />
                    ) : null}
                </TournamentPrizeWrapper>
                <TournamentItemBtnWrapper>
                    {hasSubscribeButton === true ? (
                        <TournamentSubscribeButton tournament={tournament} />
                    ) : null}
                    {hasShowMoreButton ? (
                        <Link
                            // to={`/tournament/static/${tournament.ID}`}
                            to={{
                                pathname: `/tournament/static/${tournament.ID}`,
                                state: { id: tournament._id }
                            }}
                            style={{ textDecoration: 'none' }}
                        >
                            <TournamentItemBtn variant="contained">
                                {t('SHOW MORE')}
                            </TournamentItemBtn>
                        </Link>
                    ) : null}
                    <TournamentItemBtn
                        variant="contained"
                    // onClick={() =>
                    //     history.push(
                    //         '/promotions-general-terms-and-conditions'
                    //     )
                    // }
                    >
                        <a href={`${Root.adminWebsiteUrl}${tournament.pdf}`} target='_blank' rel='noreferrer'>{t('TERMS & CONDITIONS')}</a>
                    </TournamentItemBtn>
                </TournamentItemBtnWrapper>
            </TournamentItemTextWrapper>
            <TournamentImageContainer image={image} />
            {leaderboard}
        </TournamentComingItem>
    );
};

export default TournamentCard;
