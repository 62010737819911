import React, { useEffect, useState } from 'react';
import {
    MultipleGameCloseIconWrapper,
    MultipleGameCurrentItem,
    MultipleGameCurrentItemGameName,
    MultipleGameCurrentItemHeader,
    MultipleGameCurrentItemHeaderInfoWrapper,
    MultipleGameItem
} from './style';
import SearchAutocomplete from './SearchAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { GameItemData, IProviderList } from '../../types';
import { handleCheckProviderType } from '../../helpers';
import GameZustand from '../../../../zustand/GameZustand';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';

const MultipleGames = ({ id, handleCloseScreen, fullSize, isFullScreen }) => {
    const theme = useTheme() as Theme;
    const [selectedGame, setSelectedGame] = useState<GameItemData | null>(null);
    const mainScreen = document.getElementById('game');
    const [currentProvider, setCurrentProvider] = useState(null);
    const { getProviders } = GameZustand((state) => state);

    const getProviderList = async () => {
        const resData = await getProviders({
            gameType: selectedGame?.gameType
        });
        const provider: IProviderList[] = handleCheckProviderType(
            selectedGame?.gameType,
            resData
        );

        const foundProvider = [...provider].find(
            (item: IProviderList) => item._id === selectedGame?.provider
        );
        setCurrentProvider(foundProvider);
    };

    useEffect(() => {
        if (selectedGame) {
            getProviderList();
        }
    }, [selectedGame]);

    return (
        <MultipleGameCurrentItem
            className={id}
            isFullScreen={isFullScreen}
            mainScreen={mainScreen}
            fullSize={fullSize}
        >
            <MultipleGameCurrentItemHeader fullSize={fullSize}>
                <MultipleGameCurrentItemHeaderInfoWrapper>
                    <MultipleGameCurrentItemGameName>
                        <h1>{selectedGame?.gameName}</h1>
                        {currentProvider ? (
                            <h3>
                                {currentProvider?.providerName}
                                <span>
                                    {` + RTP: ${
                                        selectedGame?.detail?.RTP
                                            ? Number(
                                                  selectedGame?.detail?.RTP
                                              ).toFixed(2)
                                            : currentProvider?.RTP
                                    }%` || ''}
                                </span>
                            </h3>
                        ) : null}
                    </MultipleGameCurrentItemGameName>
                    {!fullSize ? (
                        <MultipleGameCloseIconWrapper
                            onClick={() =>
                                handleCloseScreen(id, selectedGame?.provider)
                            }
                        >
                            <CloseIcon sx={{ color: theme.text }} />
                        </MultipleGameCloseIconWrapper>
                    ) : null}
                </MultipleGameCurrentItemHeaderInfoWrapper>
            </MultipleGameCurrentItemHeader>
            <MultipleGameItem id={id}>
                <SearchAutocomplete
                    elementId={id}
                    setSelectedGame={setSelectedGame}
                />
            </MultipleGameItem>
        </MultipleGameCurrentItem>
    );
};

export default MultipleGames;
