import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';

export const Dashboard = styled(Box)`
    width: calc(100% - 2rem);
    height: auto;
    padding: 0 1rem;
    @media only screen and (max-width: 769px) {
        padding: 0;
        width: 100%;
    }
    .swiper-pagination-bullet-active {
        background: #fa0 !important;
    }
    .MuiContainer-root {
        @media only screen and (max-width: 769px) {
            padding: 0px !important;
        }
    }
`;
export const SliderBar = styled(Box)`
    padding: 1rem 0;
    position: relative;
    & .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.primaryButton} !important;
    }
    @media only screen and (max-width: 769px) {
        padding: 0px !important;
    }
`;
export const GameSliderBar = styled(Box)`
    padding: 1rem 0;
    position: relative;
    @media only screen and (max-width: 769px) {
        padding: 0;
        margin-top: 20px;
    }
`;
export const SliderBox = styled(Box)`
    background: rgba(255, 255, 255, 0.05);
    overflow: hidden;
    height: 100%;
    position: relative;
    transition: all 0.3s;
    margin-top: 10px;
    &:hover {
        .cover {
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            border-radius: 0;
            top: 0;
        }
        img {
            transform: rotate(10deg) scale(1.5);
        }
    }
`;
export const SliderHeader = styled(Box)`
    // background: #272a37;
    display: flex;
    padding: 0.5rem 1rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: rgb(216, 216, 216) !important;
    align-items: center;
    svg {
        color: rgb(216, 216, 216) !important;
    }
`;
export const SliderHeader1 = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    //padding: 0.5rem 1rem 0.5rem 0;
    margin-bottom: 0.5rem;
    color: rgb(216, 216, 216) !important;
    align-items: center;
    svg,
    i {
        color: rgb(216, 216, 216) !important;
    }
`;
export const SliderName = styled(Typography)`
    font-family: League Spartan, Cyrillic, Cyrillic !important;
    user-select: none;
    margin-top: 0.3rem !important;
    text-transform: uppercase;
    @media only screen and (max-width: 500px) {
        //margin-left: 0.5rem !important;
    }
`;
export const SliderItem = styled(Box).attrs((props: any) => ({
    img: props.img
}))`
    background: url(${(props: any) => props.img}) center center no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 30px;
`;
export const SliderItem1 = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: auto !important;
    }
`;
export const SliderItem2 = styled(Box)`
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100% !important;
    }
`;
export const SliderItem3 = styled(Box).attrs((props: any) => ({
    img: props.img
}))`
    width: 100%;
    padding: 45% 0;
    background: url('${(props: any) => props.img}') center center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
`;

export const SkeletonPlaceholder = styled(SliderItem1)`
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 0.1;
        }
    }
    background: rgba(255, 255, 255, 0.05);
    height: 100%;

    img {
        animation: fadeIn 1s infinite alternate;
    }
`;
export const CoverBox = styled(Box)`
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in 0s !important;
    position: absolute;
    border-radius: 50%;
    top: 100%;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: gray;
`;
export const ProviderName = styled(Typography)`
    font-family: PT Sans !important;
    font-size: 0.9rem !important;
    font-weight: bold;
    line-height: inherit !important;
    user-select: none;
    margin: 0.5rem 0;
`;
export const GameName = styled(Typography)`
    font-family: PT Sans !important;
    font-size: 1rem !important;
    font-weight: bold;
    line-height: inherit !important;
    user-select: none;
    margin: 0.5rem 0;
`;
export const GameBtn = styled(Box)`
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    @media only screen and (max-width: 1400px) {
        margin: 0;
    }
`;
export const PlayGame = styled(Button)`
    background: #fa0 !important;
    font-family: League Spartan, Cyrillic !important;
    padding-top: 0.7rem;
`;
export const GameCardSlider = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 100%;
`;
export const Swiper = styled.div`
    padding-bottom: 32px;
    width: 100%;
    overflow: unset !important;
`;
export const SwiperSlide = styled.div`
    //overflow: unset !important;
    width: 100%;
`;
export const IconButtonWrapp = styled(IconButton)`
    padding: 0 !important;
`;
export const SwiperPromoButtonPrev = styled(ArrowBack)`
    width: 32px !important;
    height: 32px !important;
    &:after {
        content: unset !important;
        font-size: unset !important;
        color: white !important;
        font-weight: bold !important;
        width: 24px !important;
        height: 24px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media only screen and (max-width: 800px) {
        display: none !important;
    }
`;
export const SwiperPromoButtonNext = styled(ArrowForward)`
    width: 32px !important;
    height: 32px !important;
    &:after {
        content: unset !important;
        font-size: unset !important;
        color: white !important;
        font-weight: bold !important;
        width: 24px !important;
        height: 24px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media only screen and (max-width: 800px) {
        display: none !important;
    }
`;
export const SliderTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
        display: flex;
        align-items: center;
    }
`;
export const LoginBtn = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 12px !important;
    width: auto;
    margin: 0;
    cursor: pointer;
    background-color: transparent !important;
    border: 2px solid ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: ${(props) => props.theme.text};
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    padding-top: 0.5rem;
    &:hover {
        padding-top: 0.7rem;
        font-size: 13px !important;
    }
    @media only screen and (max-width: 500px) {
        display: none;
        font-size: 12px !important;
    }
`;
