import create from 'zustand'
import { toast } from 'react-toastify'
import ProfileApi from '../api/ProfileApi'
import { ToastBox } from '../pages/base/toast'

const useStore = create(() => ({

    // Player Update Data
    playerProfileUpdate: async (data) => {
        const flag = await ProfileApi.playerProfileUpdate(data)
        if (flag) {
            toast.success(<ToastBox letter='Success' />)
        }
    },

    // Verification Manage Part
    updateKycData: async (data) => {
        const flag = await ProfileApi.updateKycData(data)
        if (flag) {
            toast.success(<ToastBox letter='Success' />)
        }
    },
    removeKycData: async (data) => {
        const flag = await ProfileApi.removeKycData(data)
        if (flag) {
            toast.success(<ToastBox letter='Success' />)
        }
    },

    //  ------------------------------------------------------------------

    // Password Update, Session History
    changepassword: async (data) => {
        const flag = await ProfileApi.changepassword(data)
        if (flag) {
            toast.success(<ToastBox letter='Success' />)
        }
    },
    getSessionHistory: async (data) => {
        const rdata = await ProfileApi.getSessionHistory(data)
        if (rdata) {
            return rdata.data
        } else {
            return []
        }
    },

    //  ------------------------------------------------------------------

    // Nickname Update Function
    updateNickName: async (data) => {
        const flag = await ProfileApi.updateNickName(data)
        if (flag) {
            toast.success(<ToastBox letter='Success' />)
            setTimeout(() => {
                window.location.reload()
            }, 100)
        }
    },

    // Update Language
    updateLanguage: (data) => {
        ProfileApi.updateLanguage(data)
    },

    // Privacy Policy Page
    sendNotificationSetting: async (data) => {
        const rdata = await ProfileApi.sendNotificationSetting(data)
        if (rdata) {
            toast.success(<ToastBox letter="Verification Email sent" />)
        }
    },
    sendVerifyEmailRequest: async (data) => {
        const rdata = await ProfileApi.sendVerifyEmailRequest(data)
        if (rdata) {
            toast.success(<ToastBox letter="Verification Email sent" />)
            return true
        } else {
            return false
        }
    },
    sendVerifySMSRequest: async (data) => {
        const rdata = await ProfileApi.sendVerifySMSRequest(data)
        if (rdata) {
            toast.success(<ToastBox letter="Verification SMS sent" />)
            return true
        } else {
            return false
        }
    },
    sendVerifyEmailCode: async (data) => {
        const rdata = await ProfileApi.sendVerifyEmailCode(data)
        if (rdata) {
            toast.success(<ToastBox letter="Email Verified" />)
            setTimeout(() => {
                window.location.assign('/profile?page=3')
            }, 500)
        }
    },
    sendVerifySMSCode: async (data) => {
        const rdata = await ProfileApi.sendVerifySMSCode(data)
        if (rdata) {
            toast.success(<ToastBox letter="Mobile number veirified" />)
            setTimeout(() => {
                window.location.assign('/profile?page=0')
            }, 500)
        }
    },

    //  ------------------------------------------------------------------

    // Forgot Password Request
    forgotPasswordRequest: async (data) => {
        const rdata = await ProfileApi.forgotPasswordRequest(data)
        if (rdata) {
            toast.success(<ToastBox letter={`Verification ${data.send} sent`} />)
            return true
        } else {
            return false
        }
    },

    //  ------------------------------------------------------------------

    // Resent Account Activation Email
    resendAccountActivateEmail: async (data) => {
        const rdata = await ProfileApi.resendAccountActivateEmail(data)
        if (rdata) {
            toast.success(<ToastBox letter="Activation Email sent" />)
            return true
        } else {
            return false
        }
    },

    // Player Currency Update
    updatePlayerCurrency: async (data) => {
        const rdata = await ProfileApi.updatePlayerCurrency(data)
        if (rdata) {
            return rdata.data
        }
    },

    // set user wallet address
    setUserWalletAddress: async (data) => {
        const rdata = await ProfileApi.setUserWalletAddress(data)
        if (rdata.data === 'ok') {
            toast.success(<ToastBox letter='Now you can use this wallet to sign up' />)
        }
    },

    // send withdraw request
    sendWithdrawRequest: async (data) => {
        const rdata = await ProfileApi.sendWithdrawRequest(data)
        if (rdata.data === 'ok') {
            toast.success(<ToastBox letter='Withdraw request sent' />)
            return rdata.data
        }
    },

    // send transcation hash
    checkTransactionHash: async (data) => {
        const rdata = await ProfileApi.checkTransactionHash(data)
        return rdata
    },

    // HideZero Option
    updateHidezero: async (data) => {
        const rdata = await ProfileApi.updateHidezero(data)
        if (rdata) {
            return rdata.data
        }
    },

    // Add Currency to User
    addPlayerCurrency: async (data) => {
        const rdata = await ProfileApi.addPlayerCurrency(data)
        if (rdata) {
            return rdata.data
        }
    },

    // Update Dis currency Of User
    updateDisCurrency: async (data) => {
        const rdata = await ProfileApi.updateDisCurrency(data)
        if (rdata) {
            return rdata.data
        }
    }

}))

export default useStore
