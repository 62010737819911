import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class ProfileApi extends APIAbstract {
    // Player Update Data
    playerProfileUpdate(data) {
        return this.post('/api/user/playerProfileUpdate', data);
    }

    // Verification Manage Part
    updateKycData(data) {
        return this.post('/api/user/updateKycData', data);
    }
    removeKycData(data) {
        return this.post('/api/user/removeKycData', data);
    }

    //  ------------------------------------------------------------------

    // Password Update, Session History
    changepassword(data) {
        return this.post('/api/user/changepassword', data);
    }
    getSessionHistory(data) {
        return this.post('/api/user/getSessionHistory', data);
    }

    //  ------------------------------------------------------------------

    // Nickname Update Function
    updateNickName(data) {
        return this.post('/api/user/updateNickName', data);
    }

    // Update Language
    updateLanguage(data) {
        return this.post('/api/user/updateLanguage', data);
    }

    // Privacy Policy Page
    sendNotificationSetting(data) {
        return this.post('/api/user/sendNotificationSetting', data);
    }
    sendVerifyEmailRequest(data) {
        return this.post('/api/user/sendVerifyEmailRequest', data);
    }
    sendVerifySMSRequest(data) {
        return this.post('/api/user/sendVerifySMSRequest', data);
    }
    sendVerifyEmailCode(data) {
        return this.post('/api/user/sendVerifyEmailCode', data);
    }
    sendVerifySMSCode(data) {
        return this.post('/api/user/sendVerifySMSCode', data);
    }

    //  ------------------------------------------------------------------

    // Forgot Password Request
    forgotPasswordRequest(data) {
        return this.post('/api/user/forgotPasswordRequest', data);
    }

    // Resent Account Activation Email
    resendAccountActivateEmail(data) {
        return this.post('/api/user/resendAccountActivateEmail', data);
    }

    // Player Currency Upate
    updatePlayerCurrency(data: object) {
        return this.post('/api/user/updatePlayerCurrency', data);
    }

    // set user wallet address
    setUserWalletAddress(data: object) {
        return this.post('/api/user/setUserWalletAddress', data);
    }

    // send transaction hash
    sendWithdrawRequest(data: object) {
        return this.post('/api/user/sendWithdrawRequest', data);
    }
    
    // send transaction hash
    checkTransactionHash(data: object) {
        return this.post('/api/user/checkTransactionHash', data);
    }

    // HideZero Option
    updateHidezero(data: object) {
        return this.post('/api/user/updateHidezero', data);
    }

    // Add Currency to User
    addPlayerCurrency(data: object) {
        return this.post('/api/user/addPlayerCurrency', data);
    }

    // Update Dis currency Of User
    updateDisCurrency(data: object) {
        return this.post('/api/user/updateDisCurrency', data);
    }
}

const ProfileClass = new ProfileApi(`${Root.serverUrl}`);

export default ProfileClass;
