import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const NotificationPart = styled.div`
    margin-right: 0.5rem;
    svg {
        color: ${(props) => props.theme.text};
    }
    .MuiBadge-badge {
        background: ${(props) => props.theme.primaryButton} !important;
        color: white;
        padding-top: 3px;
    }
    @media only screen and (max-width: 500px) {
        margin-right: 0rem !important;
    }
`;
export const NotificationList = styled(Box)<{ hasItems: boolean }>`
    width: ${({ hasItems }) => hasItems ? '400px' : ''};
    max-height: 100%;
    background: ${({ theme }) => theme.background.primary};
    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`;
export const NotifyListItem = styled(Box)`
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #3b4253;
    &:hover {
        background: ${({ theme }) => theme.background.secondary};
    }
`;
export const NotifyItem = styled(Box)`
    padding: 0 0.5rem;
    color: ${(props) => props.theme.text};
    cursor: pointer;
    font-family: 'PT Sans' !important;
    p {
        margin: 0;
        user-select: none;
    }
    small {
        user-select: none;
        font-size: 0.875rem;
    }
    b {
        font-size: 1rem;
    }
    a {
        color: ${(props) => props.theme.primaryButton} !important;
    }
`;
export const NotifyButtonPart = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    button {
        width: 100%;
    }
`;
export const AvatarBox = styled(Box)`
    display: flex;
    user-select: none;
    img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        border-radius: 50%;
        user-select: none;
    }
`;
export const DepositBtn = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px;
    width: auto;
    margin-right: 0.5rem !important;
    cursor: pointer;
    background-color: ${(props) => props.theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
        font-size: 15px;
    }
    @media only screen and (max-width: 500px) {
        font-size: 10px !important;
        margin-right: 0.5rem;
        padding: 0.5rem 0.5rem !important;
        &:hover {
            font-size: 10px;
        }
    }
    @media only screen and (max-width: 400px) {
        margin-right: 0.2rem;
        padding: 0.5rem 0.2rem;
        &:hover {
            font-size: 10px;
        }
    }
`;
