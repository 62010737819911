import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export const Introduce = styled(Box)`
    display: flex;
    justify-content: center;
    height: 100%;
`;
export const IntroContent = styled.div`
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    margin: 15px auto;
    min-height: 450px;
    background: ${({ theme }) => theme.background.secondary};
    padding: 2rem;
    color: ${({ theme }) => theme.text} !important;
    box-sizing: border-box;
    & p,
    h5,
    h6 {
        color: ${({ theme }) => theme.text} !important;
        font-family: 'PT Sans', sans-serif !important;
    }

    @media only screen and (max-width: 768px) {
        margin: 0;
        width: 100%;
        box-shadow: unset;
    }
    @media only screen and (max-width: 500px) {
        margin: 0;
        padding: 1rem 0;
        //width: calc(100% - 2rem);
    }

    .content-letter {
        padding: 0.2rem 0;
    }
    .atag {
        text-decoration: underline;
        cursor: pointer;
    }
    .p-0 {
        padding: 0;
    }
    .mt-1 {
        margin-top: 1rem;
    }
    .ft-bold {
        font-weight: 500;
    }
    .email {
        text-decoration: underline;
        cursor: pointer;
        color: ${({ theme }) => theme.primaryButton};
    }
    & a {
        color: ${({ theme }) => theme.primaryButton};
    }
`;
export const ContentLetter = styled(Typography)`
    padding: 0.2rem 0;
`;
export const ContentLetterMT1 = styled(Typography)`
    padding: 0.2rem 0;
    margin-top: 1rem;
`;
export const ProfileContent = styled(Container)`
    margin: 1rem auto;
    padding: 0 !important;
    width: calc(100% - 2rem);
    @media (max-width: 768px) {
        margin: 0 !important;
        width: 100%;
    }
`;
export const BonusGamesTextWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const BonusGamesTitle = styled.h1`
    font-family: 'PT Sans', sans-serif;
    font-size: 30px;
    color: white;
    margin: 0;
`;
