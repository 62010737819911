import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class ConfigApi extends APIAbstract {
    // Get Site Config
    getPlayerBaseData() {
        return this.get('/api/config/getPlayerBaseData');
    }
    getPlayerContentData() {
        return this.get('/api/config/getPlayerContentData');
    }
    getAllConfig(data = {}) {
        return this.get('/api/config/getAllConfig', data);
    }
    getRequestLog(data = {}) {
        return this.get('/api/config/getRequestLog', data);
    }

    // ContentData in Dashboard
    getHomePageContentData(data = {}) {
        return this.post('/api/config/getHomePageContentData', data);
    }

    // getHomePageGameData list in dashboard
    getHomePageGameData(data = {}) {
        return this.post('/api/config/getHomePageGameData', data);
    }

    // getPokerSliderList in Poker Dashboard
    getPokerSliderList(data = {}) {
        return this.post('/api/config/getPokerSliderList', data);
    }

    // Support Data in Support Page
    getSupportTypes(data = {}) {
        return this.post('/api/config/getSupportTypes', data);
    }
    getSupportData(data = {}) {
        return this.post('/api/config/getSupportData', data);
    }

    //  ------------------------------------------------------------------

    // Notification Management Part
    getMyNotification(data = {}) {
        return this.post('/api/config/getMyNotification', data);
    }
    readAllNotification(data = {}) {
        return this.post('/api/config/readAllNotification', data);
    }

    //  ------------------------------------------------------------------

    //  ------------------------------------------------------------------

    // Site Url Check (Activate accouont)
    getUrlParseData(data = {}) {
        return this.post('/api/config/getUrlParseData', data);
    }

    // Price List
    getPriceList() {
        return this.post('/api/config/getPriceList');
    }

    // Show Our site Payment List to payment page
    getAllGatewayListForPayment() {
        return this.post('/api/config/getAllGatewayListForPayment');
    }

    getPromotionTCList() {
        return this.get('/api/config/getPromotionTCList');
    }

    getMultilang() {
        return this.post('/api/config/getMultilang');
    }

    getManifest() {
        return this.get('/api/config/getManifest');
    }
}

const ConfigClass = new ConfigApi(`${Root.serverUrl}`);

export default ConfigClass;
