import create from 'zustand';

const useStore = create<any>((set, get) => ({
    isDesktopSidebar: true, // desktop sidebar
    sidebar: false,
    nick: false,
    gamemode: false,
    authModal: { login: false, register: false },
    shortAuthModal: false,
    forgot: false,

    profileEdit: false,
    profileModal: false,
    cUrl: '',

    setDesktopSidebar: (flag: boolean) => {
        localStorage.setItem('MainSidebar', String(flag))
        set({ isDesktopSidebar: flag });
    },



    setAuthLoginModal: (status: boolean)=> {
        set({ authModal: { login: status, register: false } });
    },

    setAuthModal: async (authModalData: any, history: any) => {
        if (authModalData.register) {
            set({ authModal: authModalData });
            if (window.innerWidth <= 768) {
                history.push(`/register${history.location.search}`);
            } else {
                history.push(`/home${history.location.search}`);
            }
        } else {
            if (!authModalData.login) {
                history.push('/home');
            }
            set({ authModal: authModalData });
        }
    },
    setShortAuthModal: async (flag1, flag2, history) => {
        set({ shortAuthModal: flag1 });
        if (flag1) {
            if (window.innerWidth <= 768) {
                history.push(`/short_register${history.location.search}`);
            } else {
                history.push(`/home${history.location.search}`);
            }
        } else {
            history.push(`/home`);
        }
        if (flag2) {
            set({ authModal: { login: true, register: false } });
        } else {
            set({ authModal: { login: false, register: false } });
        }
    },
    updateAuthModal: (authModalData) => {
        set({ authModal: authModalData });
    },
    setForgotModal: async (bool) => {
        set({ forgot: bool });
    },
    setNickModal: async (flag: boolean) => {
        set({ nick: flag });
    },
    setSidebar: async (flag: boolean) => {
        set({ sidebar: flag });
    },
    setGameModal: async (flag) => {
        set({ gamemode: flag });
    },
    setProfileEdit: async (flag) => {
        set({ profileEdit: flag });
    },
    setProfileModal: async (flag) => {
        set({ profileModal: flag });
    },
    continueUrl: async (history) => {
        set({ profileModal: false, profileEdit: false });
        history.push(get().cUrl);
    },
    CalcRtFixed(num, currency, dis_currency, priceData) {
        const count = 2;
        // eslint-disable-next-line
        let re = new RegExp('^-?\\d+(?:.\\d{0,' + (count || -1) + '})?');
        const num1 = (num * priceData[dis_currency]) / priceData[currency];
        let changeNum = num1;
        if (num1 < 0.000001) {
            changeNum = num1 ? Number(num1.toFixed(count)) : 0;
            if (Number(changeNum) === 0.000001) {
                changeNum = 0;
            }
        }

        const ree = changeNum.toString().match(re)[0];
        const reee = Number(ree).toFixed(count);
        return Number(reee);
    },
    RtFixed(num, currency, currencies) {
        let count = 2;
        let oneCurrency
        if (currency.includes('-')) {
            oneCurrency = currencies.find(
                (item) => `${item.currency}-${item.network}` === currency
            );
        } else {
            oneCurrency = currencies.find(
                (item) => item.currency === currency
            );
        }
        
        
        if (oneCurrency && oneCurrency.type === 'crypto') {
            count = 6;
        }
        const re = new RegExp('^-?\\d+(?:.\\d{0,' + (count || -1) + '})?');
        let changeNum = num;
        if (num < 0.000001) {
            changeNum = num ? num.toFixed(count) : 0;
            if (Number(changeNum) === 0.000001) {
                changeNum = 0;
            }
        }

        const ree = changeNum.toString().match(re)[0];
        const reee = Number(ree).toFixed(count);
        return Number(reee);
    },
    pushUrl(history, url) {
        if (get().profileEdit) {
            set({ profileModal: true, cUrl: url });
            return false;
        } else {
            history.push(url);
            return true;
        }
    }
}));

export default useStore;
