import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';

export const AuthContent = styled(Box)`
    width: 450px;
    margin: auto;
    color: ${(props) => props.theme.text};
    @media (max-width: 500px) {
        width: 100% !important;
    }
`;
export const Letter = styled(Typography)`
    text-align: center;
    margin: 2rem 0 0 !important;
    user-select: none;
    font-size: 20px !important;
    font-weight: 700;
    color: ${(props) => props.theme.text};
    font-family: 'PT Sans' !important;
`;
export const RadioGroupWrapp = styled(RadioGroup)`
    color: ${(props) => props.theme.text};
    font-family: League Spartan, Cyrillic !important;
    svg {
        color: ${({ theme }) => theme.primaryButton} !important;
    }
`;
export const LetterInput1 = styled(TextField)({
    width: '100%',
    '& input': {
        color: 'rgb(216,216,216)',
        fontFamily: 'PT Sans !important'
    },
    '& input:-webkit-autofill': {
        background: 'transparent'
    },
    '& label': {
        color: 'rgb(216,216,216)',
        fontFamily: 'PT Sans !important'
    },
    '& label.Mui-focused': {
        color: 'rgb(216,216,216)'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgb(216,216,216)'
        },
        '&:hover fieldset': {
            borderColor: 'rgb(216,216,216)'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgb(216,216,216)'
        }
    }
});
export const AccessButton = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    width: 100%;
    margin-top: 1rem !important;
    font-size: 16px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const Redirect = styled.span`
    font-family: PT Sans !important;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    color: ${({ theme }) => theme.primaryButton};
`;
