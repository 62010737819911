import React, { useState } from 'react';
import { Box } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import AuthZustand from '../../../../zustand/AuthZustand';
import ProfileZustand from '../../../../zustand/ProfileZustand';

import {
    Popover1,
    LanguageHeader,
    LanguageItem,
    LanguageString,
    LanguageTitle
} from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';

const IntlDropdown: React.FC = () => {
    const theme = useTheme() as Theme;
    const { i18n } = useTranslation();
    const { isAuth, languages } = AuthZustand((state) => state);
    const { updateLanguage } = ProfileZustand((state) => state);

    const [dropTag, setDropTag] = useState(null);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState('');

    const handleLangUpdate = (e, lang) => {
        e.preventDefault();
        i18n.changeLanguage(lang);
        localStorage.setItem('player-langauge', lang);
        if (isAuth) {
            updateLanguage({ lang: lang });
        }
        changeDropTag(null);
    };

    const changeDropTag = (e: any) => {
        setDropTag(e);
        const open1 = Boolean(e);
        const id1 = open1 ? 'language_select' : '';
        setOpen(open1);
        setId(id1);
    };

    return (
        <LanguageHeader>
            <LanguageTitle
                aria-describedby={id}
                onClick={(e: any) => changeDropTag(e.currentTarget)}
            >
                <ReactCountryFlag
                    countryCode={i18n.language === 'en' ? 'us' : i18n.language}
                    svg
                    alt=""
                />
                <LanguageString>{i18n.language}</LanguageString>
            </LanguageTitle>
            <Popover1
                id={id}
                open={open}
                anchorEl={dropTag}
                onClose={() => changeDropTag(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                {languages.map((item, key) => (
                    <Box
                        sx={{ background: theme.background.primary }}
                        key={key}
                        onClick={(e: any) => handleLangUpdate(e, item.language)}
                    >
                        <LanguageItem>
                            <ReactCountryFlag
                                countryCode={
                                    item.language === 'en'
                                        ? 'us'
                                        : item.language
                                }
                                svg
                            />
                            <span>{item.name}</span>
                        </LanguageItem>
                    </Box>
                ))}
            </Popover1>
        </LanguageHeader>
    );
};

export default IntlDropdown;
