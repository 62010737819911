import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { passwordStrength } from 'check-password-strength';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingContext } from '../../../../layout/context/loading';
import AuthZustand from '../../../../zustand/AuthZustand';
import BaseZustand from '../../../../zustand/BaseZustand';
import { Root } from '../../../../config/config';
import * as S from '../../style';
import { ExtendedLetterInput } from '../../../userDashboard/history/style';
import countryToCurrency from 'country-to-currency';

import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalTheme } from '@web3modal/ethers/react'


const RegisterContent = () => {
    const { open } = useWeb3Modal();

    const { address,isConnected } = useWeb3ModalAccount();
    const { setThemeVariables } = useWeb3ModalTheme()
    useEffect(() => {
        setThemeVariables({
            '--w3m-z-index': 10003
        })
    }, [])
        
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const { setAuthModal } = BaseZustand((state) => state);
    const {
        myData,
        requestAttempt,
        currencies,
        regiBanner,
        countryData,
        userRegister,
        userRegisterByWallet
    } = AuthZustand((state) => state);

    const [regFlag, setRegFlag] = useState(false)

    const [email, setEmail] = useState('');
    const [reemail, setReEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [username, setUsername] = useState('');
    // const [phone, setPhone] = useState('');
    // const [phoneCode, setPhoneCode] = useState({ label: '39 - IT', value: '39', code: 'IT' });
    const [country, setCountry] = useState('IT');
    const [state, setStateValue] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [postcode, setPostCode] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [currency, setCurrency] = useState('')
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [pstatus, setPStatus] = useState({
        id: 0,
        value: 'Too weak',
        contains: []
    });
    const [referral, setReferral] = useState('');
    const [currencyList, setCurrencyList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [phoneList, setPhoneList] = useState([]);
    const [year18, setYear18] = useState(false);
    const [terms, setTerms] = useState(false);
    const [subscribe1, setSubscribe1] = useState(true);
    const [subscribe2, setSubscribe2] = useState(true);
    const [capcha, setCapcha] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    useEffect(() => {
        const stateData = queryString.parse(history.location.search);
        if (stateData.uri === 'signup' && stateData.referral) {
            setReferral(String(stateData.referral));
        }
        if (stateData.clickid) {
            window.localStorage.setItem('clickid', String(stateData.clickid));
        }
        //  eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        const submit = async () => {
            
            if (isConnected && address && regFlag) {
                showLoading();
                await userRegisterByWallet({address})
                setRegFlag(false)
                hideLoading();
                
            }
        }
        submit()
    }, [isConnected])

    useEffect(() => {
        const init = async () => {
            const arr = [];
            for (const i in currencies) {
                if (currencies[i].type === 'crypto') {
                    arr.push({
                        label: `${currencies[i].currency} (${currencies[i].network})`,
                        value: `${currencies[i].currency}-${currencies[i].network}`
                    });
                } else {
                    arr.push({
                        label: `${currencies[i].currency} (Fiat)`,
                        value: currencies[i].currency
                    });
                }
            }
            setCurrencyList(arr);
        };
        init();
    }, [currencies]);

    useEffect(() => {
        if (myData && myData?.countryCode) {
            if (currencyList.find((item: any) => item.value === countryToCurrency[myData.countryCode])) {
                setCurrency(countryToCurrency[myData.countryCode])
            } else {
                setCurrency('EUR')
            }
        } else {
            setCurrency('EUR')
        }
    }, [myData?.countryCode])

    useEffect(() => {
        const init = async () => {
            if (countryData.length) {
                const arr = [],
                    arr1 = [];
                for (const i in countryData) {
                    arr.push({
                        label: countryData[i].country,
                        value: countryData[i].code
                    });
                    arr1.push({
                        label: `${countryData[i].phonecode} - ${countryData[i].code}`,
                        value: countryData[i].phonecode,
                        code: countryData[i].code
                    });
                }
                setCountryList(arr);
                setPhoneList(arr1);
            }
        };
        init();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (myData && phoneList.length) {
            const oneItem = phoneList.find(
                (item) => item.code === myData.countryCode
            );
            if (oneItem) {
                setCountry(myData.countryCode);
                // setPhoneCode(oneItem);
            }
        }
    }, [myData, phoneList]);

    const ValidateEmail = (mail) => {
        // eslint-disable-next-line
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    };

    const checkRegisterData = () => {
        if (!firstname) {
            return 'Please input firstname';
        } else if (!lastname) {
            return 'Please input lastname';
        } else if (!username) {
            return 'Please input username';
        } else if (username.length < 6) {
            return 'Username have to more than 6 characters';
        } else if (username.length > 23) {
            return 'Username length have to small than 23';
        } else if (username.indexOf(' ') > -1) {
            return "Username can't include space";
        } else if (/^([a-zA-Z0-9-]+)$/.test(firstname) === false) {
            return "firstname can't include special letter";
        } else if (/^([a-zA-Z0-9-]+)$/.test(lastname) === false) {
            return "lastname can't include special letter";
        } else if (/^([a-zA-Z0-9-]+)$/.test(username) === false) {
            return "Username can't include special letter";
        } else if (!email || ValidateEmail(email) === false) {
            return 'Please input correct email';
        } else if (email !== reemail) {
            return 'Please input correct email';
        } else if (!currency) {
            return 'Please select currency';
        } else if (!state) {
            return 'Please input state';
        } else if (!city) {
            return 'Please input city';
        } else if (!street) {
            return 'Please input street';
        } else if (!postcode) {
            return 'Please input postcode';
        } else if (!terms) {
            return 'Please agree with our terms and conditions';
        } else if (!year18) {
            return 'You have to be 18+ years old';
        } else if (!password) {
            return 'Please input correct password';
        } else if (password.length < 8) {
            return 'Password have to more than 8 chracters';
        } else if (password !== repassword) {
            return "Password and confirm password don't match";
        } else if (username === password) {
            return "Username and password don't have to match";
        } else if (!pstatus.contains.includes('uppercase')) {
            return 'Password have to include uppercase letter';
        } else if (!pstatus.contains.includes('lowercase')) {
            return 'Password have to include lowercase letter';
        } else if (!pstatus.contains.includes('number')) {
            return 'Password have to include number';
        } else if (!pstatus.contains.includes('symbol')) {
            return 'Password have to include symbol';
        } else {
            return true;
        }
    };

    const register = async () => {
        const flag = checkRegisterData();
        if (flag === true) {
            showLoading();
            const clickid = window.localStorage.getItem('clickid');
            const sendData = {
                firstname,
                lastname,
                username,
                email: email.split(' ').join(''),
                country,
                state,
                city,
                street,
                postcode,
                currency,
                password,
                referral,
                language: i18n.language,
                promo_email: subscribe1,
                promo_sms: subscribe2,
                clickid,
                coupon: promoCode,
                capcha
            };
            const result = await userRegister(sendData);
            hideLoading();
            if (result) {
                setAuthModal({ register: false, login: false }, history);
                window.localStorage.removeItem('clickid');
            }
        } else {
            toast.error(t(flag));
        }
    };

    const goTerms = () => {
        setAuthModal({ register: false, login: false }, history);
        history.push('/terms-condition');
    };

    const changeCountry = (value) => {
        setCountry(value.value);
    };

    const changePassword = (value) => {
        setPStatus(passwordStrength(value));
        setPassword(value);
    };

    const getPassColor = (val) => {
        if (val === 0) {
            return '#ec3200';
        } else if (val === 1) {
            return '#ff9500';
        } else if (val === 2) {
            return '#48a902';
        } else if (val === 3) {
            return '#1d79dd';
        }
    };

    const changeCurrency = (v) => {
        setCurrency(v.value);
    };

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword1 = (event) => event.preventDefault();
    const handleMouseDownPassword2 = (event) => event.preventDefault();

    return (
        <S.RegisterContent>
            <S.Letter>{t('Registration Page')}</S.Letter>
            <img
                src={`${Root.adminWebsiteUrl}${regiBanner[i18n.language]}`}
                alt=""
            />
            <Grid container spacing={1}>
                <Grid item md={4} sm={12} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('FirstName')}
                        variant="outlined"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('LastName')}
                        variant="outlined"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('UserName')}
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <S.LetterInput
                        type="email"
                        label={t('Email')}
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <S.LetterInput
                        type="email"
                        label={t('Confirm Email')}
                        variant="outlined"
                        value={reemail}
                        onChange={(e) => setReEmail(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Autocomplete
                        disablePortal
                        value={
                            currencyList.find(
                                (item) => item.value === currency
                            ) || { value: '', label: t('Please select') }
                        }
                        options={currencyList}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) => changeCurrency(v)}
                        renderOption={(props, option) => (
                            <Box {...props} component="li">
                                {option.label}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <S.LetterInput {...params} label={t('Currency')} />
                        )}
                        sx={{ marginTop: '0.5rem' }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Autocomplete
                        disablePortal
                        value={
                            countryList.find(
                                (item) => item.value === country
                            ) || { value: 'IT', label: 'Italy' }
                        }
                        options={countryList}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) => changeCountry(v)}
                        renderOption={(props, option) => (
                            <Box {...props} component="li">
                                <img
                                    style={{ width: '20px' }}
                                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${option.value.toLowerCase()}.svg`}
                                    alt=""
                                />
                                &nbsp;
                                {option.label}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <ExtendedLetterInput
                                {...params}
                                label={t('Country')}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <img
                                            style={{ width: '20px' }}
                                            src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${country
                                                    ? country.toLowerCase()
                                                    : 'it'
                                                }.svg`}
                                            alt=""
                                        />
                                    )
                                }}
                            />
                        )}
                        sx={{ marginTop: '0.5rem' }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('State')}
                        variant="outlined"
                        value={state}
                        onChange={(e) => setStateValue(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('City')}
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('Street')}
                        variant="outlined"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('ZipCode')}
                        variant="outlined"
                        value={postcode}
                        onChange={(e) => setPostCode(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <S.LetterInput
                        type="text"
                        label={t('Promo Code')}
                        variant="outlined"
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <S.LetterInput
                        type={showPassword1 ? 'text' : 'password'}
                        label={t('Password')}
                        variant="outlined"
                        value={password}
                        onChange={(e) => changePassword(e.target.value)}
                        autoComplete="off"
                        sx={PasswordInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        onMouseDown={handleMouseDownPassword1}
                                        edge="end"
                                    >
                                        {showPassword1 ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {password ? (
                        <div>
                            <S.LinearProgressWarp
                                variant="determinate"
                                status={getPassColor(pstatus.id)}
                                value={25 * (pstatus.id + 1)}
                            />
                            <S.WrappInputLabel color={getPassColor(pstatus.id)}>
                                {t(pstatus.value)}
                            </S.WrappInputLabel>
                            {!pstatus.contains.includes('uppercase') && (
                                <S.WrappInputLabel color="#ec3200">
                                    {t(
                                        'Password have to include uppercase letter'
                                    )}
                                </S.WrappInputLabel>
                            )}
                            {!pstatus.contains.includes('lowercase') && (
                                <S.WrappInputLabel color="#ec3200">
                                    {t(
                                        'Password have to include lowercase letter'
                                    )}
                                </S.WrappInputLabel>
                            )}
                            {!pstatus.contains.includes('number') && (
                                <S.WrappInputLabel color="#ec3200">
                                    {t('Password have to include number')}
                                </S.WrappInputLabel>
                            )}
                            {!pstatus.contains.includes('symbol') && (
                                <S.WrappInputLabel color="#ec3200">
                                    {t('Password have to include symbol')}
                                </S.WrappInputLabel>
                            )}
                            {password.length < 8 && (
                                <S.WrappInputLabel color="#ec3200">
                                    {t(
                                        'Password have to more than 8 chracters'
                                    )}
                                </S.WrappInputLabel>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item md={6} xs={12}>
                    <S.LetterInput
                        type={showPassword2 ? 'text' : 'password'}
                        label={t('Confirm Password')}
                        variant="outlined"
                        value={repassword}
                        onChange={(e) => setRepassword(e.target.value)}
                        autoComplete="off"
                        sx={PasswordInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword2}
                                        onMouseDown={handleMouseDownPassword2}
                                        edge="end"
                                    >
                                        {showPassword2 ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <S.Subscriptions>
                        <S.SubscripContent>
                            <S.SubscripEmail>
                                {t('I am older than 18 y/o')}
                            </S.SubscripEmail>
                            <S.SubscripTerm>
                                {t('I accept')}{' '}
                                <span onClick={() => goTerms()}>
                                    {t('terms & conditions')}
                                </span>{' '}
                                {t('page')}
                            </S.SubscripTerm>
                            <S.SubscripEmail>
                                {t('Send promos by Email')}
                            </S.SubscripEmail>
                            <S.SubscripEmail>
                                {t('Send promos by SMS')}
                            </S.SubscripEmail>
                        </S.SubscripContent>
                        <S.SubscripContent>
                            <S.CheckboxWrapp
                                onClick={() => setYear18(!year18)}
                                color="primary"
                                checked={year18}
                            />
                            <S.CheckboxWrapp
                                onClick={() => setTerms(!terms)}
                                color="primary"
                                checked={terms}
                            />
                            <S.CheckboxWrapp
                                onClick={() => setSubscribe1(!subscribe1)}
                                color="primary"
                                checked={subscribe1}
                            />
                            <S.CheckboxWrapp
                                onClick={() => setSubscribe2(!subscribe2)}
                                color="primary"
                                checked={subscribe2}
                            />
                        </S.SubscripContent>
                    </S.Subscriptions>
                </Grid>
                {requestAttempt.register && (
                    <S.Captcha>
                        <ReCAPTCHA
                            sitekey={Root.siteKey}
                            onChange={(e) => setCapcha(e)}
                        />
                    </S.Captcha>
                )}
            </Grid>
            <S.AccessButton1 onClick={() => register()}>
                {t('REGISTER')}
            </S.AccessButton1>
            <S.AccessButton1 onClick={() => {
                open()
                setRegFlag(true)
            }} >
                {t('Sign up with wallet')}
            </S.AccessButton1>
            <S.FooterText>
                {t('Are you already registered ?')}&nbsp;
                <S.Redirect
                    onClick={() =>
                        setAuthModal({ register: false, login: true }, history)
                    }
                >
                    {t('Login now!')}
                </S.Redirect>
            </S.FooterText>
            <S.Redirect
                onClick={() => {
                    history.push('/support');
                    setAuthModal({ register: false, login: false }, history);
                }}
            >
                {t('Need Help ?')}
            </S.Redirect>
        </S.RegisterContent>
    );
};

const PasswordInput = {
    width: '100%',
    marginTop: '0.5rem !important',
    '& input': {
        color: 'rgb(216,216,216)',
        fontFamily: 'PT Sans !important'
    },
    '& input:-internal-autofill-selected': {
        backgroundColor: '#272a37 !important'
    },
    '& input:-webkit-autofill': {
        background: 'transparent'
    },
    '& label': {
        color: 'rgb(216,216,216)',
        fontFamily: 'PT Sans !important'
    },
    '& svg': {
        color: 'rgb(216,216,216)'
    },
    '& label.Mui-focused': {
        color: 'rgb(216,216,216)'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgb(216,216,216)'
        },
        '&:hover fieldset': {
            borderColor: 'rgb(216,216,216)'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgb(216,216,216)'
        }
    }
};

export default RegisterContent;
