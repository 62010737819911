import { useState, Fragment, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';

import { Root } from '../../../../config/config';
import { isAddress } from 'web3-validator';
import AuthZustand from '../../../../zustand/AuthZustand';
import BaseZustand from '../../../../zustand/BaseZustand';

import { UserDropDownList } from './variable';
import { AvatarBox, ListItem } from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';
import { changeAddressFormat } from '../../../../helpers';

const AvatarComponent = () => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();

    const history = useHistory();
    const { user, balanceData, vips, userLogout } = AuthZustand(
        (state) => state
    );
    const { pushUrl, setSidebar } = BaseZustand((state) => state);

    const [dropTag, setDropTag] = useState(null);
    const open = Boolean(dropTag);
    const id = open ? 'simple-popover' : undefined;

    const avartarUrl = useMemo(() => {
        const cItem = vips.find((item) => item._id === balanceData.vipLevel);
        if (cItem) {
            return `${Root.adminWebsiteUrl}${cItem.avatar}`;
        } else {
            return `${Root.adminWebsiteUrl}/image/site/avatar/default.ico`;
        }
    }, [balanceData, vips]);

    const changePage = useCallback(async (url) => {
        const flag = await pushUrl(history, url);
        if (flag) {
            setDropTag(null);
            setSidebar(false);
        }
        // eslint-disable-next-line
    }, []);

    const userLogoutFunc = useCallback(() => {
        userLogout();
        setDropTag(null);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <AvatarBox
                aria-describedby={id}
                className="avatar"
                onClick={(e) => setDropTag(e.currentTarget)}
                sx={{
                    background: theme.background.primary,
                    color: theme.text
                }}
            >
                <img src={avartarUrl} alt="" />
            </AvatarBox>
            <Popover
                id={id}
                open={open}
                anchorEl={dropTag}
                onClose={() => setDropTag(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                sx={{ backgroundColor: 'transparent' }}
            >
                {UserDropDownList.map((item, key) => (
                    <Fragment key={key}> 
                        {item.name === 'Logout' ? (
                            <ListItem
                                onClick={() => userLogoutFunc()}
                                sx={{
                                    background: theme.background.primary,
                                    color: theme.text
                                }}
                            >
                                {t('Logout')}
                            </ListItem>
                        ) : (
                            <ListItem
                                onClick={() => changePage(item.url)}
                                sx={{
                                    background:
                                        theme.background.primary,
                                    color: theme.text
                                }}
                            >
                                {t(item.name)}{' '}
                                {key === 0 && (isAddress(user.username) ? `(${changeAddressFormat(user.username)})` : `(${user.username})` )}
                            </ListItem>
                            // <>
                            //     {item.name === 'Sport History' ? (
                            //         <>
                            //             {Root.site === 'test108.com' ? (
                            //                 <ListItem
                            //                     onClick={() =>
                            //                         changePage(item.url)
                            //                     }
                            //                     sx={{
                            //                         background:
                            //                             theme.background
                            //                                 .primary,
                            //                         color: theme.text
                            //                     }}
                            //                 >
                            //                     {t(item.name)}{' '}
                            //                     {key === 0 &&
                            //                         `(${user.username})`}
                            //                 </ListItem>
                            //             ) : (
                            //                 <></>
                            //             )}
                            //         </>
                            //     ) : (
                            //     )}
                            // </>
                        )}
                    </Fragment>
                ))}
            </Popover>
        </>
    );
};

export default AvatarComponent;
