import React, { useEffect, useState } from 'react';
import * as S from '../style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConifgZustand from '../../../../../zustand/ConifgZustand';
import { shuffleArray } from '../helpers';
import { GameItemData } from '../../../types';
import GameZustand from '../../../../../zustand/GameZustand';
import { useHistory } from 'react-router-dom';
import BaseZustand from '../../../../../zustand/BaseZustand';

const GameWidget = () => {
    const history = useHistory();
    const { getHomePageGameData } = ConifgZustand((state) => state);
    const { setGameModal } = BaseZustand((state) => state);
    const { selectGame } = GameZustand((state) => state);
    const [mostPlayedGames, setMostPlayedGames] = useState([]);
    const [providerList, setProviderList] = useState([]);

    const getMostPlayedGames = async () => {
        const gameData = await getHomePageGameData();
        setMostPlayedGames(gameData.topGames);
        setProviderList(gameData.providerData);
    };

    const startGame = (gameItem: GameItemData) => {
        setGameModal(true);
        history.push('/game');
        const findProvider = providerList.find(
            (item) => item._id === gameItem.provider
        );
        selectGame(gameItem, findProvider);
    };

    useEffect(() => {
        getMostPlayedGames();
    }, []);

    return (
        <S.MostPlayedGamesPopup>
            <S.MostPlayedGamesPopupHeader
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            >
                <S.MostPlayedGamesTitle>
                    Most played games
                </S.MostPlayedGamesTitle>
            </S.MostPlayedGamesPopupHeader>
            <S.MostPlayedGamesPopupContent>
                {shuffleArray(mostPlayedGames).map((item) => (
                    <S.MostPlayedGamesItem
                        key={item.gameId}
                        onClick={() => startGame(item)}
                    >
                        <S.MostPlayedGamesImageContainer>
                            <img src={item.imageUrl} alt="" />
                        </S.MostPlayedGamesImageContainer>
                        <S.MostPlayedGamesGameTextWrapper>
                            <S.MostPlayedGamesGameName>
                                {item.gameName}
                            </S.MostPlayedGamesGameName>
                            <S.MostPlayedGamesProviderName>
                                {
                                    providerList.find(
                                        (it) => it._id === item.provider
                                    )?.providerName
                                }
                            </S.MostPlayedGamesProviderName>
                        </S.MostPlayedGamesGameTextWrapper>
                    </S.MostPlayedGamesItem>
                ))}
            </S.MostPlayedGamesPopupContent>
        </S.MostPlayedGamesPopup>
    );
};

export default GameWidget;
