import styled from 'styled-components';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TableContainer from '@mui/material/TableContainer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
// import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import AddLinkOutlined from '@mui/icons-material/AddLinkOutlined';
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';

import TabPanel from '../../base/tab';
import { LetterInput } from '../../auth/auth/login/style';

export const ProfileContent = styled(Container)`
    padding: 1rem;
    & table,
    thead {
        border-bottom: 2px solid ${({ theme }) => theme.text} !important;
    }

    th,
    td {
        padding: 1rem 0.5rem;
        color: ${({ theme }) => theme.text};
    }

    svg {
        color: ${({ theme }) => theme.text};
    }

    @media (max-width: 1024px) {
        padding: 1rem 0;
        width: calc(100% - 2rem);
    }
`;
export const ProfileFooter = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    padding: '1rem 0 0 0.5rem',
    '@media (max-width: 1024px)': {
        paddingBottom: '2rem'
    }
});
export const IntroContentTop = styled(Box)({
    marginTop: '1rem',
    boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
    padding: '2rem',
    background: '#272a37',
    '@media (max-width: 1024px)': {
        padding: '1rem',
        width: 'calc(100% - 2rem)'
    },
    '& p, & div, & li': {
        fontFamily: 'PT Sans !important'
    }
});
export const IntroContent = styled(Box)`
    background: ${({ theme }) => theme.background.secondary};
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    padding: 2rem;
    color: ${({ theme }) => theme.text};
    @media (max-width: 1024px) {
        background: unset;
        box-shadow: unset;
        padding: 0;
    }
    label,
    div {
        font-family: PT Sans !important;
        color: ${({ theme }) => theme.text};
    }
`;
export const AuthContent = styled(Box)`
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    padding: 1rem;
    margin-top: 0.5rem;
    padding-top: 15px;
    padding-bottom: 15px;
    background: ${({ theme }) => theme.background.secondary};
    width: calc(100% - 2rem);
`;
export const ProfileHeader = styled(Box)`
    .MuiTabs-scroller {
        border-bottom: 1px solid #949494 !important;
    }
    .MuiTabs-indicator {
        background-color: ${({ theme }) => theme.primaryButton} !important;
        height: 3px;
    }
    button {
        color: ${({ theme }) => theme.text} !important;
        font-family: League Spartan, Cyrillic !important;
    }
`;
export const ExtendedLetterInput = styled(LetterInput)`
    color: ${({ theme }) => theme.borderColor} !important;
    & .MuiInputAdornment-root {
        p {
            color: ${({ theme }) => theme.text} !important;
        }
    }
`;
export const LetterSelect = styled(Select)({
    '& svg': {
        color: 'rgb(216,216,216)'
    },
    '.Mui-disabled': {
        '-webkit-text-fill-color': 'rgb(216,216,216) !important'
    },
    '& fieldset': {
        borderColor: 'rgb(216,216,216) !important'
    }
});
export const FormControlBirthDay = styled(FormControl)({
    width: '100%',
    marginTop: '0.5rem !important',
    '& svg, label, input': {
        color: 'rgb(216,216,216) !important',
        '-webkit-text-fill-color': 'rgb(216,216,216) !important'
    },
    '& fieldset': {
        borderColor: 'rgb(216,216,216) !important'
    }
});
export const AccessButton = styled(Button)`
    min-width: 100px;
    font-family: League Spartan, Cyrillic !important;
    margin: 1rem 0 0 0.5rem !important;
    font-size: 16px;
    cursor: pointer;
    background-color: #fa0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const ProfileSaveButton = styled(Button)`
    min-width: 100px;
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px;
    cursor: pointer;
    background-color: #fa0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;
export const ProfileNextButton = styled(Button)`
    min-width: 100px;
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #fa0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;
export const ProfileSaveNoButton = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    margin-top: 1rem !important;
    margin-left: 1rem !important;
    font-size: 16px;
    cursor: pointer;
    background-color: transparent !important;
    border: 2px solid #fa0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const KycFile = styled(Grid)`
    width: 100%;
    user-select: none;
    display: flex;
    a {
        cursor: pointer;
        text-decoration-color: rgb(216, 216, 216);
        display: flex;
        align-items: center;
        width: calc(100% - 1.5rem);
    }
    img {
        width: 30px;
    }
    svg {
        cursor: pointer;
    }
`;
export const KycName = styled(Typography)`
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
    width: 100% !important;
`;
export const Subscriptions = styled.div`
    display: flex;
`;
export const SubscriptionsLetter = styled(Typography)`
    font-family: PT Sans !important;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.65;
    margin-bottom: 23px;
    margin: 0;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const SubscripContent = styled(Box)`
    display: flex !important;
    flex-direction: column !important;
    cursor: pointer;
`;
export const SubscripEmail = styled(Typography)`
    font-family: PT Sans !important;
    user-select: none;
    margin: 0;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const CheckboxWrapp = styled(Checkbox)`
    user-select: none;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    svg {
        color: #fa0;
    }
`;
export const TabPanelWrapp = styled(TabPanel)`
    padding: 24px 0;
`;
export const VerifyContent = styled(Box)`
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    padding: 1rem;
    background: #272a37;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const VerifyIcon = styled.i`
    font-size: 36px;
    color: rgb(216, 216, 216);
    margin: 8px;
`;
export const VerifyName = styled.p`
    font-size: 20px;
    font-weight: 700;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
    margin: 0;
`;
export const VerifyType = styled.span`
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    color: #fa0;
    margin: 0;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const VerifyExplain = styled.p`
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
    margin: 0;
    span {
        color: #fa0;
        cursor: pointer;
    }
`;
export const ProfileModal = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const ModalHeader = styled.p`
    font-size: 26px;
    user-select: none;
    text-align: center;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const ModalLetter = styled.p`
    font-size: 14px;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const ProfileModalLetter = styled.p`
    font-size: 1.1rem;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
    text-align: center;
    padding: 0;
    margin: 0;
`;
export const FileDropZone = styled.div`
    height: 150px;
    border: 1px dotted rgb(216, 216, 216);
    margin: 0.5rem 0;
    p {
        font-family: PT Sans !important;
        color: rgb(216, 216, 216);
        user-select: none;
    }
`;
export const VerifyStatus = styled.div`
    height: 150px;
    border: 1px dotted rgb(216, 216, 216);
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        font-family: PT Sans !important;
        color: #fa0;
        user-select: none;
    }
`;
export const IntroTitle = styled(Typography)`
    font-family: PT Sans !important;
    text-align: center;
    color: rgb(216, 216, 216);
    margin: 1rem;
    user-select: none;
    font-family: PT Sans !important;
    font-size: 1.2rem;
    margin-bottom: 0;
`;
export const BetType = styled(Box).attrs((props: any) => ({
    type: props.type
}))`
    background: ${(props: any) => (props.type === 'WIN' ? '#0fbb50' : 'red')};
    text-align: center;
    border-radius: 10px;
    color: white;
    width: 50px;
`;
export const BadgeShow = styled(Box).attrs((props: any) => ({
    type: props.type
}))`
    background: ${(props: any) =>
        props.type === '2' ? '#0fbb50' : props.type === '1' ? '#fa0' : 'red'};
    text-align: center;
    border-radius: 10px;
    color: white;
    width: auto;
`;
export const ProfileCard = styled.div`
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    background: #272a37;
    padding: 1rem;
    margin-top: 0.5rem;
`;
export const PromoContent = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const PromoLevelHeader = styled.div`
    display: flex;
    align-items: center;
`;
export const PromoLevel = styled.p`
    font-size: 16px;
    font-weight: 700;
    user-select: none;
    margin: 0;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const PromoInfoIcon = styled(InfoOutlined)`
    margin-left: 0.5rem;
    color: #fa0;
`;
export const PromoLevelLetter = styled.p`
    font-size: 12px;
    text-align: left;
    margin: 0 15px 8px 0;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const PromoLevelProgress = styled.div`
    width: 150px;
    height: 4px;
    border-radius: 2px;
    overflow: hidden;
    background-color: #fa0;
`;
export const PromoAllLevelContent = styled.div`
    display: flex;
    align-items: center;
    border-left: 2px solid #fa0;
`;
export const PromoAllLevel = styled.div`
    margin: 0.5rem;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const PromoAllIcon = styled(AddLinkOutlined)`
    color: #fa0;
`;
export const BonusHeader = styled(Typography)`
    font-size: 20px;
    font-weight: 700;
    font-family: PT Sans !important;
    line-height: 30px;
    margin: 24px 0px 0px;
    user-select: none;
    color: #fa0;
`;
export const BonusHistoryBtn = styled(Typography)`
    font-size: 14px;
    font-family: PT Sans !important;
    font-weight: 400;
    cursor: pointer;
    color: rgb(216, 216, 216);
    &:hover {
        color: #fa0;
    }
`;
export const Promo = styled.p`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    user-select: none;
    margin-bottom: 0;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const PromoQuestion = styled.div`
    font-size: 16px;
    margin: 0 0 8px 0px;
    text-align: center;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
`;
export const PromoInputPart = styled.div`
    position: relative;
`;
export const PromoInput = styled.input`
    background: transparent;
    border-radius: 8px;
    border: 1px solid #fa0;
    width: calc(100% - 60px);
    height: 40px;
    padding: 0 50px 0 10px;
    outline: unset !important;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
    font-size: 1rem;
`;
export const PromoInputIcon = styled(ArrowForwardOutlined)`
    position: absolute;
    top: 0;
    right: 0;
    height: 34px !important;
    background: #fa0;
    margin: 4px 4px;
    padding: 0 6px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
`;
export const ProfileBack = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
export const ProfileBackIcon = styled(ArrowBackOutlined)`
    color: rgb(216, 216, 216);
`;
export const ProfileBackLetter = styled.p`
    margin: 0;
    user-select: none;
    margin-left: 0.2rem;
    color: rgb(216, 216, 216);
`;
export const BonusHistoryLetter = styled.p`
    color: rgb(216, 216, 216);
`;
export const TableContainerWrap = styled(TableContainer)({
    paddingTop: '1rem',
    th: {
        textAlign: 'center'
    },
    td: {
        textAlign: 'center'
    }
});
export const IntroContentTop1 = styled.div`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    padding: 0 2rem 2rem 2rem;
    background: ${({ theme }) => theme.background.secondary};
    margin-bottom: 1rem;

    @media (max-width: 1024px) {
        padding: 1rem;
        width: calc(100% - 2rem);
    }

    & .MuiPaper-root {
        background: ${(props) => props.theme.background.primary};
    }
    & .MuiAutocomplete-popper .MuiAutocomplete-listbox {
        color: ${(props) => props.theme.text} !important;
        //padding: 0;
        background: ${(props) => props.theme.background.primary} !important;
    }

    & .MuiAutocomplete-option {
        color: ${(props) => props.theme.text};
        background: ${(props) => props.theme.background.primary} !important;
    }

    p,
    div,
    li,
    label {
        font-family: 'PT Sans' !important;
    }
`;

export const DatePickerContainer = styled(Grid)`
  @media (max-width: 899px) {
    margin-bottom: 8px;
  }
`;
export const MenuItemWrapp = styled(MenuItem)`
    font-family: PT Sans !important;
`;
export const NotifyHeader = styled(Typography)`
    font-size: 20px;
    font-weight: 700;
    font-family: PT Sans !important;
    line-height: 30px;
    user-select: none;
    color: #fa0;
`;
export const NotifyContent = styled.div`
    display: flex;
    justify-content: flex-start;
`;
export const VerifyTitle = styled.div`
    font-size: 16px;
    margin: 0 0 8px 0px;
    user-select: none;
    font-family: PT Sans !important;
    color: rgb(216, 216, 216);
    display: flex;
    align-items: center;
`;
export const VerifyBadge = styled.p`
    margin: 0;
    border-radius: 20px;
    padding: 0 0.4rem;
    background: #0fbb50;
    // background: #fa0;☻
    color: white;
`;
export const Popover1 = styled(Popover)`
    z-index: 9999;
    .MuiPaper-elevation {
        background: #41444f;
    }
`;
export const LinearProgressWarp = styled(LinearProgress).attrs(
    (props: any) => ({
        status: props.status
    })
)`
    margin-top: 0.5rem !important;
    height: 0.5rem;
    border-radius: 10px;
    span {
        background: ${(props: any) => props.status} !important;
    }
`;
export const MoveVerify = styled(Typography)`
    position: absolute;
    z-index: 102;
    right: 0;
    user-select: none;
    color: #fa0;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    @media (max-width: 1024px) {
        text-decoration: underline;
    }
`;
