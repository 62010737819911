import styled from 'styled-components';

export const SportId = styled.div`
    height: calc(100% - ${({ theme }) => theme.headerHeight});
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: ${({ theme }) => theme.headerHeight};
    background: #202330;
    position: relative;
    z-index: 102 !important;
    @media only screen and (max-width: 1200px) {
        height: calc(100% - ${({ theme }) => theme.headerHeight});
        margin-top: ${({ theme }) => theme.headerHeight};
        background: #272a37;
    }
`;
