import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BaseZustand from '../../../zustand/BaseZustand';
import useAuthZustand from '../../../zustand/AuthZustand';

import Header from '../Header';
import Footer from '../Footer/MainFooter';
import { Root } from '../../../config/config';

import Intl from '../Header/Intl';

import {
    LeftSidebar,
    GameItemContent,
    GameGridItem,
    GameItem,
    GameName,
    IntlContent
} from './style';
import { Fragment } from 'react';
import ThemeSwitch from '../../../theme/ThemeSwitch';

const MobileSidebar = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { setSidebar, pushUrl } = BaseZustand((state) => state);
    const { mainMenuData } = useAuthZustand((state) => state);

    const changePageOnSidebar = async (url) => {
        const flag = await pushUrl(history, url);
        if (flag) {
            setSidebar(false);
            const iframe: any = document.getElementById('sport-iframe');
            document.getElementById('top-id').scrollTop = 0;
            if (iframe) {
                if (url === '/sports#/live') {
                    iframe.contentWindow.postMessage(
                        { from: 'website', openRoute: 'live' },
                        '*'
                    );
                } else if (url === '/sports') {
                    iframe.contentWindow.postMessage(
                        { from: 'website', openRoute: 'home' },
                        '*'
                    );
                }
            }
        }
    };

    return (
        <LeftSidebar>
            <Header />
            <GameItemContent container>
                {mainMenuData
                    .find((item) => item.name === 'For You')
                    ?.data.map((gameType, key) => (
                        <Fragment key={key}>
                            {gameType.mobileSidebar ? (
                                <GameGridItem sm={3} xs={4} item key={key}>
                                    <GameItem
                                        onClick={() =>
                                            changePageOnSidebar(gameType.url)
                                        }
                                    >
                                        <img
                                            src={`${Root.adminWebsiteUrl}${gameType.image}`}
                                            alt=""
                                        />
                                        <GameName>{t(gameType.name)}</GameName>
                                    </GameItem>
                                </GameGridItem>
                            ) : (
                                <></>
                            )}
                        </Fragment>
                    ))}
            </GameItemContent>
            <IntlContent>
                <Intl />
                <ThemeSwitch />
            </IntlContent>
            <Footer />
        </LeftSidebar>
    );
};

export default MobileSidebar;
