// style.ts
import styled from 'styled-components';

interface IProps {
  aspectRatio?: number;
  maxWidth?: number;
}

export const ImageContainer = styled.div<IProps>`
  position: relative;
  width: 100%;
  overflow: hidden;
  
  ${({ maxWidth }) => maxWidth ? `max-width: ${maxWidth}px;` : ''}

  ${({ aspectRatio }) => aspectRatio ? `
  &::before {
    content: '';
    display: block;
    padding-top: ${(1 / aspectRatio) * 100}%
  }` : ''}

  .placeholder,
  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .placeholder {
    ${({ aspectRatio }) => !aspectRatio ? 'position: relative;' : ''}
    animation: fadeIn 1s infinite alternate;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.1;
    }
  }
};
`
