import { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import AuthZustand from '../../../../../zustand/AuthZustand';
import WalletZustand from '../../../../../zustand/WalletZustand';
import * as S from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../../theme';
import InnerLoading from '../../../../base/innerLoadingComponent';

const PraxisModal = () => {
    const theme = useTheme() as Theme;
    const { isAuth } = AuthZustand((state) => state);
    const { getPraxisUrl, setPraxisModalOpen, praxisModalOpen } = WalletZustand((state) => state);

    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const init = async () => {
        setLoading(true);
        if (isAuth) {
            const data = await getPraxisUrl();
            if (data) {
                setUrl(data.url);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (praxisModalOpen) {
            init();
        }
        // eslint-disable-next-line
    }, [praxisModalOpen]);

    const closeModal = () => {
        setUrl('');
        setPraxisModalOpen(false);
    };

    return (
        <Modal
            center={true}
            open={praxisModalOpen}
            onClose={() => closeModal()}
            modalId="praxisModal"
            styles={{
                modal: { background: theme.background.secondary },
                closeIcon: { fill: theme.text }
            }}
        >
            <S.RegisterContent loading={loading}>
                {
                    loading
                        ? (<InnerLoading status={loading} />)
                        : (<iframe src={url} title="praxis" allowFullScreen />)
                }
            </S.RegisterContent>
        </Modal>
    );
};

export default PraxisModal;
