import { Dispatch, ReactText, SetStateAction, useContext, useEffect, useState } from 'react';
import { getPerPage } from '../pages/game/helpers';
import { ITournament } from '../pages/Tournament/types';
import { IGetGamesResponse } from '../types';
import { GameItemData } from '../pages/game/types';
import { LoadingContext } from '../layout/context/loading';

interface IParams {
    tournament: ITournament;
    getTournamentGames: (gameOptions, page, perPage) => Promise<IGetGamesResponse | ReactText>;
}

interface IResult {
    currentTournamentGames: GameItemData[];
    setCurrentPage: Dispatch<SetStateAction<number>>;
    hasMoreGames: boolean;
}

const useTournamentGamesInfiniteScroll = ({ tournament, getTournamentGames }: IParams): IResult => {
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = getPerPage();
    const [currentTournamentGames, setCurrentTournamentGames] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const hasMoreGames = currentTournamentGames.length < totalCount;

    useEffect(() => {
        const refreshTournamentGames = async () => {
            if (tournament) {
                showLoading();
                const result = await getTournamentGames(tournament?.Games, currentPage, perPage);
                if (typeof result === 'object' && result.data && result.count) {
                    setCurrentTournamentGames(
                        currentPage === 0
                            ? result.data
                            : (prevGames) => [...prevGames, ...result.data]
                    );
                    setTotalCount(result.count);
                }
                hideLoading();
            }
        }
        refreshTournamentGames();
    }, [tournament, currentPage, perPage]);

    return {
        currentTournamentGames,
        setCurrentPage,
        hasMoreGames
    }
}

export default useTournamentGamesInfiniteScroll;
