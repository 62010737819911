import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class AuthApi extends APIAbstract {
    // Authentication part
    sessionCheck() {
        return this.get('/api/auth/sessionCheck');
    }
    userLogin(data) {
        return this.post('/api/auth/user_login', data);
    }
    userLoginWitWallet(data) {
        return this.post('/api/auth/userLoginWitWallet', data);
    }
    userRegister(data) {
        return this.post('/api/auth/user_register', data);
    }
    userRegisterByWallet(data) {
        return this.post('/api/auth/userRegisterByWallet', data);
    }
    userShortRegister(data) {
        return this.post('/api/auth/user_short_register', data);
    }
    userLogout() {
        return this.delete('/api/auth/userLogout');
    }
}

const AuthClass = new AuthApi(`${Root.serverUrl}`);

export default AuthClass;
