import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import {
    LoginBtn,
    SwiperPromoButtonNext,
    SwiperPromoButtonPrev
} from '../style';

export const PromotionsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
    cursor: pointer;
    @media (max-width: 800px) {
        width: unset;
        padding: 0;
    }
`;

export const PromotionsHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
`;

export const PromotionTitleSliderBtnWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PromotionSliderPrevBtn = styled(SwiperPromoButtonPrev)`
    color: rgb(216, 216, 216);
`;

export const PromotionSliderNextBtn = styled(SwiperPromoButtonNext)`
    color: rgb(216, 216, 216);
`;

export const PromotionsTitle = styled.p`
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.text};
    font-weight: 700;
    font-size: 17px;
    font-family: 'League Spartan', 'Cyrillic', sans-serif;
`;

export const PromotionsHeaderBtn = styled(LoginBtn)`
    padding: 6px 16px !important;
    color: ${(props) => props.theme.text};
    @media (max-width: 500px) {
        display: none;
    }
`;

export const PromotionSliderWrapper = styled(Swiper)`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
`;

export const PromotionItemWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(74, 118, 168, 0.09);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 3px;
    @media (max-width: 800px) {
        box-shadow: unset;
    }
`;

export const PromotionItemImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const PromotionItemTextWrapper = styled.div`
    //width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 0;
`;

export const PromotionItemTitle = styled.h2`
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
    color: rgb(216, 216, 216);
`;

export const PromotionItemContent = styled.p`
    font-family: 'PT Sans', sans-serif;
    color: rgb(216, 216, 216);
    @media (max-width: 599px) {
        font-size: 15px !important;
    }
`;

export const PromotionItemFooter = styled.div`
    //width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 20px;
    //background: red;
`;

export const PromotionItemFooterBtn = styled(LoginBtn)`
    margin-right: 20px;
`;
