import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import GameZustand from '../../../../../zustand/GameZustand';
import usePlayGame from '../../../../../hooks/usePlayGame';
import { GameItemData } from '../../../types';
import {
    OptionText,
    StyledOption,
    Image,
    TextOptionText,
    SearchAutocompleteWrapper
} from '../style';
import * as S from '../../../style';
// import { providerconfig } from '../../../../../config/mainConfig';
import AuthZustand from '../../../../../zustand/AuthZustand';
import BaseZustand from '../../../../../zustand/BaseZustand';
import { useTranslation } from 'react-i18next';
import { filterByProviderId } from './helpers';
import styled, { css } from 'styled-components';
import { ExtendedLetterInput } from '../../../../userDashboard/history/style';
import { getGameImageSrc } from '../../../../../helpers';

const GameInput = styled(ExtendedLetterInput)`
  width: 100%;
  margin-top: 1rem !important;
  ${({ theme }) =>
          theme.mode === 'white'
                  ? css`
                    color: ${(props) => props.theme.text} !important;
                    & input:-webkit-autofill,
                    & input:-webkit-autofill:hover,
                    & input:-webkit-autofill:focus,
                    & input:-webkit-autofill:active {
                      transition: background-color 5000s ease-in-out 0s;
                      -webkit-text-fill-color: inherit !important;
                    }

                    & .MuiInputBase-input:-webkit-autofill {
                      color: #fff !important;
                      font-family: 'PT Sans' !important;
                      & :-webkit-autofill:active {
                        -webkit-box-shadow: unset;
                        color: #fff !important;
                      }
                      & :-webkit-autofill {
                        background-color: transparent;
                      }
                      -webkit-text-fill-color: #fff !important;
                    }

                    & input {
                      color: #fff;

                      &:focus {
                        color: #fff;
                      }
                    }

                    & input:-internal-autofill-selected {
                      background-color: #fff !important;
                    }

                    & input:-webkit-autofill {
                      background: transparent;
                    }

                    & label {
                      color: #fff !important;
                      font-family: 'PT Sans' !important;
                    }

                    & svg {
                      color: #fff !important;
                    }

                    & label.Mui-focused {
                      color: #fff !important;
                    }

                    & .MuiOutlinedInput-root {
                      & fieldset {
                        border-color: #fff;
                      }

                      &:hover fieldset {
                        border-color: #fff !important;
                      }

                      &.Mui-focused fieldset {
                        border-color: #fff !important;
                      }
                    }
                  `
                  : null}
`;
const SearchAutocomplete = ({ elementId, setSelectedGame }) => {
    const { t } = useTranslation();
    const { isAuth, addtionalProviderConfig } = AuthZustand((state) => state);
    const { setAuthModal } = BaseZustand((state) => state);
    const [searchValue, setSearchValue] = useState('');
    const { getGames, setCurrentProviders, game, currentProviders, removeProviderById } = GameZustand(
        (state) => state
    );
    const {
        selectCurrentGame,
        url,
        openDemo,
        OpenGame,
        currentGame,
        setShowButtons,
        showButtons,
        setCurrentGame
    } = usePlayGame();
    const [open, setOpen] = useState(false);
    const [gamesList, setGamesList] = useState([]);

    const handleChooseGame = async (gameItem: GameItemData, elementId) => {
        selectCurrentGame(gameItem, elementId);
        setSearchValue(gameItem?.gameName);
        setSelectedGame(gameItem);
        if (gameItem?.provider.length) setCurrentProviders(gameItem?.provider);
    };

    const renderOption = (option) => {
        if (option.gameId === 'custom') {
            return (
                <StyledOption key={option.gameId}>
                    <TextOptionText>{option.gameName}</TextOptionText>
                </StyledOption>
            );
        }

        const imageUrl = getGameImageSrc(option);

        return (
            <StyledOption
                key={option.gameId}
                onClick={() => handleChooseGame(option, elementId)}
            >
                <Image
                    src={imageUrl}
                />
                <OptionText>{option.gameName}</OptionText>
            </StyledOption>
        );
    };

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const { count, data } = await getGames({
                    gameType: '',
                    page: 0,
                    perPage: 100,
                    provider: '0',
                    gameName: searchValue
                });

                const gamesList = filterByProviderId(data, currentProviders);

                const extraOptions = [];
                if (count - data.length > 0) {
                    extraOptions.push({
                        gameId: 'custom',
                        gameName: `... and ${count - data.length} more`
                    });
                }

                setGamesList([...gamesList, ...extraOptions]);
            } catch (error) {
                toast.error(error.message);
            }
        };

        fetchGames();
    }, [searchValue, currentProviders]);

    useEffect(() => {
        if (currentGame !== null) {
            setShowButtons(true);
        }
    }, [currentGame]);

    const handleInputChange = (event, value) => {
        setSearchValue(value);
        if (value === '') {
            setSelectedGame(null);
            setCurrentGame(null)
        }
    };

    const handleClearInput = () => {
        removeProviderById(currentGame.provider)
        setSearchValue('');
        setSelectedGame(null);
        setCurrentGame(null);
    };

    return (
        <SearchAutocompleteWrapper>
            {!url.length ? (
                <Autocomplete
                    sx={{
                        width: 1,
                        maxWidth: '300px',
                        margin: '0 auto'
                    }}
                    onChange={(event, value) => {
                        handleInputChange(event, value);
                        if (value === null) {
                            handleClearInput();
                        }
                    }}
                    defaultValue={game.gameName}
                    value={searchValue}
                    onInputChange={handleInputChange}
                    options={gamesList}
                    open={open}
                    freeSolo={true}
                    disablePortal={true}
                    getOptionLabel={(option) => option?.gameName || searchValue}
                    renderOption={(props, option) => renderOption(option)}
                    onClose={() => setOpen(false)}
                    renderInput={(params) => (
                        <GameInput
                            {...params}
                            label="Search"
                            variant="outlined"
                            onClick={() => setOpen(true)}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: <SearchIcon />
                            }}
                        />
                    )}
                />
            ) : (
                <iframe
                    src={url}
                    title="game"
                    style={{ width: '100%', height: '100%' }}
                    allowFullScreen
                />
            )}
            {currentGame ? (
                <S.ButtonPart>
                    {!isAuth ? (
                        <S.RegisterBtn
                            variant="contained"
                            onClick={() =>
                                setAuthModal({ login: true, register: false }, history)
                            }
                        >
                            {t('LOGIN')}
                        </S.RegisterBtn>
                    ) : null}
                    {currentGame &&
                    currentGame.detail.HasDemo &&
                    showButtons ? (
                        <S.LoginBtn
                            variant="contained"
                            onClick={() => openDemo(currentGame)}
                        >
                            {t('Fun Play')}
                        </S.LoginBtn>
                    ) : null}
                    {isAuth &&
                    currentGame &&
                    currentGame.gameType !== addtionalProviderConfig.Poker &&
                    showButtons ? (
                        <S.RegisterBtn
                            variant="contained"
                            onClick={() => OpenGame(currentGame)}
                        >
                            {t('Play Game')}
                        </S.RegisterBtn>
                    ) : null}
                </S.ButtonPart>
            ) : (
                <></>
            )}
        </SearchAutocompleteWrapper>
    );
};

export default SearchAutocomplete;
