import styled from 'styled-components';
import { TournamentItemTitle } from '../style';

export const WinningInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > ${TournamentItemTitle} {
        font-size: 30px;
        font-family: 'PT Sans', sans-serif;
    }
    & > span {
        font-family: 'PT Sans', sans-serif;
        margin: 0 0 10px;
    }
`;
