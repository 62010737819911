import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const Footer = styled(Box).attrs((props: any) => ({
    show: props.show
}))`
    width: ${(props) => (props.show ? 'calc(100% - 2rem)' : 'unset')};
    background: ${(props) => props.theme.background.primary};
    height: ${(props) => (props.show === 'true' ? 'auto' : '0')};
    padding: ${(props) => (props.show === 'true' ? '1rem' : '0')};
    @media only screen and (max-width: 500px) {
        padding-top: 0;
    }
`;

export const FooterContent = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem 0 !important;
    position: relative;
    .swiper-wrapper {
        display: flex;
        align-items: center;
    }
    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Logo = styled.img`
    max-width: 150px;
    cursor: pointer;
`;

export const FooterLink = styled(Grid).attrs((props: any) => ({
    num: props.num
}))`
    width: 100% !important;
    max-width: calc(100% / 2);
    display: flex;
    justify-content: center;
    margin: 0 0 1rem 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: League Spartan, Cyrillic !important;
    color: ${(props) => props.theme.text};
    text-align: center;
    user-select: none;
    transition: all 0.2s;
    padding-left: 0 !important;
    @media (max-width: 500px) {
        max-width: unset;
    }
    &:hover {
        color: ${({ theme }) =>
            theme.mode === 'black' ? '#ffffff' : theme.primaryButton};
        font-size: 14px;
    }
`;

export const FooterText = styled(Grid)`
    color: ${(props) => props.theme.text};
    text-align: center;
    user-select: none;
    font-family: PT Sans !important;
    transition: all 0.2s ease-in 0s !important;
    @media only screen and (max-width: 500px) {
        font-size: 11px;
        animation: battito 1s ease-in-out infinite both;
    }
`;

export const FooterProvider1 = styled(Box)`
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    @media only screen and (max-width: 500px) {
        min-width: 60px;
        max-width: 60px;
    }
    img {
        min-width: 50px;
        max-width: 50px;
        min-height: 53px;
        max-height: 53px;
    }
`;

export const FooterProvider = styled(Box)`
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    img {
        min-width: 100px;
        max-width: 100px;
        @media only screen and (max-width: 500px) {
            min-width: 60px;
            max-width: 60px;
        }
    }
`;

export const FooterRule = styled(Box)`
    min-width: 100px;
    max-width: 100px;
    min-height: 50px;
    max-height: 50px;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    margin: 0 0.5rem;

    @media only screen and (max-width: 500px) {
        min-width: 60px;
        max-width: 60px;
    }
    img {
        width: 100%;
        max-height: 50px;
    }
`;

export const FooterRule1 = styled(Box)`
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    margin: 0 0.5rem;

    @media only screen and (max-width: 500px) {
        min-width: 60px;
        max-width: 60px;
    }
    img {
        width: 100%;
        max-height: 50px;
    }
`;

export const FooterVerify = styled(Box)`
    min-width: 150px;
    max-width: 150px;
    min-height: 50px;
    max-height: 50px;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    @media only screen and (max-width: 500px) {
        min-width: 60px;
        max-width: 60px;
    }
    img {
        width: 100%;
        max-height: 50px;
    }

    img {
        width: 100%;
        height: 100%;
        animation: battito 1s ease-in-out infinite both;
    }
    @media only screen and (max-width: 500px) {
        max-width: 100px;
        max-height: 33px;
    }
`;
