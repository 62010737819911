import { providerconfig } from '../../config/mainConfig';
import { IProviderList } from './types';

export const getPerPage = (): number => {
    if (window.innerWidth >= 900) {
        return 30;
    } else if (window.innerWidth >= 600) {
        return 24;
    } else {
        return 24;
    }
};

export const handleCheckProviderType = (
    providerType,
    response
): IProviderList[] => {
    let provider: IProviderList[] = [];

    if (providerType === providerconfig['Slot']) {
        provider = [
            ...[{ _id: '0', providerName: 'All' }],
            // ...[{ _id: '1', providerName: 'New Games' }],
            // ...[{ _id: '2', providerName: 'Top Games' }],
            ...response
        ];
    } else {
        provider = [...[{ _id: '0', providerName: 'All' }], ...response];
    }
    return provider;
};

export const getOneProvider = (
    provider: IProviderList[],
    setCurrentProvider,
    providerType
): void => {
    const oneProvider = provider.find(
        (item: IProviderList): boolean =>
            item.providerName === providerType.provider
    );
    if (oneProvider) {
        setCurrentProvider(oneProvider);
    }
};
