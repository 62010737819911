import React, { FC, useEffect, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeContext } from '../../context/ThemeContext';
import { ThemeProviderProps } from '../../types';
import { themeList, Theme, IThemeList } from '../index';

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
    const initialTheme = localStorage.getItem('theme') || 'dark';
    const [theme, setTheme] = useState<string>(initialTheme);
    const [customTheme, setCustomTheme] = useState<Theme>(
        () =>
            themeList.find((item) => item.theme.mode === initialTheme)?.theme ||
            themeList[0].theme
    );
    const [headerHeight, setHeaderHeight] = useState<string>('64px');

    useEffect(() => {
        setCustomTheme((prevValue) => ({ ...prevValue, headerHeight }));
    }, [theme, headerHeight]);

    useEffect(() => {
        const meta = document.querySelector('meta[name="theme-color"]') as any;
        meta.content = customTheme.background.secondary;
    }, [customTheme.mode]);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        setCustomTheme(
            themeList.find((item: IThemeList) => item.theme.mode === newTheme)
                ?.theme || themeList[0].theme
        );
        localStorage.setItem('theme', newTheme);
    };

    const setCustomMainTheme = (newTheme?: Theme) => {
        if (newTheme) {
            setTheme(newTheme.mode);
            setCustomTheme(newTheme);
            localStorage.setItem('theme', newTheme.mode);
        }
    };

    return (
        <ThemeContext.Provider
            value={{
                theme,
                toggleTheme,
                setCustomMainTheme,
                setHeaderHeight,
                currentTheme: customTheme,
                customTheme
            }}
        >
            <StyledThemeProvider theme={customTheme}>
                {children}
            </StyledThemeProvider>
        </ThemeContext.Provider>
    );
};
