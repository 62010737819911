import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

export const Popover1 = styled(Popover)`
    .MuiPaper-elevation {
        background: #41444f;
    }
`;
export const LanguageHeader = styled(Grid)`
    padding: 0 16px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 550px) {
        padding: 0 4px;
    }
`;
export const LanguageTitle = styled(Box)`
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    img {
        width: 1.2em !important;
        height: 1.2em !important;
    }
`;
export const LanguageString = styled(Typography)`
    padding: 0 0 0 4px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    font-style: normal;
    text-align: center;
    user-select: none;
    color: ${(props) => props.theme.text};
`;
export const LanguageItem = styled(Typography)`
    padding: 10px !important;
    cursor: pointer;
    user-select: none;
    color: ${(props) => props.theme.text};
    span {
        margin-left: 5px !important;
        font-weight: bold !important;
        font-size: 0.8rem !important;
    }
    &:hover {
        background: #c8c8c852;
    }
`;
