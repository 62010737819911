import React, { useCallback, useMemo } from 'react';
import { ITournament } from '../types';
import {
    TournamentBonusCardBonusName,
    TournamentBonusCardBonusNameWrapper,
    TournamentBonusCardButtonWrapper,
    TournamentBonusCardWrapper,
    TournamentBonusCartTextWrapper
} from './style';
import {
    TournamentImageContainer,
    TournamentItemBtn,
    TournamentItemTitle,
    TournamentNameWrapper
} from '../style';
import { useTranslation } from 'react-i18next';
import { useTournamentStore } from '../../../zustand/TournamentZustand';
import {
    TournamentBonusExpire,
    TournamentBonusTitle
} from '../TournamentBonus/TournamentBonus';
import BonusWageringProgressBar from './BonusWageringProgressBar';
import { useHistory, useParams } from 'react-router-dom';
import { IParams } from '../CurrentTournament/types';
import AuthZustand from '../../../zustand/AuthZustand';

export interface ITournamentBonusCard {
    tournament: ITournament;
    bonus: any;
}
const TournamentBonusCard = ({ tournament, bonus }: ITournamentBonusCard) => {
    const { i18n, t } = useTranslation();
    const params: IParams = useParams();
    const history = useHistory();
    const { redeemTournamentBonus, cancelTournamentBonus } = useTournamentStore(
        (state) => state
    );
    const { isAuth, user } = AuthZustand((state) => state);

    if (!isAuth) {
        return null;
    }

    const handleShowMoreTournamentBonus = useCallback(() => {
        history.push(`/tournament-bonus/${bonus.ID}`);
    }, [bonus.ID]);

    const bonusBalance = useMemo(() => {
        const playerPlacement = tournament.Leaderboard.find((player) => {
            return parseInt(player.IDUser) === user.softId;
        });

        if (!playerPlacement || !playerPlacement.Win) {
            return null;
        }

        const currentBalance = parseInt(playerPlacement.Win);
        const bonusPercent = parseInt(bonus.Results.balance.Value) / 100;
        return currentBalance * bonusPercent;
    }, [user.softId, tournament.Leaderboard]);

    return (
        <>
            <TournamentBonusCardWrapper>
                <TournamentBonusCartTextWrapper>
                    <TournamentNameWrapper>
                        <TournamentItemTitle>
                            {tournament.Name[i18n.language] ||
                                tournament.Name.en}
                        </TournamentItemTitle>
                    </TournamentNameWrapper>
                    <TournamentBonusCardBonusNameWrapper>
                        <TournamentBonusCardBonusName>
                            <TournamentItemTitle>
                                {t('Tournament bonus')}:&nbsp;
                            </TournamentItemTitle>
                            <TournamentBonusTitle>
                                {bonus.Name.en}
                            </TournamentBonusTitle>
                        </TournamentBonusCardBonusName>
                        <TournamentBonusCardBonusName>
                            <TournamentItemTitle>
                                {t('Bonus reward')}:&nbsp;
                            </TournamentItemTitle>

                            <TournamentBonusTitle>
                                {bonusBalance
                                    ? '€' + bonusBalance
                                    : 'Processing'}
                                {bonus.Results.freerounds
                                    ? '+' +
                                    ' ' +
                                    bonus.Results.freerounds.Value +
                                    ' ' +
                                    'Free spins'
                                    : null}
                            </TournamentBonusTitle>
                        </TournamentBonusCardBonusName>
                        <TournamentBonusExpire>
                            {t('Bonus expires on')} {bonus.Expire}
                        </TournamentBonusExpire>
                        <BonusWageringProgressBar bonus={bonus} />
                    </TournamentBonusCardBonusNameWrapper>
                    <TournamentBonusCardButtonWrapper>
                        {bonus.Active === 0 ? (
                            <TournamentItemBtn
                                variant={'contained'}
                                onClick={() => redeemTournamentBonus(bonus.ID)}
                            >
                                {t('Activate')}
                            </TournamentItemBtn>
                        ) : null}
                        {parseInt(params.id) !== bonus.ID ? (
                            <TournamentItemBtn
                                onClick={handleShowMoreTournamentBonus}
                                variant={'contained'}
                            >
                                {t('Show more')}
                            </TournamentItemBtn>
                        ) : null}
                        {bonus.Active === 1 ? (
                            <TournamentItemBtn
                                variant={'contained'}
                                onClick={() => cancelTournamentBonus(bonus.ID)}
                            >
                                {t('Cancel')}
                            </TournamentItemBtn>
                        ) : null}
                        <TournamentItemBtn
                            variant="contained"
                            onClick={() =>
                                history.push(
                                    '/promotions-general-terms-and-conditions'
                                )
                            }
                        >
                            {t('Terms & conditions')}
                        </TournamentItemBtn>
                    </TournamentBonusCardButtonWrapper>
                </TournamentBonusCartTextWrapper>
                <TournamentImageContainer
                    image={
                        tournament.Image.en ||
                            tournament.Image[i18n.language]
                            ? tournament.Image[i18n.language]
                                ? tournament.Image[i18n.language]
                                : tournament.Image.en
                            : 'https://www.onlineunitedstatescasinos.com/app/uploads/2020/04/Online-Casino-Tournaments.jpg'
                    }
                >
                    {/* <img
                        src={
                            tournament.Image.en ||
                            tournament.Image[i18n.language]
                                ? tournament.Image[i18n.language]
                                    ? tournament.Image[i18n.language]
                                    : tournament.Image.en
                                : 'https://www.onlineunitedstatescasinos.com/app/uploads/2020/04/Online-Casino-Tournaments.jpg'
                        }
                        alt=""
                    /> */}
                </TournamentImageContainer>
            </TournamentBonusCardWrapper>
        </>
    );
};

export default TournamentBonusCard;
