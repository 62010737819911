import React, { useEffect, useState } from 'react';
import MultipleGames from '../pages/game/GameOpen/MultipleGames';
import GameZustand from '../zustand/GameZustand';

interface Screen {
    id: number;
    fullSize: boolean;
}

export const useSplitScreen = () => {
    const [screens, setScreens] = useState<Screen[]>([]);
    const [screenIdCounter, setScreenIdCounter] = useState(0);

    const clearScreens = () => {
        setScreens([]);
        setScreenIdCounter(0);
    };

    const { removeProviderById } = GameZustand((state: any) => state);

    const renderCount = screens.length;
    const hasGameElement = !!document.getElementById('game');
    const maxScreens = hasGameElement ? 3 : 4;
    const fullSize = renderCount === 1 && !hasGameElement;

    const handleAddScreen = () => {
        setScreens((prevScreens) => {
            if (prevScreens.length < maxScreens) {
                const newScreenId = screenIdCounter + 1;
                setScreenIdCounter(newScreenId);
                return [...prevScreens, { id: newScreenId, fullSize: false }];
            }
            return prevScreens;
        });
    };

    const handleCloseScreen = (id: number, providerId: string) => {
        setScreens((prevScreens) => {
            const updatedScreens = prevScreens.filter(
                (screen) => screen.id !== id
            );
            if (providerId) removeProviderById(providerId);
            return updatedScreens;
        });
    };

    const renderComponents = (isFullScreen: boolean) => {
        return screens.map((screen) => (
            <MultipleGames
                isFullScreen={isFullScreen}
                key={screen.id}
                id={screen.id}
                handleCloseScreen={handleCloseScreen}
                fullSize={fullSize}
            />
        ));
    };

    useEffect(() => {
        setScreens((prevScreens) =>
            prevScreens.map((screen) => ({
                ...screen,
                fullSize: prevScreens.length === 1 && !hasGameElement
            }))
        );
    }, [renderCount, hasGameElement]);

    return {
        handleAddScreen,
        renderComponents,
        handleCloseScreen,
        renderCount,
        fullSize,
        clearScreens
    };
};
