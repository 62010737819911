import { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthZustand from '../../../../zustand/AuthZustand';

import AuthZustand from '../../../../zustand/AuthZustand';
import BaseZustand from '../../../../zustand/BaseZustand';
import { Root } from '../../../../config/config';

import { MobileSubItem, MobileSubName, MobileFooter } from './style';
import WalletZustand from '../../../../zustand/WalletZustand';

const MobileFooterPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { isAuth } = AuthZustand((state) => state);
    const { setAuthModal, pushUrl, updateAuthModal } = BaseZustand(
        (state) => state
    );
    const { praxisModalOpen, setPraxisModalOpen } = WalletZustand((state) => state);
    const { mainMenuData } = useAuthZustand((state) => state);

    const [menuData, setMenuData] = useState([]);

    useEffect(() => {
        const data = mainMenuData.find((item) => item.name === 'For You');
        if (data && data.data) {
            const fdata = data.data.filter((item) => item.onMBFooter === true);
            setMenuData(fdata);
        }
    }, [mainMenuData]);

    const checkUrl = useCallback((item) => {
        const url = history.location.pathname;
        if (item.url === url) {
            return true;
        } else if (url === '/' && item.url === '/home') {
            return true;
        } else {
            return false;
        }
        // eslint-disable-next-line
    }, []);

    const changePageOnCookie = useCallback(async (url) => {
        if (isAuth) {
            const flag = await pushUrl(history, url);
            if (flag) {
                updateAuthModal({ login: false, register: false });
            }
        } else {
            history.push(url);
            updateAuthModal({ login: false, register: false });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <MobileFooter>
            {menuData.map((item, key) => (
                <Fragment key={key}>
                    {
                        item.name === 'Login' && !isAuth ? (<>{
                            <MobileSubItem
                                selected={checkUrl(item)}
                                onClick={() =>
                                    setAuthModal(
                                        {
                                            login: true,
                                            register: false
                                        },
                                        history
                                    )
                                }
                            >
                                <img
                                    src={`${Root.adminWebsiteUrl}${item.image}`}
                                />
                                <MobileSubName>
                                    {t(item.name)}
                                </MobileSubName>
                            </MobileSubItem>
                        }</>) : (<></>)
                    }
                    {
                        item.name === 'Deposit' && isAuth ? (<>{
                            <MobileSubItem
                                selected={praxisModalOpen}
                                onClick={() => setPraxisModalOpen(true)}
                            >
                                <img src={`${Root.adminWebsiteUrl}${item.image}`} />
                                <MobileSubName>{t(item.name)}</MobileSubName>
                            </MobileSubItem>
                        }</>) : (<></>)
                    }
                    {
                        item.name !== 'Deposit' && item.name !== 'Login' ? (<>{
                            <MobileSubItem
                                selected={checkUrl(item)}
                                onClick={() => changePageOnCookie(item.url)}
                            >
                                <img src={`${Root.adminWebsiteUrl}${item.image}`} />
                                <MobileSubName>{t(item.name)}</MobileSubName>
                            </MobileSubItem>
                        }</>) : (<></>)
                    }
                </Fragment>
            ))}
        </MobileFooter>
    );
};

export default MobileFooterPage;
