import styled from 'styled-components';
import { TournamentItemBtn, TournamentItemTitle } from '../../style';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export const TournamentCurrentGamesListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TournamentGameListContainer = styled.div`
  width: 100%;
`;

export const TournamentGameList = styled(Grid)`
`;

export const TournamentGameItemOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: transparent;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > * {
        transition: all 0.2s;
        z-index: -1;
    }
`;

export const TournamentGameItem = styled(Box).attrs(
    (props: { showskeleton?: string }) => ({
        showskeleton: props.showskeleton
    })
)`
    display: ${(props: { showskeleton?: string }) =>
        props.showskeleton === 'true' ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    @media (max-width: 1000px) {
        p {
            display: none;
        }
    }
    img {
        width: 100%;
        height: 100%;
        transition: all 0.2s;
    }
    &:hover {
        & img {
            transform: scale(1.2) rotateZ(10deg);
        }
        & ${TournamentGameItemOverlay} {
            background: rgba(0, 0, 0, 0.55) !important;
            z-index: 10 !important;
            transition: all 0.2s;
            & > * {
                z-index: 10 !important;
                transition: all 0.2s;
            }
        }
    }
`;

export const TournamentGameTitle = styled(TournamentItemTitle)`
    margin: 0 0 10px;
`;

export const TournamentGameItemBtn = styled(TournamentItemBtn)`
    margin: 0;
`;
