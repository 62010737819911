import { useContext, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { LoadingContext } from '../../../../layout/context/loading';
import ProfileZustand from '../../../../zustand/ProfileZustand';
import BaseZustand from '../../../../zustand/BaseZustand';

import {
    AuthContent,
    Letter,
    RadioGroupWrapp,
    AccessButton,
    Redirect
} from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';
import { ExtendedLetterInput } from '../../../userDashboard/history/style';

const ForgotPassword = () => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();
    const history = useHistory();
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const { forgot, setForgotModal, setAuthModal } = BaseZustand(
        (state) => state
    );
    const { forgotPasswordRequest } = ProfileZustand((state) => state);

    const [value, setValue] = useState('');
    const [type, setType] = useState('Email');

    const ValidateEmail = (mail) => {
        // eslint-disable-next-line
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    };

    const login = async (e) => {
        e.preventDefault();
        if (!value) {
            toast.error(t(`Please input correct ${type}`));
            return;
        }

        if (type === 'Email' && ValidateEmail(value) === false) {
            toast.error(t(`Please input correct ${type}`));
            return;
        }

        if (
            type === 'SMS' &&
            (`${value}`.length < 9 || `${value}`.length > 14)
        ) {
            toast.error(t(`Please input correct ${type}`));
            return;
        }

        showLoading();
        const flag = await forgotPasswordRequest({
            value: value.split(' ').join('').replaceAll('+', ''),
            type,
            send: type === 'Email' ? 'Email' : 'SMS'
        });
        if (flag) setForgotModal(false);
        hideLoading();
    };

    return (
        <Modal
            center={true}
            open={forgot}
            onClose={() => setForgotModal(false)}
            styles={{
                modal: { background: theme.background.secondary },
                closeIcon: { fill: theme.text }
            }}
        >
            <AuthContent>
                <Letter>{t('Reset your password')}</Letter>
                <form onSubmit={(e) => login(e)}>
                    <RadioGroupWrapp
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={type}
                        onChange={(e) => {
                            setType(e.target.value);
                            setValue('');
                        }}
                    >
                        <FormControlLabel
                            value="Email"
                            control={<Radio />}
                            label={t('Email')}
                        />
                        <FormControlLabel
                            value="Mobile Number"
                            control={<Radio />}
                            label={t('SMS')}
                        />
                    </RadioGroupWrapp>
                    <ExtendedLetterInput
                        label={t(type)}
                        variant="outlined"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        autoComplete="off"
                    />
                    <AccessButton variant="outlined" type="submit">
                        {t('Submit')}
                    </AccessButton>
                    <Redirect
                        onClick={() => {
                            setForgotModal(false);
                            setAuthModal(
                                { register: false, login: true },
                                history
                            );
                        }}
                    >
                        {t('Back to login')}
                    </Redirect>
                </form>
            </AuthContent>
        </Modal>
    );
};

export default ForgotPassword;
