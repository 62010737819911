import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const RouterContext = React.createContext([]);

const RouterProvider = ({children}) => {
    const location = useLocation();

    const [history, setHistory] = useState<{ from: string | null, to: string }[]>([{
        from: null,
        to: location.pathname + location.search,
    }]);

    useEffect(()=> {
        setHistory((history)=> {
            const [prev] = history.slice(-1)
            return [
                ...history,
                { from: prev.to, to: location.pathname + location.search }
            ];
        })
    }, [location]);

    return (
        <RouterContext.Provider value={history}>
            {children}
        </RouterContext.Provider>
    )
}

export { RouterContext, RouterProvider };