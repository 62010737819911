import { useState, useEffect } from 'react'
import { TournamentPageContainer } from '../style';
import TournamentCard from './TournamentCard';
import { useHistory } from 'react-router-dom';
import { RandomTournamentHeaderBtn } from '../../dashboard/RandomTournament/style';
import { CurrentTournamentWrapper } from './style';
import { useTournamentStore } from '../../../zustand/TournamentZustand';
import TournamentGames from './TournamentGame';
import { useTranslation } from 'react-i18next';
import { StatePram } from './types';
// import { staticTournament } from '../heplers'

import useStaticTournamentGamesInfiniteScroll from '../../../hooks/useStaticTournamentGames';


const StaticTournament = () => {
    const history = useHistory();
    const { t } = useTranslation()
    const { getStaticTournamentGames, getCurrentLocalTournament } = useTournamentStore(
        (state) => state
    );

    const [currentTournament, setCurrentTournament] = useState('')

    const state: StatePram = history.location.state
    const tourId = state && state.id ? state.id : 0

    const {
        hasMoreGames,
        setCurrentPage,
        currentTournamentGames
    } = useStaticTournamentGamesInfiniteScroll({
        getStaticTournamentGames
    }, tourId);

    const handleBackToTournamentList = () => {
        history.push('/tournament');
    };

    useEffect(() => {
        const init = async () => {
            const data = await getCurrentLocalTournament({ tourId })
            setCurrentTournament(data)
        }
        if (tourId !== 0) {
            init()
        } else {
            handleBackToTournamentList()
        }
    }, [tourId])

    return (
        <TournamentPageContainer maxWidth="xl">
            <RandomTournamentHeaderBtn
                variant="contained"
                onClick={handleBackToTournamentList}
            >
                {t('Back')}
            </RandomTournamentHeaderBtn>
            <CurrentTournamentWrapper>
                {
                    currentTournament != '' ?
                        <TournamentCard
                            isStatic
                            tournament={currentTournament}
                            fullSize={true}
                            hasSubscribeButton={false}
                            hasShowMoreButton={false}
                        /> : <></>
                }
                <TournamentGames
                    tournamentGames={currentTournamentGames}
                    title={'Tournament games'}
                    setCurrentPage={setCurrentPage}
                    hasMoreGames={hasMoreGames}
                />
            </CurrentTournamentWrapper>
        </TournamentPageContainer>
    );
};

export default StaticTournament;
