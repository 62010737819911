import { Fragment, useState, useContext, useEffect } from 'react';
// import Popover from '@mui/material/Popover';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingContext } from '../../../context/loading';
import { a11yProps } from '../../../../pages/base/a11yProps';

import BaseZustand from '../../../../zustand/BaseZustand';
import AuthZustand from '../../../../zustand/AuthZustand';
import ProfileZustand from '../../../../zustand/ProfileZustand';

import { Root } from '../../../../config/config';

import {
    User,
    Balance,
    BalanceImg,
    DepositBtn,
    ProfileHeader,
    TabPanelWrapp,
    BalancePart,
    BalanceAmount,
    BalanceName,
    SwitchBox,
    SwitchName
} from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';
import { Menu } from '@mui/material';
// import WalletZustand from '../../../../zustand/WalletZustand';

const Header = () => {
    const theme = useTheme() as Theme;
    const history = useHistory();
    const { t } = useTranslation();
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const { user, balanceData, currencies, sessionUpdate } = AuthZustand(
        (state) => state
    );
    // const { RtFixed, setSidebar } = BaseZustand((state) => state);
    const { RtFixed } = BaseZustand((state) => state);
    const { updatePlayerCurrency, updateHidezero } = ProfileZustand(
        (state) => state
    );
    // const { setPraxisModalOpen } = WalletZustand((state) => state);

    const [value, setValue] = useState(0);
    const [balanceDropTag, setBalanceDropTag] = useState(null);
    const balanceOpen = Boolean(balanceDropTag);
    const balanceId = balanceOpen ? 'simple-popover' : undefined;

    useEffect(() => {
        if (
            currencies.find((item) => item.currency === user.currency)?.type ===
            'crypto'
        ) {
            setValue(1);
        } else {
            setValue(0);
        }
        // eslint-disable-next-line
    }, []);

    const changeCurrency = async (item) => {
        showLoading();
        setBalanceDropTag(null);
        if (user.currency !== item) {
            await updatePlayerCurrency({ currency: item });
            await sessionUpdate();
        }
        hideLoading();
    };

    const setHidezero = async () => {
        showLoading();
        await updateHidezero({ hidezero: !user.hidezero });
        await sessionUpdate();
        hideLoading();
    };

    const getCurrencyImg = (currency) => {
        let flag
        if (currency.includes('-')) {
            flag = currencies.find((item) => `${item.currency}-${item.network}` === currency);
        } else {
            flag = currencies.find((item) => item.currency === currency);
        }
        
        if (flag && flag.type === 'crypto') {
            return `${
                Root.serverUrl
            }/image/site/coins/${currency.split('-')[0].toLocaleLowerCase()}@2x.png`;
        } else {
            return `https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/${currency}.svg`;
        }
    };

    // const openPraxisModal = () => {
    //     setPraxisModalOpen(true);
    //     setSidebar(false);
    // };

    return (
        <>
            <User>
                <Balance
                    aria-describedby={balanceId}
                    onClick={(e) => setBalanceDropTag(e.currentTarget)}
                >
                    <p>
                        {RtFixed(
                            balanceData.balance[user.currency],
                            user.currency,
                            currencies
                        )}
                    </p>
                    &nbsp;
                    {
                        user.currency.includes('-') ? (<p>{`${user.currency.split('-')[0]}`}</p>) : (<p>{user.currency}</p>)  
                    }
                    
                    

                    <BalanceImg src={getCurrencyImg(user.currency)} />
                    {balanceOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Balance>
                <DepositBtn variant="contained" onClick={() => {
                    // if (window.$solitics) {
                    //     window.$solitics.onClick('DEPOSIT', null);
                    // }
                    // openPraxisModal();
                    history.push('/wallet')
                }}>
                    {t('DEPOSIT')}
                </DepositBtn>
            </User>
            <Menu
                id={balanceId}
                open={balanceOpen}
                anchorEl={balanceDropTag}
                onClose={() => setBalanceDropTag(null)}
                PaperProps={{
                    style: {
                        background: theme.background.secondary
                    }
                }}
                sx={{
                    maxWidth: 'unset'
                    // background: theme.background.secondary
                }}
            >
                <ProfileHeader>
                    <Tabs
                        value={value}
                        onChange={(e, v) => setValue(v)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label={t('Banking')} {...a11yProps(0)} />
                        <Tab label={t('Crypto')} {...a11yProps(1)} />
                    </Tabs>
                </ProfileHeader>
                <TabPanelWrapp value={value} index={0}>
                    {Object.keys(balanceData.balance).map((item, key) => (
                        <Fragment key={key}>
                            {value === 0 &&
                                currencies.find((it) => it.currency === item)
                                    ?.type === 'fiat' &&
                                (user.hidezero
                                    ? Number(balanceData.balance[item]) !== 0
                                    : true) && (
                                    <BalancePart
                                        onClick={() => changeCurrency(item)}
                                    >
                                        <BalanceAmount>
                                            {RtFixed(
                                                balanceData.balance[item],
                                                item,
                                                currencies
                                            )}
                                        </BalanceAmount>
                                        <BalanceImg
                                            src={getCurrencyImg(item)}
                                        />
                                        <BalanceName>{item}</BalanceName>
                                    </BalancePart>
                                )}
                        </Fragment>
                    ))}
                </TabPanelWrapp>
                <TabPanelWrapp value={value} index={1}>
                    {Object.keys(balanceData.balance).map((item, key) => (
                        <Fragment key={key}>
                            {value === 1 &&
                                currencies.find((it) => `${it.currency}-${it.network}` === item || it.currency === item)
                                    ?.type === 'crypto' &&
                                (user.hidezero
                                    ? Number(balanceData.balance[item]) !== 0
                                    : true) && (
                                    <BalancePart
                                        onClick={() => changeCurrency(item)}
                                    >
                                        <BalanceAmount>
                                            {RtFixed(
                                                balanceData.balance[item],
                                                item,
                                                currencies
                                            )}
                                        </BalanceAmount>
                                        <BalanceImg
                                            src={getCurrencyImg(item)}
                                        />
                                        <BalanceName>{`${item.split('-')[0]} (${item.split('-')[1]})`}</BalanceName>
                                    </BalancePart>
                                )}
                        </Fragment>
                    ))}
                </TabPanelWrapp>

                <SwitchBox onClick={() => setHidezero()}>
                    <Switch checked={user.hidezero} />
                    <SwitchName>{t('Hide zero balances')}</SwitchName>
                </SwitchBox>
            </Menu>
        </>
    );
};

export default Header;
