import io from 'socket.io-client';
import create from 'zustand';
import { toast } from 'react-toastify';
import { Root } from '../config/config';
import AuthApi from '../api/AuthApi';
import ConfigApi from '../api/ConfigApi';
import { providerconfig } from '../config/mainConfig';
import { ToastBox } from '../pages/base/toast';
import { depositSuccessNotify } from '../config/mainConfig';
import BaseZustand from './BaseZustand';

const useStore = create((set, get) => ({
    isAuth: false,

    user: {
        username: '',
        nickname: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        gender: '',
        birthday: '',
        country: '',
        state: '',
        city: '',
        postcode: '',
        kyc_data: [],
        currency: 'EUR',
        dis_currency: 'EUR',
        kyc_status: 'block',
        add_status: 'block',
        pay_status: 'block',
        language: 'en',
        hidezero: false,
        promo_email: false,
        promo_sms: false,
        notify_site: false,
        notify_email: false,
        notify_sms: false,
        verify_email: false,
        verify_sms: false
    },
    balanceData: {
        balance: { EUR: 0 },
        lock_balance: { EUR: 0 },
        bonus_balance: { EUR: 0 },
        vipLevel: '',
        vipDate: '',
        vipPoint: 0
    },
    notifyData: [],

    myData: {},
    priceData: {},

    countryData: [],
    adminWallet: '',
    chainDataList: [],
    currencies: [],
    vips: [],
    languages: [],
    minWithdrawAmount: 0,
    regiBanner: {},
    logoImage: '',
    favImage: '',

    proImgData: [],
    mainMenuData: [],
    footerMenuData: [],

    useBonus: true,

    addtionalProviderConfig: {},

    gameSliderDatas: [],

    requestAttempt: {
        login: false,
        register: false
    },

    sessionCheck: async () => {
        let token = AuthApi.getToken();

        const baseInit = async () => {
            const crdata = await ConfigApi.getPlayerBaseData();
            set({
                currencies: crdata.data.currencyData,
                vips: crdata.data.vipData,
                languages: crdata.data.languageData,
                countryData: crdata.data.countryData,
                adminWallet: crdata.data.AdminWalletAddress,
                minWithdrawAmount: crdata.data.minWithdrawAmount, 
                chainDataList: crdata.data.BlockchainList
            });
        };

        const siteConfigInit = async () => {
            const configData = await ConfigApi.getAllConfig();
            const { myData, siteData } = configData.data;

            const logoData =
                siteData.find((item) => item.key === 'logo')?.value || '';
            const faviconData =
                siteData.find((item) => item.key === 'favicon')?.value || '';
            // const callbellData =
            //     siteData.find((item) => item.key === 'callbell')?.value || '';
            const gsData =
                siteData.find((item) => item.key === 'google_script')?.value ||
                '';
            const playerNameData =
                siteData.find((item) => item.key === 'playerName')?.value ||
                Root.site;

            let link = document.querySelector("link[rel~='icon']");
            link.href = `${Root.adminWebsiteUrl}${faviconData}`;

            let link1 = document.querySelector("link[rel~='apple-touch-icon']");
            link1.href = `${Root.adminWebsiteUrl}${faviconData}`;

            document.title = playerNameData;

            // const script = document.createElement('script');
            // script.src = `${Root.adminWebsiteUrl}${callbellData}`;
            // script.async = true;
            // document.body.appendChild(script);

            const script1 = document.createElement('script');
            script1.src = `https://www.googletagmanager.com/gtag/js?id=${gsData}`;
            script1.async = true;
            document.body.appendChild(script1);

            // eslint-disable-next-line no-eval
            eval(`
                window.dataLayer = window.dataLayer || [];
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', '${gsData}');
            `);

            set({
                logoImage: logoData,
                favImage: faviconData,
                regiBanner:
                    siteData.find((item) => item.key === 'regi_banner')
                        ?.detail || {},
                myData
            });
        };

        const siteContentInit = async () => {
            const crdata = await ConfigApi.getPlayerContentData();
            set({
                proImgData: crdata.data.proImgData,
                mainMenuData: crdata.data.mainMenuData,
                footerMenuData: crdata.data.footerMenuData,
                useBonus: crdata.data.useBonus,
                addtionalProviderConfig: { ...crdata.data.addtionalData, ...providerconfig },
                gameSliderDatas: crdata.data.gameSliderDatas
            });
        };

        const requestLogInit = async () => {
            const res = await ConfigApi.getRequestLog();
            set({ requestAttempt: res.data });
        };

        const init5 = async () => {
            const rdata = await ConfigApi.getMyNotification();
            if (rdata) {
                set({ notifyData: rdata.data });
                if (
                    !get().user.deposited &&
                    window.location.pathname !== '/promotion'
                ) {
                    for (let i = 0; i < rdata.data.length; i++) {
                        const content = JSON.parse(rdata.data[i].content);
                        if (
                            content &&
                            content.id &&
                            content.id === depositSuccessNotify
                        ) {
                            window.location.assign('/promotion');
                        }
                    }
                }
            }
        };

        const init6 = async () => {
            Root.socket = io(Root.serverUrl, { query: { token } });

            setInterval(() => {
                Root.socket.emit('getbalance', { token });
            }, 1000 * 10);

            Root.socket.off('balance');
            Root.socket.on('balance', (data) => {
                set((state) => ({
                    balanceData: {
                        ...state.balanceData,
                        balance: data.balance,
                        bonus_balance: data.bonus_balance,
                        lock_balance: data.lock_balance,
                        vipLevel: data.vipLevel,
                        vipDate: data.vipDate,
                        vipPoint: data.vipPoint
                    }
                }));

                if (data.activated !== get().user.activated) {
                    set((state) => ({
                        user: {
                            ...state.user,
                            activated: data.activated
                        }
                    }));
                }

                if (data.notifyCount !== get().notifyData.length) {
                    init5();
                }
            });
            Root.socket.off('expire');
            Root.socket.on('expire', () => {
                if (window.$solitics) {
                    window.$solitics.onLogout();
                }
                toast.error(<ToastBox letter="Session Expired" />);
                AuthApi.clearToken();
                window.location.reload();
            });
            Root.socket.off('price');
            Root.socket.on('price', (data) => {
                set({ priceData: data });
            });
            const priceData = await ConfigApi.getPriceList();
            set({ priceData: priceData.data.data });
        };

        baseInit();
        siteConfigInit();
        siteContentInit();

        if (token) {
            const rdata = await AuthApi.sessionCheck();
            set({
                user: rdata.data,
                balanceData: {
                    balance: rdata.data.balance,
                    bonus_balance: rdata.data.bonus_balance,
                    lock_balance: rdata.data.lock_balance,
                    vipLevel: rdata.data.vipLevel,
                    vipDate: rdata.data.vipDate,
                    vipPoint: rdata.data.vipPoint
                },
                isAuth: true
            });
            init5();
            init6();
        } else {
            requestLogInit();
        }
    },
    sessionUpdate: async () => {
        if (AuthApi.getToken()) {
            const rdata = await AuthApi.sessionCheck();
            set({
                user: rdata.data,
                balanceData: {
                    balance: rdata.data.balance,
                    bonus_balance: rdata.data.bonus_balance,
                    lock_balance: rdata.data.lock_balance,
                    vipLevel: rdata.data.vipLevel,
                    vipDate: rdata.data.vipDate,
                    vipPoint: rdata.data.vipPoint
                }
            });
        }
    },
    userLogin: async (loginData) => {
        const rdata = await AuthApi.userLogin(loginData);
        const { setAuthLoginModal } = BaseZustand.getState();
        const { sessionCheck } = useStore.getState();
        if (rdata && rdata.data) {
            if (rdata.data.status) {
                toast.success(<ToastBox letter={rdata.data.message} />);
                AuthApi.setToken(rdata.data.token);
                if (window.location.pathname === '/game') {
                    await sessionCheck();
                    return setAuthLoginModal(false);
                }
                if (window.$solitics) {
                    const { id, email } = rdata.data;
                    window.$solitics.loginSuccess({
                        memberId: id,
                        email,
                        brand: Root.soliticsBrand,
                        branch: Root.soliticsBranch,
                        popupToken: Root.soliticsPopupToken
                    });
                }
                setTimeout(() => {
                    window.location.assign('/home');
                }, 100);
            } else {
                toast.error(<ToastBox letter={rdata.data.message} />);
                set({ requestAttempt: rdata.data.resData });
            }
        }
    },
    userLoginWitWallet: async (loginData) => {
        const rdata = await AuthApi.userLoginWitWallet(loginData);
        const { setAuthLoginModal } = BaseZustand.getState();
        const { sessionCheck } = useStore.getState();
        if (rdata && rdata.data) {
            if (rdata.data.status) {
                toast.success(<ToastBox letter={rdata.data.message} />);
                AuthApi.setToken(rdata.data.token);
                if (window.location.pathname === '/game') {
                    await sessionCheck();
                    return setAuthLoginModal(false);
                }
                if (window.$solitics) {
                    const { id, email } = rdata.data;
                    window.$solitics.loginSuccess({
                        memberId: id,
                        email,
                        brand: Root.soliticsBrand,
                        branch: Root.soliticsBranch,
                        popupToken: Root.soliticsPopupToken
                    });
                }
                setTimeout(() => {
                    window.location.assign('/home');
                }, 100);
            } else {
                toast.error(<ToastBox letter={rdata.data.message} />);
                set({ requestAttempt: rdata.data.resData });
            }
        }
    },
    userRegister: async (registerData) => {
        const rdata = await AuthApi.userRegister(registerData);
        if (rdata && rdata.data) {
            toast.success(<ToastBox letter={rdata.data.message} />);
            AuthApi.setToken(rdata.data.token);
            setTimeout(() => {
                window.location.reload();
            }, 100);
            return true;
        } else {
            return false;
        }
    },
    userRegisterByWallet: async (walletAddress) => {
        const rdata = await AuthApi.userRegisterByWallet(walletAddress);
        if (rdata && rdata.data) {
            toast.success(<ToastBox letter={rdata.data.message} />);
            AuthApi.setToken(rdata.data.token);
            setTimeout(() => {
                window.location.reload();
            }, 100);
            return true;
        } else {
            return false;
        }
    },
    userShortRegister: async (registerData) => {
        const rdata = await AuthApi.userShortRegister(registerData);
        if (rdata && rdata.data) {
            toast.success(<ToastBox letter={rdata.data.message} />);
            AuthApi.setToken(rdata.data.token);
            setTimeout(() => {
                window.location.reload();
            }, 100);
            return true;
        } else {
            return false;
        }
    },
    setUpSession: (token) => {
        AuthApi.clearToken();
        setTimeout(() => {
            AuthApi.setToken(token);
            setTimeout(() => {
                window.location.reload();
            }, 100);            
            // setTimeout(() => {
            //     if (type === 'bonus') {
            //         window.location.assign(`/promotion/2?dialog=welcomebonus&type=bonus`);
            //     } else if (type === 'nodeposit') {
            //         window.location.assign(`/profile`);
            //     } else {
            //         window.location.assign(`/deposit`);
            //     }
            // }, 300)
        }, 100);
    },
    userLogout: async () => {
        if (window.$solitics) {
            window.$solitics.onLogout();
        }
        await AuthApi.userLogout();
        AuthApi.clearToken();
        window.location.assign('/home');
    },
    getMyNotification: async () => {
        const res = await ConfigApi.getMyNotification();
        if (res) {
            set({ notifyData: res.data });
        }
    }
}));

export default useStore;
