import { useEffect, useState } from 'react';

function useIntervalTrigger(interval) {
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        if (interval < 1) {
            return;
        }

        const intervalIndex = setInterval(() => {
            setTrigger((i) => i + 1);
        }, interval);
        return () => {
            clearInterval(intervalIndex);
        };
    }, []);

    return trigger;
}

export default useIntervalTrigger;
