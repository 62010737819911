import {
    useEffect,
    useState,
    useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { LoadingContext } from '../../../layout/context/loading';
import GameZustand from '../../../zustand/GameZustand';
import AuthZustand from '../../../zustand/AuthZustand';
import BaseZustand from '../../../zustand/BaseZustand';

import * as S from '../style';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
    MultipleGameCloseIconWrapper,
    MultipleGameCurrentItemGameName,
    MultipleGameCurrentItemHeader,
    MultipleGameCurrentItemHeaderInfoWrapper
} from './MultipleGames/style';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from 'styled-components';
import { Theme } from '../../../theme';
import { generateBackgroundImageByThemeMode, getGameImageSrc } from '../../../helpers';
import { GameItemData } from '../types';

const GamePage = ({
    setGameModal,
    setAllScreen,
    handle,
    allScreen,
    renderCount
}) => {
    const theme = useTheme() as Theme;
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const {
        game,
        provider,
        removeCurrentProviders,
        openGame,
        removeProviderById
    } = GameZustand((state) => state);
    const { user, balanceData, currencies, isAuth, addtionalProviderConfig } =
        AuthZustand((state) => state);
    const { RtFixed, setAuthModal } = BaseZustand(
        (state) => state
    );
    const [url, setUrl] = useState('');

    const [pokerUrl, setPokerUrl] = useState('');

    const dimensions = useWindowDimensions();
    const backgroundImage = generateBackgroundImageByThemeMode(theme.mode);

    const gameInit = async () => {
        if (
            isAuth &&
            balanceData.balance[user.currency] &&
            balanceData.balance[user.currency] > 0 &&
            provider.currency.includes(user.dis_currency)
        ) {
            const data = await openGame(
                { ...game, locale: i18n.language },
                true
            );
            if (data) {
                setPokerUrl(data.data);
            }
        }
    };

    useEffect(() => {
        if (!game._id) {
            history.goBack();
        }
        if (game.gameType === addtionalProviderConfig.Poker) {
            gameInit();
        }

        const eventMethod = window.addEventListener
            ? 'addEventListener'
            : 'attachEvent';
        const eventer = window[eventMethod];
        const messageEvent =
            eventMethod === 'attachEvent' ? 'onmessage' : 'message';
        eventer(messageEvent, function (e) {
            switch (e.data) {
                case 'closeLobby':
                    closeFunc();
                    break;
                default:
                    break;
            }
        });
    }, []);

    const playGame = (data) => {
        if (!data) return;
        if (data.status === 1) {
            if (game.gameType !== addtionalProviderConfig.Poker) {
                if (window.innerWidth < 1250) {
                    window.location.replace(data.data);
                } else {
                    setUrl(data.data);
                }
            }
        } else {
            if (game.detail.system === '992' || game.detail.system === '944') {
                const { gameHtml, gameScript } = data.data;
                if (gameHtml || gameScript) {
                    const newData = gameHtml.split('amp;').join('');
                    document.getElementById('game').innerHTML = newData;
                    // eslint-disable-next-line
                    eval(gameScript);
                } else {
                    history.goBack();
                }
            } else {
                if (window.innerWidth < 1250) {
                    if (
                        game.provider === '6202af80aa8da4b634fd472d' ||
                        game.provider === '633d6fa71819bcaba63ddc07'
                    ) {
                        const newData = data.data.split('amp;').join('');
                        const findex = newData.indexOf('src="');
                        const fstring = newData.slice(
                            findex + 5,
                            newData.length
                        );
                        const sindex = fstring.indexOf('"');
                        const sstring = fstring.slice(0, sindex);
                        window.location.replace(sstring);
                    } else {
                        const newData = data.data;
                        const findex = newData.indexOf('text/javascript">');
                        const sindex = newData.indexOf('</script>');
                        const fstring = newData.slice(findex + 18, sindex);
                        // eslint-disable-next-line no-eval
                        eval(fstring);
                    }
                } else {
                    const newData = data.data.split('amp;').join('');
                    document.getElementById('game').innerHTML = newData;
                }
            }
        }
    };

    const openDemo = async () => {
        showLoading();
        const data = await openGame({ ...game, locale: i18n.language }, false);
        hideLoading();
        playGame(data);
    };

    const OpenGame = async () => {
        if (!isAuth) {
            toast.warn(t('Please login'));
            setAuthModal({ login: true, register: false }, history);
            return;
        }
        if (
            (balanceData.balance[user.currency] &&
                balanceData.balance[user.currency] > 0) ||
            (balanceData.bonus_balance[user.dis_currency] &&
                balanceData.bonus_balance[user.dis_currency] > 0)
        ) {
            if (!provider.currency.includes(user.dis_currency)) {
                toast.warn(`${user.dis_currency} ${t('is not allowed on this game')}`);
                return;
            }

            showLoading();

            const data = await openGame({ ...game, locale: i18n.language }, true);
            playGame(data);

            hideLoading();
        } else {
            toast.warn(t('Please deposit money'));
        }
    };

    const closeFunc = () => {
        if (history.location.pathname === '/game') {
            history.goBack();
        } else {
            setGameModal(false);
            setAllScreen(false);
            handle.exit();
        }
        removeCurrentProviders();
    };

    const handleCloseScreen = (id, providerId) => {
        const element = document.querySelector(`.${id}`);
        if (providerId) removeProviderById(providerId);
        if (element) {
            element.remove();
        }
        if (renderCount === 0) {
            history.goBack();
            setGameModal(false);
            setAllScreen(false);
            handle.exit();
            removeCurrentProviders();
        }
    };
    return (
        <S.SoftTopId
            className={'game'}
            img={backgroundImage}
            platform={navigator.platform}
            allScreen={String(allScreen)}
            screenCount={renderCount}
            style={{
                height:
                    dimensions.clientWidth < 1250
                        ? dimensions.clientHeight
                        : null
            }}
        >
            <MultipleGameCurrentItemHeader>
                <MultipleGameCurrentItemHeaderInfoWrapper>
                    <MultipleGameCurrentItemGameName>
                        <h1>{game?.gameName}</h1>
                        {provider ? (
                            <h3>
                                {provider?.providerName}
                                <span>
                                    {` + RTP: ${game?.detail?.RTP
                                        ? Number(game?.detail?.RTP).toFixed(
                                            2
                                        )
                                        : provider?.RTP
                                        }%` || ''}
                                </span>
                            </h3>
                        ) : null}
                    </MultipleGameCurrentItemGameName>
                    {renderCount > 0 ? (
                        <MultipleGameCloseIconWrapper
                            onClick={() =>
                                handleCloseScreen('game', game?.provider)
                            }
                        >
                            <CloseIcon
                                sx={{
                                    color:
                                        theme.mode === 'white'
                                            ? theme.background.primary
                                            : theme.text
                                }}
                            />
                        </MultipleGameCloseIconWrapper>
                    ) : null}
                </MultipleGameCurrentItemHeaderInfoWrapper>
            </MultipleGameCurrentItemHeader>
            <S.GamePlayPage url={String(!url)} id="game">
                {!url ? (
                    <>
                        <S.MobileGameBox>
                            <S.HeaderBox3>
                                <IconButton onClick={closeFunc}>
                                    <Close />
                                </IconButton>
                            </S.HeaderBox3>
                            <S.HeaderBox4>
                                <S.GameName1>
                                    <span>{game.gameName}</span>
                                </S.GameName1>
                                <S.ProviderName>
                                    {provider.providerName}&nbsp;&nbsp;
                                    {game.gameType === addtionalProviderConfig['Slot']
                                        ? `RTP: ${game.detail.RTP
                                            ? Number(
                                                game.detail.RTP
                                            ).toFixed(2)
                                            : provider.RTP
                                        }%`
                                        : ''}
                                </S.ProviderName>
                                {isAuth ? (
                                    <S.GameBalanceShowPart>
                                        <S.BalanceIn>{t('Bonus')}</S.BalanceIn>
                                        <S.BalancePart>
                                            <S.BalanceImg src="https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/EUR.svg" />
                                            <S.Balance>
                                                {RtFixed(
                                                    balanceData.bonus_balance &&
                                                        balanceData
                                                            .bonus_balance[
                                                        user.dis_currency
                                                        ]
                                                        ? balanceData
                                                            .bonus_balance[
                                                        user.dis_currency
                                                        ]
                                                        : 0,
                                                    user.dis_currency,
                                                    currencies
                                                )}
                                            </S.Balance>
                                            &nbsp;
                                            <S.Balance>
                                                {user.dis_currency}
                                            </S.Balance>
                                        </S.BalancePart>
                                    </S.GameBalanceShowPart>
                                ) : (
                                    <></>
                                )}
                            </S.HeaderBox4>
                            <img
                                src={getGameImageSrc(game as unknown as GameItemData)}
                                alt=""
                            />
                        </S.MobileGameBox>
                        <S.ButtonPart>
                            {!isAuth ? (
                                <S.RegisterBtn
                                    variant="contained"
                                    onClick={() =>
                                        setAuthModal(
                                            { login: true, register: false },
                                            history
                                        )
                                    }
                                >
                                    {t('LOGIN')}
                                </S.RegisterBtn>
                            ) : (
                                <></>
                            )}
                            {game && game.detail.HasDemo ? (
                                <S.LoginBtn
                                    variant="contained"
                                    onClick={() => openDemo()}
                                >
                                    {t('Fun Play')}
                                </S.LoginBtn>
                            ) : (
                                <></>
                            )}
                            {isAuth ? (
                                game.gameType !== addtionalProviderConfig.Poker ? (
                                    <S.RegisterBtn
                                        variant="contained"
                                        onClick={() => OpenGame()}
                                    >
                                        {t('Play Game')}
                                    </S.RegisterBtn>
                                ) : (
                                    <S.RegisterBtn
                                        variant="contained"
                                        disabled={!pokerUrl}
                                    >
                                        <a href={pokerUrl} target="blank">
                                            {t('Play Game')}
                                        </a>
                                    </S.RegisterBtn>
                                )
                            ) : (
                                <></>
                            )}
                        </S.ButtonPart>
                    </>
                ) : (
                    <></>
                )}
                {url ? (
                    <iframe
                        src={url}
                        title="game"
                        style={{ width: '100%', height: '100%' }}
                        allowFullScreen
                    />
                ) : (
                    <></>
                )}
            </S.GamePlayPage>
        </S.SoftTopId>
    );
};

export default GamePage;
