import { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsNone from '@mui/icons-material/NotificationsNone';
import { useTranslation } from 'react-i18next';

import { Root } from '../../../../config/config';

import ConifgZustand from '../../../../zustand/ConifgZustand';
import AuthZustand from '../../../../zustand/AuthZustand';

import {
    NotificationPart,
    NotificationList,
    NotifyItem,
    NotifyListItem,
    NotifyButtonPart,
    AvatarBox,
    DepositBtn
} from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';

const Header = () => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();

    const { readAllNotification } = ConifgZustand((state) => state);
    const { notifyData, getMyNotification } = AuthZustand((state) => state);

    const [notifications, setNotifications] = useState([]);
    const [dropTag, setDropTag] = useState(null);
    const open = Boolean(dropTag);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        const tempData = [];
        for (let i = 0; i < notifyData.length; i++) {
            tempData.push({
                _id: notifyData[i]._id,
                avatar: `${Root.adminWebsiteUrl}${notifyData[i].avatar}`,
                sender: notifyData[i].sender,
                title: JSON.parse(notifyData[i].content).title_site,
                content: JSON.parse(
                    notifyData[i].content
                ).content_site.replaceAll('\n', ' '),
                auto: notifyData[i].auto
            });
        }
        setNotifications(tempData);
        // eslint-disable-next-line
    }, [notifyData]);

    const readall = async () => {
        const flag = await readAllNotification();
        if (flag) {
            getMyNotification();
            setDropTag(null);
        }
    };

    return (
        <NotificationPart>
            <IconButton
                aria-describedby={id}
                onClick={(e) => setDropTag(e.currentTarget)}
            >
                {notifications.length ? (
                    <Badge badgeContent={notifications.length}>
                        <NotificationsNone sx={{ color: theme.text }} />
                    </Badge>
                ) : (
                    <NotificationsNone sx={{ color: theme.text }} />
                )}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={dropTag}
                onClose={() => setDropTag(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                style={{ maxHeight: '500px' }}
            >
                <NotificationList hasItems={notifications.length > 0}>
                    {notifications.length === 0 && (
                        <NotifyListItem key="no notifications">
                            <NotifyItem>
                                <p>
                                    <span>{t('There are no new notifications')}</span>
                                </p>
                            </NotifyItem>
                        </NotifyListItem>
                    )}
                    {notifications.map((item, key) => (
                        <NotifyListItem key={key}>
                            <AvatarBox className="avatar">
                                <img src={item.avatar} alt="" />
                            </AvatarBox>
                            <NotifyItem>
                                <p>
                                    <span>{t(item.title)}</span>
                                </p>
                                {item.auto ? (
                                    <small
                                        dangerouslySetInnerHTML={{
                                            __html: item.content
                                        }}
                                    />
                                ) : (
                                    <small>{t(item.content)}</small>
                                )}
                            </NotifyItem>
                        </NotifyListItem>
                    ))}
                    {notifications.length > 0 && (<NotifyButtonPart>
                        <DepositBtn variant="contained" onClick={readall}>
                            {t('Read Receipt')}
                        </DepositBtn>
                    </NotifyButtonPart>)}
                </NotificationList>
            </Popover>
        </NotificationPart>
    );
};

export default Header;
