import create, { SetState } from 'zustand';
import TournamentApi from '../api/TournamentApi';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { ITournament } from '../pages/Tournament/types';
import { ReactText } from 'react';
import { IGetGamesResponse } from '../types';

export interface ITournamentStore {

    tournaments: ITournament[];
    localTournaments: [];
    tournamentLeaderboard: [];
    currentTournament: ITournament | null;
    tournamentGames: [];
    tournamentGamesCount: number;
    statictournamentGames: [];
    tournamentBonuses: any[];
    sidebarTournaments: ITournament[];
    actualTournaments: ITournament[];
    bonusGames: [];
    bonusGamesCount: number;
    tournamentProgression: unknown | [];
    pastTournaments: [];
    pastCurrentTournament: ITournament | null;
    activeBonusTournament: ITournament | null;
    totalCount: number;
    limit: number;
    offset: number;
    userProgression: any | null;
}
export const useTournamentStore = create(
    (set: SetState<ITournamentStore>, get: any) => ({
        tournaments: [],
        localTournaments: [],
        currentTournament: null,
        tournamentLeaderboard: [],
        tournamentGames: [],
        tournamentGamesCount: 0,
        statictournamentGames: [],
        tournamentBonuses: [],
        sidebarTournaments: [],
        actualTournaments: [],
        bonusGames: [],
        bonusGamesCount: 0,
        tournamentProgression: [],
        pastTournaments: [],
        pastCurrentTournament: null,
        activeBonusTournament: null,
        totalCount: 0,
        limit: 5,
        offset: 0,
        userProgression: null,

        getTournaments: async (): Promise<void | ReactText> => {
            try {
                // const response: AxiosResponse =
                //     await TournamentApi.getTournaments();
                // if (response.data.length > 0) {
                //     set({
                //         sidebarTournaments: response.data,
                //         tournaments: response.data
                //     });
                // }
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getActualTournaments: async (): Promise<void | ReactText> => {
            try {
                // const response: AxiosResponse =
                //     await TournamentApi.getTournaments(true);
                // if (response.data.length > 0) {
                //     set({ actualTournaments: response.data });
                // }
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getTournamentByBonusId: async (bonusId): Promise<void> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getTournamentByBonusId(bonusId);
                if (response.data) {
                    set({ activeBonusTournament: response.data });
                }
            } catch (e) {
                set({ activeBonusTournament: null });
            }
        },

        getAllLocalTournament: async () => {
            const rdata = await TournamentApi.getAllLocalTournament();
            if (rdata) {
                return rdata.data;
            }
        },

        setAllLocalTournaments: async() => {
            const response: AxiosResponse =
                    await TournamentApi.getAllLocalTournament();
            if (response.data?.length > 0) {
                set({ localTournaments: response.data })
            }
        },
        getCurrentLocalTournament: async (data) => {
            const rdata = await TournamentApi.getCurrentLocalTournament(data);
            if (rdata) {
                return rdata.data;
            }
        },

        getTournament: async (tournamentId): Promise<void | ReactText> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getTournament(tournamentId);
                set({ currentTournament: response.data });
            } catch (e) {
                return toast.error(e.message);
            }
        },

        resetCurrentTournament: () => {
            set({
                currentTournament: null,
                tournamentGames: [],
                tournamentLeaderboard: [],
                tournamentProgression: []
            });
        },

        resetPastCurrentTournament: () => {
            set({
                pastCurrentTournament: null,
                tournamentGames: [],
                tournamentLeaderboard: [],
                tournamentProgression: []
            });
        },

        getTournamentLeaderboard: async (
            tournamentId,
            limit,
            offset
        ): Promise<void | ReactText> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getTournamentLeaderboard(
                        Number(tournamentId),
                        limit,
                        offset
                    );

                if (response.data.results.length > 0) {
                    set({
                        tournamentLeaderboard: response.data.results,
                        totalCount: response.data.totalCount
                    });
                }
            } catch (e) {
                return toast.error(e.message);
            }
        },

        resetTournamentLeaderboard: () => {
            set({ tournamentLeaderboard: [] });
        },

        getTournamentCurrentUserLeaderboard: async (
            tournamentId,
            limit,
            offset
        ): Promise<void | ReactText> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getTournamentCurrentUserLeaderboard(
                        Number(tournamentId),
                        limit,
                        offset
                    );
                if (response.data.results.length > 0) {
                    set({
                        tournamentProgression: response.data.results,
                        totalCount: response.data.totalCount
                    });
                }
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getUserTournamentProgression: async (tournamentId) => {
            try {
                const response =
                    await TournamentApi.getUserTournamentProgression(
                        tournamentId
                    );
                if (response.data) {
                    set({ userProgression: response.data });
                }
            } catch (e) {
                toast.error(e.message);
            }
        },

        subscribeToTournament: async (
            tournamentId
        ): Promise<void | ReactText> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.subscribeToTournament(tournamentId);
                if (response.data) {
                    toast.success('You subscribed to the tournament!');
                    return response.data;
                }
            } catch (e) {
                return toast.error(e.message);
            }
        },

        unsubscribeFromTournament: async (tournamentId) => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.unsubscribeFromTournament(tournamentId);
                if (response.data) {
                    toast.success('You unsubscribed from the tournament!');
                    return response.data;
                }
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getTournamentGames: async (gameOptions, page, perPage): Promise<IGetGamesResponse | ReactText> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getTournamentGames(gameOptions, page, perPage);
                if (response.data?.data?.length > 0) {
                    set({ tournamentGames: response.data.data });
                }
                if (response.data?.count > 0) {
                    set({ tournamentGamesCount: response.data.count });
                }
                return response.data;
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getStaticTournamentGames: async (page: number, perPage: number, t_id: number): Promise<IGetGamesResponse | ReactText> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getStaticTournamentGames(page, perPage, t_id);
                if (response.data?.data?.length > 0) {
                    set({ statictournamentGames: response.data.data });
                }
                if (response.data?.count > 0) {
                    set({ tournamentGamesCount: response.data.count });
                }
                return response.data;
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getTournamentUserBonuses: async (): Promise<void | ReactText> => {
            try {
                // const response: AxiosResponse =
                //     await TournamentApi.getTournamentUserBonuses();
                // set({ tournamentBonuses: [...response.data] });
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getPastTournaments: async (limit: number) => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getPastTournaments(limit);
                set({ pastTournaments: response.data });
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getPastTournament: async (tournamentId) => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getPastTournament(tournamentId);
                set({ pastCurrentTournament: response.data });
            } catch (e) {
                return toast.error(e.message);
            }
        },

        getBonusGames: async (gameOptions): Promise<void | ReactText> => {
            try {
                const response: AxiosResponse =
                    await TournamentApi.getTournamentGames(gameOptions);
                if (response.data?.data?.length > 0) {
                    set({ bonusGames: response.data.data });
                }
                if (response.data.count > 0) {
                    set({ bonusGamesCount: response.data.count });
                }
            } catch (e) {
                return toast.error(e.message);
            }
        },

        redeemTournamentBonus: async (bonusId): Promise<void | ReactText> => {
            try {
                const { data } = await TournamentApi.selectTournamentBonus(
                    true,
                    bonusId
                );
                if (data.error) {
                    toast.error('Error redeeming your bonus!');
                }

                if (data.Status) {
                    toast.success('You bonus has been redeemed!');
                }
            } catch (e) {
                toast.error('Error redeeming your bonus!');
            } finally {
                get().getTournamentUserBonuses();
            }
        },

        cancelTournamentBonus: async (bonusId): Promise<void | ReactText> => {
            try {
                const { data } = await TournamentApi.cancelTournamentBonus(
                    bonusId
                );
                if (data.error) {
                    toast.error('Error redeeming your bonus!');
                }

                if (data.Status) {
                    toast.success('You bonus has been redeemed!');
                }
            } catch (e) {
                toast.error('Error redeeming your bonus!');
            } finally {
                get().getTournamentUserBonuses();
            }
        }
    })
);

export const handleNextPage = (event, totalCount, itemsPerPage) => {
    const newOffset = (event.selected * itemsPerPage) % totalCount;
    useTournamentStore.setState((prev) => ({
        offset: (prev.offset = newOffset)
    }));
};
