import styled from 'styled-components';
import { TournamentItemTextWrapper } from '../style';

export const CurrentTournamentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 50px;
    & span {
        font-family: 'Big Shoulders Display', sans-serif;
        letter-spacing: 2px;
        font-size: 20px;
        color: #8a8989;
    }
    @media (max-width: 1300px) {
        align-items: center;
    }
`;

export const TournamentCurrentItemTextWrapper = styled(
    TournamentItemTextWrapper
)`
    align-items: center;
    text-align: center;
    max-width: fit-content;
    @media (max-width: 1300px) {
        justify-content: center;
        & > div {
            align-items: center;
        }
    }
`;

export const TournamentCurrentNameWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    top: 0;
    p {
        text-align: center;
        line-height: 120%;
        color: orange;
        font-size: 40px;
        -webkit-text-stroke: 1px #fff;
    }
    & > button {
        margin-top: 30px;
    }
    @media (max-width: 1300px) {
        flex: 1;
        margin-top: 60px;
        position: relative;
    }
`;

export const CurrentTournamentInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
`;

export const CurrentTournamentInfoTextWrapper = styled.div`
    width: 100%;
    max-width: calc(100% / 3);
    @media (max-width: 800px) {
        max-width: unset;
    }
`;
