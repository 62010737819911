import React from 'react';
import ReactPaginate from 'react-paginate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
interface ILeaderboardPagination {
    handlePageClick: (e: any) => void;
    totalCount: number;
    itemsPerPage: number;
}
const LeaderboardPagination = ({
    handlePageClick,
    totalCount,
    itemsPerPage
}: ILeaderboardPagination) => {
    const pageCount = Math.ceil(totalCount / itemsPerPage);

    return (
        <>
            <ReactPaginate
                className="paginationWrapper"
                breakLabel="..."
                nextLabel={<ArrowForwardIosIcon />}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel={<ArrowBackIosIcon />}
                activeClassName="active"
                breakClassName="break"
                renderOnZeroPageCount={null}
            />
        </>
    );
};

export default LeaderboardPagination;
