import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BaseZustand from '../../../../zustand/BaseZustand';

import {
    Cookie,
    CookieLetter,
    CookieAccept
} from './style';

const CookiePage = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [cookie, setCookie] = useState('disabled');
    const { pushUrl } = BaseZustand((state) => state);

    useEffect(() => {
        const data = localStorage.getItem('8866-cookie');
        if (data) {
            setCookie(data);
        }
    }, []);

    const setCookieFunc = () => {
        setCookie('enabled');
        localStorage.setItem('8866-cookie', 'enabled');
    };

    return (
        <>
            {cookie === 'disabled' ? (
                <Cookie>
                    <CookieLetter>
                        {t(
                            'We use cookies to improve your experience. By using our website you are accepting our'
                        )}
                        &nbsp;&nbsp;
                        <span onClick={() => pushUrl(history, '/cookie')}>
                            {t('Cookie Policy.')}
                        </span>
                    </CookieLetter>
                    <CookieAccept onClick={() => setCookieFunc()}>
                        {t('Accept')}
                    </CookieAccept>
                </Cookie>
            ) : (
                <></>
            )}
        </>
    );
};

export default CookiePage;
