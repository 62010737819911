import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    TournamentCurrentGamesListWrapper,
    TournamentGameItem,
    TournamentGameItemBtn,
    TournamentGameItemOverlay,
    TournamentGameList,
    TournamentGameTitle,
    TournamentGameListContainer
} from './style';
import { TournamentItemTitle } from '../../style';
import { IProvider } from '../types';
import GameZustand from '../../../../zustand/GameZustand';
import { GameItemData } from '../../../game/types';
import BaseZustand from '../../../../zustand/BaseZustand';
import AuthZustand from '../../../../zustand/AuthZustand';

import { useHistory } from 'react-router-dom';
// import { providerconfig } from '../../../../config/mainConfig';
import { useTranslation } from 'react-i18next';
import Image from '../../../../layout/component/image';
import GameMainPlaceholder from '../../../../layout/placeholders/gameMainPlaceholder.png';
import { SkeletonPlaceholder } from '../../../dashboard/style';
import InfiniteScroll from 'react-infinite-scroll-component';
import Grid from '@mui/material/Grid';

export interface ITournamentGamesProps {
    tournamentGames: GameItemData[];
    title: string;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    hasMoreGames: boolean;
}

const TournamentGames = ({
    tournamentGames,
    title,
    setCurrentPage,
    hasMoreGames
}: ITournamentGamesProps) => {
    const { addtionalProviderConfig } = AuthZustand((state) => state);

    const { t } = useTranslation();
    const history = useHistory();
    const [, setCurrentGameType] = useState('');
    const [currentProvider, setCurrentProvider] = useState<IProvider>({
        _id: '',
        image: '',
        providerName: '',
        currency: []
    });
    const { selectGame, getProviders } = GameZustand((state) => state);
    const [providerList, setProviderList] = useState<IProvider[]>([]);
    const { setGameModal } = BaseZustand((state) => state);

    useEffect(() => {
        if (tournamentGames.length > 0) {
            const fetchProviderData = async () => {
                const providers = await getProviders(
                    tournamentGames[0].gameType
                );
                setProviderList(providers);

                const foundProvider = providers.find(
                    (item) => item._id === tournamentGames[0].provider
                );
                setCurrentProvider(foundProvider);
            };

            const foundKey = Object.keys(addtionalProviderConfig).find(
                (key) => addtionalProviderConfig[key] === tournamentGames[0].gameType
            );
            if (foundKey) {
                setCurrentGameType(foundKey);
            }

            fetchProviderData();
        }
    }, [tournamentGames.length, providerList.length]);

    const handleStartGame = (gameItem: GameItemData) => {
        if (currentProvider) {
            const findProvider = providerList.find(
                (item) => item._id === gameItem.provider
            );
            setCurrentProvider(findProvider);
            selectGame(gameItem, findProvider);
            setGameModal(true);
            history.push('/game');
        }
    };

    return (
        <TournamentCurrentGamesListWrapper>
            <TournamentItemTitle>{t(`${title}`)}</TournamentItemTitle>
            <br />
            <TournamentGameListContainer>
                <InfiniteScroll
                    dataLength={tournamentGames.length}
                    next={() => setCurrentPage((prevPage) => prevPage + 1)}
                    hasMore={hasMoreGames}
                    scrollableTarget="top-id"
                    loader={<></>}
                    endMessage={<></>}
                >
                    <TournamentGameList container spacing={window.innerWidth > 768 ? 2 : 1}>
                        {Array(12)
                            .fill(null)
                            .map((item, key) => (
                                <Grid
                                    item
                                    md={2}
                                    sm={3}
                                    xs={6}
                                    key={key}
                                    style={{
                                        display: tournamentGames.length > 0 ? 'none' : 'block'
                                    }}
                                >
                                    <TournamentGameItem
                                        showSkeleton={tournamentGames.length > 0}
                                    >
                                        <SkeletonPlaceholder>
                                            <img src={GameMainPlaceholder} alt="" />
                                        </SkeletonPlaceholder>
                                    </TournamentGameItem>
                                </Grid>
                            ))}
                        {tournamentGames.length > 0 &&
                            tournamentGames.map((item: GameItemData, idx) => {
                                return (
                                    <Grid
                                        item
                                        md={2}
                                        sm={3}
                                        xs={6}
                                        key={idx}
                                    >
                                        <TournamentGameItem>
                                            <Image
                                                image={item.imageUrl}
                                                placeholder={GameMainPlaceholder}
                                                aspectRatio={4/3}
                                            />
                                            <TournamentGameItemOverlay>
                                                <TournamentGameTitle
                                                    style={{ color: '#ffffff' }}
                                                >
                                                    {item.gameName}
                                                </TournamentGameTitle>
                                                <TournamentGameTitle
                                                    style={{ color: '#ffffff' }}
                                                >
                                                    {currentProvider?.providerName}
                                                </TournamentGameTitle>
                                                <TournamentGameItemBtn
                                                    variant="contained"
                                                    onClick={() => {
                                                        handleStartGame(item);
                                                    }}
                                                >
                                                    {t('Play Game')}
                                                </TournamentGameItemBtn>
                                            </TournamentGameItemOverlay>
                                        </TournamentGameItem>
                                    </Grid>
                                );
                            })}
                    </TournamentGameList>
                </InfiniteScroll>
            </TournamentGameListContainer>
        </TournamentCurrentGamesListWrapper>
    );
};

export default TournamentGames;
