import { ImageContainer } from './style';
import { ReactElement } from 'react';

interface IImageProps {
    image: string;
    placeholder: string;
    onClick?: () => void;
    aspectRatio?: number;
    maxWidth?: number;
    badge?: ReactElement;
}

const Image = ({ image, placeholder, onClick, aspectRatio, maxWidth, badge }: IImageProps) => {
    return (
        <ImageContainer aspectRatio={aspectRatio} maxWidth={maxWidth}>
            <img
                onClick={onClick}
                src={placeholder}
                alt=""
                className="placeholder"
            />
            <img onClick={onClick} src={image} alt="" className="image" />
            {badge}
        </ImageContainer>
    );
};

export default Image;
