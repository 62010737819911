import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const LocationObserver = () => {
    const location = useLocation();
    const [previousPath, setPreviousPath] = useState(null);

    useEffect(() => {
        const { $solitics } = window as any;
        if ($solitics) {
            $solitics.onPageEnter(location.pathname, null);

            if (previousPath) {
                $solitics.onPageLeave(previousPath, null);
            }
        }
        setPreviousPath(location.pathname);
    }, [location.pathname]);

    return null;
}

export default LocationObserver;
