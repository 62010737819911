import { FC } from 'react';
import * as S from './style';

interface IInnerLoadingProps {
    status: boolean;
}

const InnerLoading: FC<IInnerLoadingProps> = ({ status }) => {
    if (status) {
        return (
            <S.InnerLoading>
                <S.Load5>
                    <S.Ring2>
                        <S.BallHolder>
                            <S.Ball />
                        </S.BallHolder>
                    </S.Ring2>
                </S.Load5>
            </S.InnerLoading>
        );
    }

    return null;
};

export default InnerLoading;
