import styled from 'styled-components';
import Box from '@mui/material/Box';

export const RegisterContent = styled(Box)<{ loading: boolean }>`
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: 900px) {
        width: 100% !important;
    }
    
    ${({ loading }) =>  loading ? `
        min-height: 700px;
    ` : ''}
`;
