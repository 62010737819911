import styled from 'styled-components';

export const TournamentTimerWrapper = styled.div`
    display: flex;
    font-size: 15px;
    font-weight: 500;
    justify-content: flex-start;
    margin: 0 20px;
`;

export const TournamentTimerItem = styled.div`
    width: 100%;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 10px;
    color: #8a8989;
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;

    & > span {
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #ffffff;
        font-family: 'Big Shoulders Display', sans-serif;
        border: 1px solid #ffffff;
        padding: 10px;
        margin-top: 10px;
    }
`;
