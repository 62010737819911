import React, { useEffect, useState } from 'react';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { TournamentBonusDescription } from '../../TournamentBonus/TournamentBonus';
import { useTranslation } from 'react-i18next';

const BonusWageringProgressBar = ({ bonus }) => {
    const { t } = useTranslation();
    const [progress, setProgress] = useState(0);

    const getLoadedPercent = (bonusWagering, totalWagering) => {
        if (bonusWagering >= totalWagering) {
            setProgress(100);
        } else {
            return setProgress((bonusWagering / totalWagering) * 100);
        }
    };

    useEffect(() => {
        if (bonus.Active) {
            getLoadedPercent(bonus.Wagering, bonus.ReleaseWageringTotal);
        }
    }, [bonus.Active, bonus.ReleaseWageringTotal, bonus.Wagering]);

    return (
        <>
            {bonus.Active > 0 ? (
                <TournamentBonusDescription>
                    <Stack
                        alignItems="center"
                        spacing={0.5}
                        sx={{
                            p: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#272a37',
                            position: 'sticky',
                            top: 0,
                            zIndex: 10,
                            border: '3px solid orange'
                        }}
                    >
                        <Typography
                            variant="caption"
                            textAlign="center"
                            sx={{
                                color: 'white'
                            }}
                        >
                            {t('Wagering')} {bonus.Wagering}/
                            {bonus.ReleaseWageringTotal}
                        </Typography>
                        <LinearProgress
                            sx={{
                                width: '100%',
                                mx: '12px !important',
                                background: 'rgb(34 64 100)'
                            }}
                            color="secondary"
                            variant="determinate"
                            value={progress}
                        />
                    </Stack>
                </TournamentBonusDescription>
            ) : null}
        </>
    );
};

export default BonusWageringProgressBar;
