export const UserDropDownList = [
    {
        name: 'My Profile',
        url: '/profile'
    },
    {
        name: 'Wallet Page',
        url: '/wallet'
    },
    {
        name: 'Sport History',
        url: '/history'
    },
    // {
    // 	name: 'Responsible Gambling',
    // 	url: '/profile?page=4'
    // },
    {
        name: 'Logout'
    }
];
