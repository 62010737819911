import styled, { css } from 'styled-components';

const handleChooseItem = (item: string, open: boolean) => {
    if (item) {
        return css`
          background: ${(props) =>
              props.theme.mode === 'white'
                  ? props.theme.primaryButton
                  : props.theme.background.primary};
          color: ${(props) =>
              props.theme.mode === 'white'
                  ? props.theme.background.primary
                  : props.theme.text};
            & svg {
              color: ${(props) =>
                  props.theme.mode === 'white'
                      ? props.theme.background.primary
                      : props.theme.text};            }
                    
            & svg,
            & img {
              color: ${(props) =>
                  props.theme.mode === 'white'
                      ? props.theme.background.primary
                      : props.theme.text};            
            }
            padding: ${open ? '10px 5px 12px 10px' : '10px 10px 12px 10px'}};
        `;
    }
};

export const MainSidebarWrapper = styled.div.attrs((props: any) => ({
    open: props.open,
    display: props.display
}))`
    height: 100%;
    width: ${(props) => (props.open ? '240px' : '60px')};
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props) => props.theme.text};
    position: fixed;
    z-index: ${(props) => (props.display === 'true' ? '0' : '10000')};
    background: ${(props) => props.theme.background.secondary};
    transition: all 0.1s;
    @media (max-width: 1200px) {
        display: none;
    }
    & a {
        color: unset;
        text-decoration: none;
    }
`;

export const MainSidebarChapterWrapper = styled.div.attrs((props: any) => ({
    open: props.open
}))`
    width: ${({ open }) => (open ? '100%' : 'calc(100% + 10px)')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    margin-bottom: 5px;
`;

export const OpenCloseButtonWrapper = styled.div.attrs((props: any) => ({
    open: props.open
}))`
    transform: ${({ open }) => (open ? 'rotateY(180deg)' : '')};
    cursor: pointer;
    position: absolute;
    right: ${({ open }) => (open ? '-20px' : '10px')};
    top: ${({ open }) => (open ? '15px' : '20px')};
    background: ${({ open, theme }) =>
        open ? theme.background.secondary : 'unset'};
    width: 40px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    height: 30px;
    align-items: center;
    button {
        color: white;
    }
`;

export const MainSidebarTitle = styled.h3.attrs((props: any) => ({
    open: props.open
}))`
    margin: 0;
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    user-select: none;
    display: ${({ open }) => (open ? 'block' : 'none')};
    transition: display 1s ease-in-out;
`;

export const MainSidebarItem = styled(MainSidebarChapterWrapper).attrs(
    (props: any) => ({
        open: props.open,
        url: props.url
    })
)`
    cursor: pointer;
    border: none;
    justify-content: flex-start;
    padding: ${({ open }) =>
        open ? '10px 5px 12px 10px' : '10px 10px 12px 10px'};
    font-family: 'PT Sans', sans-serif;
    border-radius: 10px;
    width: calc(100% - 10px);
    & .MuiBadge-badge {
        background-color: ${({ theme }) => theme.primaryButton};
        color: white;
    }
    ${({ url, open }) => handleChooseItem(url, open)}
    transition: all 0.3s;
    &:hover {
        background: ${(props) =>
            props.theme.mode === 'white'
                ? props.theme.primaryButton
                : props.theme.background.primary};
        color: ${(props) =>
            props.theme.mode === 'white'
                ? props.theme.background.primary
                : props.theme.text};
        & svg {
            color: ${(props) =>
                props.theme.mode === 'white'
                    ? props.theme.background.primary
                    : props.theme.text};
        }

        & svg,
        & img {
            color: ${(props) =>
                props.theme.mode === 'white'
                    ? props.theme.background.primary
                    : props.theme.text};
        }
    }
    img {
        width: 20px;
    }
`;

export const MainSidebarItemWithBadge = styled.div`
    display: flex;
    align-items: center;
    & .MuiBadge-badge {
        background-color: orangered;
        color: white;
    }
`;

export const MainSidebarItemTitle = styled(MainSidebarTitle)`
    font-size: 15px;
    margin-left: 10px;
    text-transform: unset;
`;

export const MainSidebarItemList = styled.div.attrs((props: any) => ({
    open: props.open
}))`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 0 10px 10px;
    display: ${({ open }) => (open ? 'flex' : 'none')};
`;

export const MainSidebarScroll = styled.div.attrs((props: any) => ({
    open: props.open
}))`
    width: calc(100% - 30px);
    height: ${(props) => (props.open ? '100vh' : `calc(100vh - ${props.theme.headerHeight})`)};
    margin-top: ${(props) => (props.open ? '0' : props.theme.headerHeight)};
    padding: ${(props) =>
        props.open ? '20px 20px 20px 10px' : '0px 20px 20px 10px'};
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    transition: overflow 0.3s;
    &:hover {
        overflow: hidden auto;
    }
`;
