import styled from 'styled-components';
import { Box } from '@mui/material';

export const B8866Loading = styled(Box)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #ffffff45;
    z-index: 99999999;
    user-select: none;

    @keyframes loadingE {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
export const Load5 = styled(Box)`
    animation: loadingE 3s linear infinite;
    user-select: none;
`;
export const Ring2 = styled(Box)`
    position: relative;
    width: 55px;
    height: 55px;
    margin: 0 auto;
    border: 4px solid #fa0;
    border-radius: 100%;
    user-select: none;
`;

export const BallHolder = styled(Box)`
    position: absolute;
    width: 12px;
    height: 55px;
    left: calc(50% - 6px);
    top: 0;
    animation: loadingE 1.3s linear infinite;
    user-select: none;
`;

export const Ball = styled(Box)`
    position: absolute;
    top: -11px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #fa0;
    user-select: none;
`;

export const InnerLoading = styled(B8866Loading)`
  position: unset;
  width: 100%;
  height: 100%;
  background: transparent;
`;
