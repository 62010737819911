import styled from 'styled-components';

export const TopId = styled.div.attrs((props: any) => ({
    open: props.open
}))`
    width: calc(100% - ${(props) => (props.open ? '240px' : '60px')});
    height: calc(100% - ${({ theme }) => theme.headerHeight});
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: ${({ theme }) => theme.headerHeight};
    margin-left: ${(props) => (props.open ? '240px' : '60px')};
    background: ${(props) => props.theme.background.primary};
    position: relative;
    transition: all 0.1s;
    @media only screen and (max-width: 1200px) {
        width: 100%;
        margin-left: 0;
        margin-top: ${({ theme }) => theme.headerHeight};
        background: ${(props) => props.theme.background.primary};
    }
    @media only screen and (max-width: 1000px) {
        height: calc(100% - ${({ theme }) => theme.headerHeight} - 45px);
    }
    @media (max-width: 800px) {
        width: unset;
        padding: 0 0.5rem;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
