import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class GameApi extends APIAbstract {
    // Get Game Provider Information
    getProviders(data) {
        return this.post('/api/game/getProviders', data);
    }

    //  ------------------------------------------------------------------

    // Game Relate Part
    getGames(data) {
        return this.post('/api/game/getGames', data);
    }
    // Get game by id
    getGamesById(gameId) {
        return this.post('/api/game/getGamesById', { gameId });
    }
    getGamesCategories(gameType) {
        return this.get('/api/game/getGamesCategories', { gameType });
    }
    openGame(data = {}) {
        return this.post('/api/game/openGame', data);
    }
    openSportsGame(data = {}) {
        return this.post('/api/game/openSportsGame', data);
    }
    openDemoGame(data) {
        return this.post('/api/game/openDemoGame', data);
    }

    //  ------------------------------------------------------------------

    // Game Bet History Part
    getGameHistory(data) {
        return this.post('/api/game/getGameHistory', data);
    }
    getSportHistory(data) {
        return this.post('/api/game/getSportHistory', data);
    }
    getBonusGameHistory(data) {
        return this.post('/api/game/getBonusGameHistory', data);
    }
    getProviderList() {
        return this.post('/api/game/getProviderList');
    }
}

const GameClass = new GameApi(`${Root.serverUrl}`);

export default GameClass;
