import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const Cookie = styled(Box)`
    position: absolute;
    z-index: 999999999;
    bottom: 0;
    background: ${({ theme }) => theme.background.secondary};
    width: calc(100% - 4rem);
    padding: 2rem 2rem 1rem 2rem;

    @media only screen and (max-width: 500px) {
        width: calc(100% - 2rem);
        padding: 1rem;
    }
`;

export const CookieLetter = styled(Typography)`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text};
    font-weight: 700;
    font-family: 'PT Sans' !important;

    span {
        color: ${({ theme }) => theme.primaryButton};
        cursor: pointer;
    }
`;

export const CookieAccept = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px;
    width: auto;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff !important;
    cursor: pointer;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
        font-size: 15px;
    }
    @media only screen and (max-width: 500px) {
        font-size: 14px;
    }
`;
