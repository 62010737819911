import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class TournamentApi extends APIAbstract {
    getTournaments(isActual = false) {
        return this.post('/api/tournament/getTournaments', { isActual });
    }

    getTournament(ID) {
        return this.post('/api/tournament/getTournament', { ID });
    }

    getTournamentByBonusId(bonusId) {
        return this.post('/api/tournament/getTournamentByBonusId', {
            bonusId
        });
    }

    getTournamentLeaderboard(IDTournament, limit, offset) {
        return this.post('/api/tournament/getTournamentLeaderboard', {
            IDTournament,
            offset,
            limit
        });
    }

    getTournamentCurrentUserLeaderboard(IDTournament, limit, offset) {
        return this.post('/api/tournament/getTournamentLeaderboard', {
            IDTournament,
            limit,
            offset
        });
    }

    subscribeToTournament(IDTournament) {
        return this.post('/api/tournament/subscribeToTournament', {
            IDTournament
        });
    }

    unsubscribeFromTournament(IDTournament) {
        return this.post('/api/tournament/unsubscribeFromTournament', {
            IDTournament
        });
    }

    getUserTournamentProgression(tournamentId) {
        return this.post('/api/tournament/getUserTournamentProgression', {
            tournamentId
        });
    }

    getTournamentGames(gameOptions, page = 0, perPage = 12) {
        return this.post('/api/tournament/getTournamentGames', { gameOptions, page, perPage });
    }

    getStaticTournamentGames(page = 0, perPage = 12, t_id) {
        return this.post('/api/tournament/getStaticTournamentGames', { page, perPage, t_id });
    }

    getTournamentUserBonuses() {
        return this.post('/api/tournament/getTournamentUserBonuses', {});
    }

    selectTournamentBonus(isActivate, IDBonus) {
        return this.post('/api/tournament/redeemTournamentBonus', {
            isActivate,
            IDBonus
        });
    }

    cancelTournamentBonus(IDBonus) {
        return this.post('/api/tournament/cancelTournamentBonus', {
            IDBonus
        });
    }

    getPastTournaments(limit: number) {
        return this.post('/api/tournament/getPastTournaments', { limit });
    }

    getPastTournament(tournamentId) {
        return this.post('/api/tournament/getPastTournament', {
            tournamentId
        });
    }
    getAllLocalTournament() {
        return this.post('/api/tournament/getAllLocalTournament', {});
    }

    getCurrentLocalTournament(data) {
        return this.post('/api/tournament/getCurrentLocalTournament', data);
    }
}

const TournamentClass = new TournamentApi(`${Root.serverUrl}`);

export default TournamentClass;
