import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    TournamentPrize,
    TournamentPrizePoolWrapper,
    TournamentPrizeTitle
} from '../style';

const TournamentResults = ({ tournament, tournamentResults }) => {
    const { t } = useTranslation();

    const winner = tournament.WinningSpread[tournamentResults.IDUserPlace.split('-')[0] - 1]

    switch (tournament.Target) {
        case 'bonus':
            return (
                <TournamentPrizePoolWrapper>
                    {
                        winner ? <>
                            <TournamentPrizeTitle>
                                {t('You won')}:&nbsp;&nbsp;
                            </TournamentPrizeTitle>
                            <TournamentPrize>
                                {
                                    tournamentResults.Win
                                        ? '€ ' + parseInt(tournamentResults.Win)
                                        : 'Processing'
                                }
                            </TournamentPrize>
                        </> : <></>
                    }
                </TournamentPrizePoolWrapper>
            );
        case 'loyalty': {
            return (
                <TournamentPrizePoolWrapper>
                    {
                        winner ? <>
                            <TournamentPrizeTitle>
                                {t('You won')}:&nbsp;&nbsp;
                            </TournamentPrizeTitle>
                            <TournamentPrize>
                                {' '}
                                {
                                    tournamentResults.Win
                                        ? parseInt(tournamentResults.Win) + ' loyalty pts'
                                        : 'Processing'
                                }
                            </TournamentPrize>
                        </> : <></>
                    }
                </TournamentPrizePoolWrapper>
            );
        }
        case 'balance':
        default: {
            return (
                <TournamentPrizePoolWrapper>
                    {
                        winner ? <>
                            <TournamentPrizeTitle>
                                {t('You won')}:&nbsp;&nbsp;
                            </TournamentPrizeTitle>
                            <TournamentPrize>
                                {' '}
                                {
                                    tournamentResults.Win
                                        ? '€ ' + parseInt(tournamentResults.Win)
                                        : 'Processing'
                                }
                            </TournamentPrize>
                        </> : <></>
                    }
                </TournamentPrizePoolWrapper>
            );
        }
    }
};

export default TournamentResults;
