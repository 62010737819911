import { FC } from 'react';
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import { Modal as ModalContent, ModalHeader, ModalLetter } from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../theme';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';

interface IProps {
    open: boolean;
    onClose: () => void;
}

const IosInstallModal: FC<IProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const theme = useTheme() as Theme;

    return (
        <Modal
            center
            open={open}
            onClose={onClose}
            styles={{
                modal: { background: theme.background.secondary },
                closeIcon: { fill: theme.text }
            }}
        >
            <ModalContent>
                <ModalHeader>{t('How to install?')}</ModalHeader>
                <img src="/assets/img/pwa_image.png" width={144} alt="" />
                <ModalLetter>
                    <span>{t('To install the App simply tap')}</span> <IosShareOutlinedIcon />
                </ModalLetter>
                <ModalLetter>
                    <span>{t('and then')} <b>{t('Add to Home Screen')}</b></span> <AddBoxOutlinedIcon />
                </ModalLetter>
            </ModalContent>
        </Modal>
    )
}

export default IosInstallModal;
