import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const LeftSidebar = styled(Box)`
    display: none;
    position: fixed;
    background: ${(props) => props.theme.background.primary};
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    box-shadow: 0 5px 3px rgb(0 0 0 / 40%);
    overflow-y: auto;
    z-index: 1000;
    padding: 2rem 0;
    @media only screen and (max-width: 1200px) {
        display: block;
        height: calc(100vh - ${({ theme }) => theme.headerHeight} - 2rem);
        top: ${({ theme }) => theme.headerHeight};
    }
`;

export const GameItemContent = styled(Grid)`
    display: flex;
    padding: 0 1rem 1rem 1rem;
`;

export const GameGridItem = styled(Grid)`
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 !important;
`;

export const GameItem = styled(Grid)`
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    border-radius: 10px;
    background: ${(props) => props.theme.background.secondary};
    width: 90px !important;
    height: 90px !important;
    img {
        width: 50px;
    }
`;

export const GameIcon = styled.i`
    color: #fa0;
    display: inline-block;
    font: normal normal normal 14px/1 mg_icons;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GameName = styled(Grid)`
    color: ${(props) =>
        props.theme.mode === 'light' ? props.theme.text : '#fa0'};
    font-size: 12px;
    font-weight: bold;
    font-family: League Spartan, Cyrillic !important;
    text-align: center !important;
`;

export const IntlContent = styled.div`
    display: none;
    justify-content: center;
    padding: 1rem 0;
    border: none;
    @media only screen and (max-width: 600px) {
        border-top: 1px solid #41444f;
        display: flex;
    }
`;
