import styled from 'styled-components';

export const TopId = styled.div.attrs((props: any) => ({
    open: props.open
}))`
    width: calc(100% - ${(props) => (props.open ? '240px' : '60px')});
    height: calc(100% - ${({ theme }) => theme.headerHeight});
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: ${({ theme }) => theme.headerHeight};
    margin-left: ${(props) => (props.open ? '240px' : '60px')};
    background: ${(props) => props.theme.background.primary};
    position: relative;
    transition: all 0.1s;
    &::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }
    @media only screen and (max-width: 1200px) {
        margin-left: 0;
        width: 100%;
    }
    @media only screen and (max-width: 1000px) {
        background: ${(props) => props.theme.background.secondary};
        height: calc(100% - 81px - 45px);
    }
`;
