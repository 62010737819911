import React from 'react';
import Media from 'react-media';
import Header from '../../component/Header';
import SportFooter from '../../component/Footer/SportFooter';
import BaseZustand from '../../../zustand/BaseZustand';
import Sidebar from '../../component/MobileSidebar';
import CoockiePage from '../../component/Footer/Coockie';
import { SportId } from './style';

const SportPageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { sidebar } = BaseZustand((state) => state);

    return (
        <>
            <Header page="sport" back={true} />
            <SportId id="top-id">
                {sidebar ? <Sidebar /> : <></>}
                {children}
            </SportId>
            <CoockiePage />
            <Media queries={{ default: '(min-width: 1200px)' }}>
                {(matches) => <>{matches.default && <SportFooter />}</>}
            </Media>
        </>
    );
};

export default SportPageLayout;
