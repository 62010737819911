import { useContext, useMemo } from 'react';
import { currentTimeFormatter } from '../heplers';
import { TournamentItemBtn } from '../style';
import { IUser } from '../../../types';
import AuthZustand from '../../../zustand/AuthZustand';
import { useTournamentStore } from '../../../zustand/TournamentZustand';
import { LoadingContext } from '../../../layout/context/loading';
import { useTranslation } from 'react-i18next';

const TournamentSubscribeButton = ({ tournament }) => {
    const now = new Date();
    const { t } = useTranslation();
    const { isAuth }: IUser | any = AuthZustand((state) => state);
    const {
        tournaments,
        getTournament,
        subscribeToTournament,
        unsubscribeFromTournament,
        getTournaments,
        getActualTournaments
    } = useTournamentStore((state) => state);

    const { showLoading, hideLoading } = useContext(LoadingContext);

    const subscribedTournamentId = useMemo(() => {
        const subscribedTournament = tournaments.find(
            (tournament) => tournament.Selected
        );

        return subscribedTournament ? subscribedTournament.ID : null;
    }, [tournaments]);

    if (now < currentTimeFormatter(tournament.Starts, false)) {
        return null;
    }

    const toggleTournamentSubscription = async (isSubscribe) => {
        showLoading();
        if (isSubscribe) {
            await subscribeToTournament(tournament.ID);
        } else {
            await unsubscribeFromTournament(tournament.ID);
        }
        await getTournament(tournament.ID);
        await getTournaments();
        await getActualTournaments();
        hideLoading();
    };

    if (tournament.Selected) {
        return (
            <TournamentItemBtn
                sx={{ backgroundColor: 'orange' }}
                variant="contained"
                onClick={() => toggleTournamentSubscription(false)}
            >
                {t('UNSUBSCRIBE')}
            </TournamentItemBtn>
        );
    }

    if (!isAuth) {
        return null;
    }

    if (subscribedTournamentId && subscribedTournamentId !== tournament.ID) {
        return null;
    }

    return (
        <TournamentItemBtn
            sx={{ backgroundColor: 'orange' }}
            variant="contained"
            onClick={() => toggleTournamentSubscription(true)}
        >
            {t('SUBSCRIBE')}
        </TournamentItemBtn>
    );
};

export default TournamentSubscribeButton;
