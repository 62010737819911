import styled from 'styled-components';

export const RegisterId = styled.div`
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: ${(props) => props.theme.background.primary};
    position: relative;
    z-index: 102;
`;
