import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

type TDimensions = {
    innerWidth: number;
    innerHeight: number;
    clientWidth: number;
    clientHeight: number;
};

const getDimensions = () => ({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    clientHeight: document.body.clientHeight,
    clientWidth: document.body.clientWidth
});

const useWindowDimensions = (): TDimensions => {
    const [dimensions, setDimensions] = useState(getDimensions());

    const _setDimensions = useMemo(
        () => (dim) => {
            setDimensions(dim);
        },
        []
    );

    /* eslint-disable-next-line */
    const debouncedSetDimensions = useCallback(debounce(_setDimensions, 100), [
        _setDimensions
    ]);

    const resizeListener = useRef<EventListenerOrEventListenerObject>();
    useEffect(() => {
        resizeListener.current = function handleResize() {
            setTimeout(() => debouncedSetDimensions(getDimensions()), 4);
        };
        window.addEventListener('resize', resizeListener.current);
        return () => {
            window.removeEventListener('resize', resizeListener.current);
        };
    }, [debouncedSetDimensions]);

    return dimensions;
};

export default useWindowDimensions;
