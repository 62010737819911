import {
    TournamentPrize,
    TournamentPrizePoolWrapper,
    TournamentPrizeTitle
} from '../style';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TournamentPrizePool = ({ tournament }) => {
    const { t } = useTranslation();
    switch (tournament.Target) {
        case 'bonus':
            return (
                <TournamentPrizePoolWrapper>
                    <TournamentPrizeTitle>
                        {t('BONUS PRIZE POOL')}:&nbsp;&nbsp;
                    </TournamentPrizeTitle>
                    <TournamentPrize>
                        &nbsp;
                        {'€ ' + tournament?.TotalFounds?.EUR}
                        {tournament.TotalFounds?.Currency?.FS
                            ? ` + ${tournament.TotalFounds?.Currency?.FS} Free rounds`
                            : ''}
                    </TournamentPrize>
                </TournamentPrizePoolWrapper>
            );
        case 'loyalty': {
            return (
                <TournamentPrizePoolWrapper>
                    <TournamentPrizeTitle>
                        {t('LOYALTY PRIZE POOL')}:&nbsp;&nbsp;
                    </TournamentPrizeTitle>
                    <TournamentPrize>
                        &nbsp;
                        {tournament.Value} {'loyalty pts'}
                    </TournamentPrize>
                </TournamentPrizePoolWrapper>
            );
        }
        case 'balance':
        default: {
            return (
                <TournamentPrizePoolWrapper>
                    <TournamentPrizeTitle>
                        {t('BALANCE PRIZE POOL')}:&nbsp;&nbsp;
                    </TournamentPrizeTitle>
                    <TournamentPrize>
                        &nbsp;
                        {'€ ' + tournament?.TotalFounds?.EUR}
                    </TournamentPrize>
                </TournamentPrizePoolWrapper>
            );
        }
    }
};

export default TournamentPrizePool;
