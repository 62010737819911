import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTournamentStore } from '../../../zustand/TournamentZustand';
import { TournamentItemTitle, TournamentPageContainer } from '../style';
import {
    CurrentTournamentInfoTextWrapper,
    CurrentTournamentInfoWrapper,
    CurrentTournamentWrapper
} from '../CurrentTournament/style';
import { RandomTournamentHeaderBtn } from '../../dashboard/RandomTournament/style';
import TournamentGames from '../CurrentTournament/TournamentGame';
import TournamentBonusCard from '../TournamentBonusCard';
import useTournamentGamesInfiniteScroll from '../../../hooks/useTournamentGames';

const BonusTournamentDescription = () => {
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const {
        activeBonusTournament: tournament,
        tournamentBonuses,
        getTournamentUserBonuses,
        getTournamentByBonusId,
        getTournamentGames
    } = useTournamentStore((state) => state);

    useEffect(() => {
        getTournamentUserBonuses();
    }, []);

    const {
        hasMoreGames,
        setCurrentPage,
        currentTournamentGames
    } = useTournamentGamesInfiniteScroll({
        tournament,
        getTournamentGames
    });

    useEffect(() => {
        if (tournamentBonuses.length > 0) {
            const activeBonus = tournamentBonuses.find(
                (tournamentBonuses) => tournamentBonuses.Active >= 0
            );
            if (activeBonus) {
                getTournamentByBonusId(activeBonus.ID);
            }
        }
    }, [tournamentBonuses]);

    const handleBackToTournamentList = () => {
        history.push('/tournament');
    };

    if (!tournament) {
        return (
            <TournamentPageContainer maxWidth="xl">
                <CurrentTournamentWrapper>
                    <TournamentItemTitle>
                        {t('Bonus description missing')}
                    </TournamentItemTitle>
                    <span>
                        {t(
                            'We have a problems on server. Please wait when we fix it!'
                        )}
                    </span>
                </CurrentTournamentWrapper>
            </TournamentPageContainer>
        );
    }

    return (
        <TournamentPageContainer maxWidth="xl">
            <RandomTournamentHeaderBtn
                variant="contained"
                onClick={handleBackToTournamentList}
            >
                {t('BACK')}
            </RandomTournamentHeaderBtn>
            <CurrentTournamentWrapper>
                {tournament
                    ? tournamentBonuses.map((bonus) => (
                        <TournamentBonusCard
                            key={bonus.ID}
                            tournament={tournament}
                            bonus={bonus}
                        />
                    ))
                    : null}

                <CurrentTournamentInfoWrapper>
                    <CurrentTournamentInfoTextWrapper>
                        <TournamentItemTitle>
                            {tournament.Description[i18n.language]
                                ? tournament.Description[i18n.language]
                                : tournament.Description.en}
                        </TournamentItemTitle>
                    </CurrentTournamentInfoTextWrapper>
                </CurrentTournamentInfoWrapper>
                <TournamentGames
                    tournamentGames={currentTournamentGames}
                    title={'Tournament games'}
                    setCurrentPage={setCurrentPage}
                    hasMoreGames={hasMoreGames}
                />
            </CurrentTournamentWrapper>
        </TournamentPageContainer>
    );
};

export default BonusTournamentDescription;
