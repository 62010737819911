import { DefaultTheme } from 'styled-components';

export interface Theme extends DefaultTheme {
    mode: string;
    headerHeight?: string;
    background: {
        primary: string;
        secondary: string;
    };
    primaryButton: string;
    text: string;
    borderColor: string;
}

export interface IThemeList {
    id: number;
    name: string;
    theme: Theme;
}

export const themeList: IThemeList[] = [
    {
        id: 0,
        name: 'Purple theme',
        theme: {
            mode: 'purple',
            background: {
                primary: 'rgb(62, 10, 99)',
                secondary: 'rgb(85, 16, 133)'
            },
            primaryButton: 'rgb(242, 173, 61)',
            text: 'rgb(255, 255, 255)',
            borderColor: 'rgb(216, 216, 216)'
        }
    },
    {
        id: 1,
        name: 'Blue theme',
        theme: {
            mode: 'blue',
            background: {
                primary: 'rgb(27, 26, 82)',
                secondary: 'rgb(40, 39, 121)'
            },
            primaryButton: 'rgb(39, 34, 219)',
            text: 'rgb(255, 255, 255)',
            borderColor: 'rgb(216, 216, 216)'
        }
    },
    {
        id: 2,
        name: 'White theme',
        theme: {
            mode: 'white',
            background: {
                primary: 'rgb(255, 255, 255)',
                secondary: 'rgb(243, 243, 243)'
            },
            primaryButton: 'rgb(242, 173, 61)',
            text: 'rgb(0, 0, 0)',
            borderColor: 'rgb(0, 0, 0)'
        }
    },
    {
        id: 3,
        name: 'Green theme',
        theme: {
            mode: 'green',
            background: {
                primary: 'rgb(20, 92, 101)',
                secondary: 'rgb(25, 118, 129)'
            },
            primaryButton: 'rgb(242, 173, 61)',
            text: 'rgb(255, 255, 255)',
            borderColor: 'rgb(216, 216, 216)'
        }
    },
    {
        id: 4,
        name: 'Black theme',
        theme: {
            mode: 'black',
            background: {
                primary: 'rgb(20, 20, 20)',
                secondary: 'rgb(0, 0, 0)'
            },
            primaryButton: 'rgb(85, 213, 29)',
            text: 'rgb(85, 213, 29)',
            borderColor: 'rgb(85, 213, 29)'
        }
    },
    {
        id: 5,
        name: 'Dark theme',
        theme: {
            mode: 'dark',
            background: {
                primary: 'rgb(32, 35, 48)',
                secondary: 'rgb(39, 42, 55)'
            },
            primaryButton: 'rgb(255, 170, 0)',
            text: 'rgb(216, 216, 216)',
            borderColor: 'rgb(216, 216, 216)'
        }
    }
];
