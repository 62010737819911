import React, { useEffect, useContext } from 'react';
import Media from 'react-media';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Container from '@mui/material/Container';

import { Root } from '../../../config/config';

import BaseZustand from '../../../zustand/BaseZustand';
import ConifgZustand from '../../../zustand/ConifgZustand';
import AuthZustand from '../../../zustand/AuthZustand';

import Avatar from '../Header/Avatar';
import Intl from '../Header/Intl';
import Notification from '../Header/Notification';
import Balanace from '../Header/Balance';

import Login from '../../../pages/auth/auth/login';
import Register from '../../../pages/auth/auth/register/registermodal';
import ForgotPassword from '../../../pages/auth/auth/forgotpass';
import VerifyEmailModal from '../../../pages/auth/verify/verify_email';
import VerifySMSModal from '../../../pages/auth/verify/verify_sms';
import GameModal from '../../../pages/game/GameOpen/modal';

import {
    AppBarWrapp,
    MainHeader,
    HeaderBox,
    Logo,
    HeaderMenuBox,
    DehazeWrapp,
    AuthBtn,
    LoginBtn,
    RegisterBtn,
    IntlContent,
    BackBtn,
    BackIcon
} from './style';
import ThemeSwitch from '../../../theme/ThemeSwitch';
import { useTheme } from 'styled-components';
import { Theme } from '../../../theme';
import Praxis from '../../../pages/userDashboard/wallet/deposit/praxis';
import AppInstall from '../../../components/AppInstall';
import { LoadingContext } from '../../../layout/context/loading';

const Header = ({ page = '', back = false }) => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();
    const history = useHistory();
    const { isAuth, logoImage, setUpSession } = AuthZustand((state) => state);
    const { isDesktopSidebar, setAuthModal, setSidebar, sidebar, pushUrl } = BaseZustand(
        (state) => state
    );
    const { getUrlParseData } = ConifgZustand((state) => state);
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const urlParse = async (token) => {
        showLoading();
        const data = await getUrlParseData({ token });
        hideLoading();

        if (data) {
            if (data.type === 'activateaccount') {
                if (data.value) {
                    history.push('/activate/success', data);
                } else {
                    history.push('/activate/failure', data);
                }
            }
        }
    };

    useEffect(() => {
        const stateData = queryString.parse(history.location.search);

        if (stateData.token) {
            urlParse(stateData.token);
        }

        if (stateData.uri === 'signup' && !isAuth) {
            setAuthModal({ login: false, register: true }, history);
        }

        if (stateData.uri === 'signin' && !isAuth) {
            setAuthModal({ login: true, register: false }, history);
        }

        if (stateData.session) {
            const session = String(stateData.session).split(' ').join('+')
            setUpSession(session)
        }

        // eslint-disable-next-line
    }, []);

    return (
        <AppBarWrapp position="fixed" open={isDesktopSidebar} page={page}>
            <AppInstall page={page} />
            <Container maxWidth="xl">
                <MainHeader>
                    <Media
                        queries={{
                            large: '(min-width: 1201px)',
                            small: '(max-width: 1200px)'
                        }}
                    >
                        {(matches) => (
                            <>
                                {matches.large && (
                                    <>
                                        {
                                            back ? (
                                                <BackBtn>
                                                    <BackIcon
                                                        variant="contained"
                                                        onClick={() => pushUrl(history, '/home')}
                                                    >
                                                        {t('BACK TO HOME')}
                                                    </BackIcon>
                                                </BackBtn>
                                            ) : (
                                                <></>
                                            )
                                            // <BackIcon>
                                            //     <IconButton onClick={() => history.push('/home')}>
                                            //         <ArrowBack />
                                            //     </IconButton>
                                            // </BackIcon> : <></>
                                        }
                                        <HeaderBox>
                                            <Logo
                                                src={`${Root.adminWebsiteUrl}${logoImage}`}
                                                onClick={() => pushUrl(history, '/')}
                                                alt=""
                                            />
                                        </HeaderBox>
                                    </>
                                )}
                                {matches.small && (
                                    <HeaderMenuBox>
                                        <DehazeWrapp
                                            onClick={() => setSidebar(!sidebar)}
                                        />
                                    </HeaderMenuBox>
                                )}
                            </>
                        )}
                    </Media>
                    <AuthBtn>
                        {!isAuth ? (
                            <>
                                <LoginBtn
                                    sx={{ color: theme.text }}
                                    variant="contained"
                                    onClick={() =>
                                        setAuthModal(
                                            { login: true, register: false },
                                            history
                                        )
                                    }
                                >
                                    {t('LOGIN')}
                                </LoginBtn>
                                <RegisterBtn
                                    variant="contained"
                                    onClick={() =>
                                        setAuthModal(
                                            { login: false, register: true },
                                            history
                                        )
                                    }
                                >
                                    {t('REGISTER')}
                                </RegisterBtn>
                            </>
                        ) : (
                            <>
                                {history.location.pathname !== '/sports' && (<Notification />)}
                                <Balanace />
                                <Avatar />
                            </>
                        )}
                        <IntlContent>
                            <Intl />
                            <ThemeSwitch />
                        </IntlContent>
                    </AuthBtn>
                </MainHeader>
            </Container>
            <Login />
            <Register />
            <GameModal />
            <VerifyEmailModal />
            <VerifySMSModal />
            <ForgotPassword />
            <Praxis />
        </AppBarWrapp>
    );
};

export default Header;
