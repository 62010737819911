import { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';

// import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { LoadingContext } from '../../../../layout/context/loading';

import AuthZustand from '../../../../zustand/AuthZustand';
import BaseZustand from '../../../../zustand/BaseZustand';
import { Root } from '../../../../config/config';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalTheme } from '@web3modal/ethers/react'
import {
    AuthContent,
    Letter,
    // LetterInput,
    Captcha,
    AccessButton,
    Redirect,
    FooterText,
    PasswordInput
} from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';

const Login = () => {
    const { open } = useWeb3Modal();
    const theme = useTheme() as Theme;
    const { t } = useTranslation();
    const history = useHistory();
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const { address,isConnected } = useWeb3ModalAccount();
    const { setThemeVariables } = useWeb3ModalTheme()
    useEffect(() => {
        setThemeVariables({
            '--w3m-z-index': 10003
        })
    }, [])

    const { authModal, setAuthModal, setForgotModal } = BaseZustand(
        (state) => state
    );
    const { isAuth, requestAttempt, userLogin, userLoginWitWallet } = AuthZustand((state) => state);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [capcha, setCapcha] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);
    const [captchaStatus, setCaptchaStatus] = useState<ReCAPTCHA>();
    const [logFlag, setLogFlag] = useState(false)
    useEffect(() => {
        const submit = async () => {
            if (isConnected && address && logFlag) {
                showLoading();
                await userLoginWitWallet({address})
                setLogFlag(false)
                hideLoading();
            }
        }
        submit()
    }, [isConnected])
    
    useEffect(() => {
        const stateData = queryString.parse(history.location.search);

        if (stateData.uri === 'signin' && !isAuth && stateData.email) {
            setEmail(stateData.email.toString());
        }

        setTimeout(() => {
            document.getElementById('init-loading').style.display = 'none';
        }, 2000);

        // eslint-disable-next-line
    }, []);

    const login = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.error(t('Please input email or username'));
            return;
        } else if (!password) {
            toast.error(t('Please input correct password'));
            return;
        }
        showLoading();
        await userLogin({ email: email.split(' ').join(''), password, capcha });
        
        if (requestAttempt.login) {
            captchaStatus.reset()
        }
        
        hideLoading();
    };

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleMouseDownPassword1 = (event) => event.preventDefault();

    return (
        <Modal
            center={true}
            open={authModal && authModal.login}
            onClose={() =>
                setAuthModal({ register: false, login: false }, history)
            }
            styles={{
                modal: { background: theme.background.secondary },
                closeIcon: { fill: theme.text }
            }}
        >
            <AuthContent>
                <Letter>{t('Sign In with your account')}</Letter>
                <form onSubmit={(e) => login(e)}>
                    <PasswordInput
                        label={t('Username')}
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                    />
                    <PasswordInput
                        type={showPassword1 ? 'text' : 'password'}
                        label={t('Password')}
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                        // sx={PasswordInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        onMouseDown={handleMouseDownPassword1}
                                        edge="end"
                                    >
                                        {showPassword1 ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {requestAttempt.login && (
                        <Captcha>
                            <ReCAPTCHA
                                ref={(r) => setCaptchaStatus(r)}
                                sitekey={Root.siteKey}
                                onChange={(e) => setCapcha(e)}
                            />
                        </Captcha>
                    )}
                    <AccessButton variant="outlined" type="submit">
                        {t('LOGIN')}
                    </AccessButton>
                    <AccessButton variant="outlined" onClick={() => {
                        open()
                        setLogFlag(true)
                    }} >
                        {t('Login with wallet')}
                    </AccessButton>
                    <Redirect
                        onClick={() => {
                            setAuthModal(
                                { register: false, login: false },
                                history
                            );
                            setForgotModal(true);
                        }}
                    >
                        {t('Forgot password?')}
                    </Redirect>
                    <FooterText>
                        {t("Don't you have account?")} &nbsp;
                        <Redirect
                            onClick={() =>
                                setAuthModal(
                                    { register: true, login: false },
                                    history
                                )
                            }
                        >
                            {t('Create now!')}
                        </Redirect>
                    </FooterText>
                </form>
            </AuthContent>
        </Modal>
    );
};

export default Login;
