import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class WalletApi extends APIAbstract {
    // This is for show available Gateway show for this user
    getGatewayList(data) {
        return this.post('/api/wallet/getGatewayList', data);
    }

    // Transaction Detail Page
    getAllGatewayList() {
        return this.post('/api/wallet/getAllGatewayList');
    }
    getTransactionHistory(data) {
        return this.post('/api/wallet/getTransactionHistory', data);
    }
    cancelWithdraw(data) {
        return this.post('/api/wallet/cancelWithdraw', data);
    }

    //  ------------------------------------------------------------------

    // Get Saved Card Info
    getCardData() {
        return this.post('/api/wallet/getCardData');
    }

    // Player Transaction Excute
    getIkajoLaunchUrl(data: object) {
        return this.post('/api/wallet/getIkajoLaunchUrl', data);
    }
    getaDeposit(data: object) {
        return this.post('/api/wallet/getaDeposit', data);
    }
    getWithdraw(data: object) {
        return this.post('/api/wallet/getWithdraw', data);
    }
    getSkrillLaunchUrl(data) {
        return this.post('/api/wallet/getSkrillLaunchUrl', data);
    }
    skrillWithdraw(data) {
        return this.post('/api/wallet/skrillWithdraw', data);
    }
    coinTakeWallet() {
        return this.post('/api/wallet/coinTakeWallet');
    }
    coinWithdraw(data) {
        return this.post('/api/wallet/coinWithdraw', data);
    }
    payRetailerDeposit(data: object) {
        return this.post('/api/wallet/payRetailerDeposit', data);
    }
    payRetailerWithdraw(data) {
        return this.post('/api/wallet/payRetailerWithdraw', data);
    }
    bankWithdraw(data) {
        return this.post('/api/wallet/bankWithdraw', data);
    }
    getOnramperLaunchUrl(data) {
        return this.post('/api/wallet/getOnramperLaunchUrl', data);
    }
    getPAdmitLaunchUrl(data: object) {
        return this.post('/api/wallet/getPAdmitLaunchUrl', data);
    }
    sendPAdmitWithdraw(data: object) {
        return this.post('/api/wallet/sendPAdmitWithdraw', data);
    }
    getGumballLaunchUrl(data: object) {
        return this.post('/api/wallet/getGumballLaunchUrl', data);
    }
    addGumballCard(data: object) {
        return this.post('/api/wallet/addGumballCard', data);
    }
    getGumBallCards(data: object) {
        return this.post('/api/wallet/getGumBallCards', data);
    }
    getJetonLaunchUrl(data: object) {
        return this.post('/api/wallet/getJetonLaunchUrl', data);
    }
    getCoryLaunchUrl(data: object) {
        return this.post('/api/wallet/getCoryLaunchUrl', data);
    }
    jetonWithdraw(data: object) {
        return this.post('/api/wallet/jetonWithdraw', data);
    }
    coryWithdraw(data: object) {
        return this.post('/api/wallet/coryWithdraw', data);
    }

    getPraxisUrl(data: object) {
        return this.post('/api/wallet/getPraxisUrl', data);
    }
}

const WalletClass = new WalletApi(`${Root.serverUrl}`);

export default WalletClass;
