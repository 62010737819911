import styled from 'styled-components';

export const TournamentBonusTitle = styled.div`
    margin: 35px 0px 5px;
    color: orange;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    font-family: 'Big Shoulders Display', sans-serif;
    -webkit-text-stroke: 1px rgb(255, 255, 255);
`;

export const TournamentBonusDescription = styled.div`
    width: 100%;
    color: white;
    font-size: 30px;
    font-weight: normal;
    font-family: 'Big Shoulders Display', sans-serif;
    z-index: 10;
    margin: 30px 0 0;
`;

export const TournamentBonusExpire = styled.div`
    color: lightgrey;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    font-family: 'Big Shoulders Display', sans-serif;
`;

export const TournamentBonusGameWrapper = styled.div.attrs(
    (props: { showMore: boolean }) => ({
        showMore: props.showMore
    })
)`
    width: 100%;
    height: ${({ showMore }) => (showMore === true ? '500px' : '0px')};
    transform: ${({ showMore }) =>
        showMore === true ? 'scaleY(1)' : 'scaleY(0)'};
    transform-origin: top;
    transition: all 0.26s ease;
    overflow: hidden;
    @media (max-width: 500px) {
        height: ${({ showMore }) => (showMore === true ? '1055px' : '0px')};
    }
`;
