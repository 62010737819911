import React from 'react';
import Media from 'react-media';
import Header from '../../component/Header';
import BaseZustand from '../../../zustand/BaseZustand';
import Sidebar from '../../component/MobileSidebar';
import CoockiePage from '../../component/Footer/Coockie';
import MobileFooterPage from '../../component/Footer/MobileFooter';
import { TopId, ProfileLayout } from './style';

const ProfilePageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { sidebar } = BaseZustand((state) => state);

    return (
        <>
            <TopId id="top-id">
                <Header back={true} />
                {sidebar ? <Sidebar /> : <></>}
                <ProfileLayout>{children}</ProfileLayout>
                <Media queries={{ small: '(max-width: 1000px)' }}>
                    {(matches) => <>{matches.small && <MobileFooterPage />}</>}
                </Media>
            </TopId>
            <CoockiePage />
        </>
    );
};

export default ProfilePageLayout;
