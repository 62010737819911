import React from 'react';
import Media from 'react-media';
import Header from '../../component/Header';
import Footer from '../../component/Footer/MainFooter';
import BaseZustand from '../../../zustand/BaseZustand';
import Sidebar from '../../component/MobileSidebar';
import CoockiePage from '../../component/Footer/Coockie';
import MobileFooterPage from '../../component/Footer/MobileFooter';
import MainSidebar from '../../component/MainSidebar';
import { TopId } from './style';

const GameLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { sidebar, isDesktopSidebar, gamemode } = BaseZustand(
        (state) => state
    );

    return (
        <>
            {gamemode ? <></> : <MainSidebar />}
            <TopId id="top-id" open={isDesktopSidebar}>
                {sidebar ? <Sidebar /> : <></>}
                <Header />
                {children}
                <Footer />
                <Media queries={{ small: '(max-width: 1000px)' }}>
                    {(matches) => <>{matches.small && <MobileFooterPage />}</>}
                </Media>
            </TopId>
            <CoockiePage />
        </>
    );
};

export default GameLayout;
