import styled from 'styled-components';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Countdown from 'react-countdown';
import TournamentPageTimer from './TournamentTimer';

export const RegisterBtn1 = styled(Button)`
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px !important;
    width: 100%;
    cursor: pointer;
    background-color: #fa0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
        font-size: 15px !important;
    }
    @media only screen and (max-width: 500px) {
        font-size: 12px !important;
        margin-right: 0.5rem;
    }
`;

export const TournamentPageContainer = styled(Container)`
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 50px auto 50px;
    text-align: center;

    @media (max-width: 769px) {
        padding: 0;
    }
`;
export const TournamentPageTitle = styled.h1`
    font-family: 'Big Shoulders Display', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 44px;
    color: ${(props) => props.theme.text};
`;
export const TournamentPageSubtitle = styled.h3`
    font-family: 'Big Shoulders Display', sans-serif;
    color: rgb(216, 216, 216);
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
`;
export const TournamentCurrentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
`;

export const TournamentImageContainer = styled(Box).attrs(
    (props: { image: string }) => ({
        image: props.image
    })
)`
    background: url(${(props) => props.image});
    background-position: right;
    background-size: 100% 100% ;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s;
    }
`;

export const TournamentComingWrapper = styled(TournamentCurrentWrapper)`
    flex-direction: column;
    justify-content: space-between;
`;

export const TournamentCurrentItem = styled.div`
    width: 100%;
    min-height: 365px;
    background: #272a37;
    border: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0;
    position: relative;
    // &::after {
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background: rgb(0, 0, 0);
    //     background: linear-gradient(
    //         90deg,
    //         rgba(0, 0, 0, 1) 17%,
    //         rgba(255, 255, 255, 0) 55%
    //     );
    // }
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const TournamentItemTextWrapper = styled.div`
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    margin: 20px;
    z-index: 10;
    @media (max-width: 800px) {
        width: unset;
    }
`;

export const TournamentItemTitle = styled.p`
    margin: 15px 0 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: orange;
    font-family: 'Big Shoulders Display', sans-serif;
    z-index: 1;
`;

export const TournamentItemProvider = styled.p`
    margin: 15px 0 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: orange;
    font-family: 'Big Shoulders Display', sans-serif;
    z-index: 1;
`;

export const TournamentTimersTitle = styled.p`
    margin: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    font-family: 'Big Shoulders Display', sans-serif;
    z-index: 1;
    min-width: 125px;
    text-align: center;
    color: #aaa;
    @media (max-width: 800px) {
        margin: 15px !important;
    }
`;

export const TournamentPrizeWrapper = styled.div`
    margin: 0 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 1300px) {
        //    align-items: center
    }
`;

export const TournamentTimerContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > P {
        margin-top: 0;
    }
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const TournamentPrizePoolWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const TournamentPrizeTitle = styled.h3`
    font-family: 'Big Shoulders Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    color: #cfd3e4;
    text-transform: uppercase;
`;

export const TournamentPrize = styled.p`
    font-family: 'Big Shoulders Display', sans-serif;
    margin: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 31px;
    color: orange;
    -webkit-text-stroke: 1px #fff;
`;

export const TournamentItemBtnWrapper = styled.div`
    width: 100%;
    max-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  
    @media(max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
`;

export const CurrentTournamentItemBtnWrapper = styled.div`
    margin: 60px 15px 15px;
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: center;
`;

export const TournamentItemBtn = styled(RegisterBtn1).attrs((props) => ({
    variant: props.variant
}))`
    color: ${({ variant }) =>
        variant === 'contained' ? 'white !important' : 'orange !important'};
    background: ${({ variant, theme }) =>
        variant === 'contained' ? theme.primaryButton : null} !important;
    font-size: 12px !important;
    white-space: nowrap;
    margin-right: 0;
    width: fit-content;

    &:hover {
        background: ${({ variant, theme }) =>
            variant === 'contained' ? theme.primaryButton : null};
    }
    @media (max-width: 600px) {
        font-size: 10px !important;
    }
    @media (max-width: 550px) {
        font-size: 12px !important;
    }
    a {
        color: white;
        text-decoration: none;
    }
`;

export const TournamentTimer = styled(Countdown)`
    font-family: 'Big Shoulders Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: #cfd3e4;
`;

export const TournamentComingItemWrapper = styled.div.attrs(
    (props: { isColumn: boolean }) => ({
        isColumn: props.isColumn
    })
)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: ${(props) => (props.isColumn ? 'wrap' : 'no-wrap')};
    position: relative;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const TournamentComingItem = styled(TournamentCurrentItem).attrs(
    (props: any) => ({
        fullSize: props.fullSize,
        isStatic: props?.isStatic
    })
)`
    width: ${(props) => (props.fullSize === true ? '100%' : '49%')};
    overflow: hidden;
    //z-index: 0;
    ${(props) => props.isStatic ? 'height: 450px' : 'min-height: 100%'};
    @media (max-width: 800px) {
        flex-direction: ${(props) => props.isStatic ? 'row' : 'column'};
        align-items: center;
        width: 100%;
    }
`;

export const TournamentList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0 10px;
`;

export const TournamentListItem = styled.div`
    width: 100%;
    padding: 10px;
    background: #272a37;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.12);
    font-family: 'Big Shoulders Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #cfd3e4;
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`;

export const TournamentListItemWithImage = styled.div`
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & p {
        margin: 0 25px;
    }
    @media (max-width: 800px) {
        padding: 20px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        & p {
            margin: 25px 0 0;
            font-size: 24px;
        }
    }
`;

export const TournamentListItemImageWrapper = styled.div`
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid orange;
    overflow: hidden;
    & img {
        width: 100%;
        min-width: 200px;
        object-position: center;
    }
`;

export const TournamentListItemTimesWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
        padding: 0 20px;
    }
`;

export const TournamentListItemTimes = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 15px;
    flex-direction: row;
    @media (max-width: 550px) {
        flex-wrap: wrap;
        margin: 15px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`;

export const TournamentItemButtonWrapper = styled.div`
    width: 100%;
    max-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
        padding: 20px;
    }
`;

export const TournamentListItemButtonWrapper = styled(
    TournamentItemButtonWrapper
)`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 250px;
`;

export const TournamentNameWrapper = styled.div`
    margin: 20px 0;
    p {
        color: orange;
        //line-height: 25px;
        text-transform: uppercase;
        font-size: 56px;
        -webkit-text-stroke: 1px #fff;
    }
`;

export const TournamentOtherTimer = styled(TournamentPageTimer)`
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const TournamentWiningWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span {
        font-family: 'Big Shoulders Display', sans-serif;
        letter-spacing: 2px;
        font-size: 30px;
        color: #8a8989;
    }
`;
