import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import BaseZustand from '../../../../zustand/BaseZustand';
import RegisterContent from './registercontent';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';

const RegisterModal = () => {
    const theme = useTheme() as Theme;
    const history = useHistory();
    const { authModal, setAuthModal } = BaseZustand((state) => state);

    return (
        <Modal
            center={true}
            open={window.innerWidth > 768 && authModal.register}
            onClose={() =>
                setAuthModal({ register: false, login: false }, history)
            }
            styles={{
                modal: { background: theme.background.secondary },
                closeIcon: { fill: theme.text }
            }}
        >
            <RegisterContent />
        </Modal>
    );
};

export default RegisterModal;
