import React, { useEffect } from 'react';
import * as S from '../style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTournamentStore } from '../../../../../zustand/TournamentZustand';
import TournamentLeaderBoard from '../../../../Tournament/CurrentTournament/TournamentLeaderBoard';

const TournamentLeaderboardWidget = () => {
    const {
        tournaments,
        tournamentLeaderboard,
        getTournamentLeaderboard,
        userProgression,
        getUserTournamentProgression
    } = useTournamentStore((state) => state);

    let tournament = null;

    tournament = tournaments.find((item) => item.Selected > 0);

    useEffect(() => {
        if (tournament) {
            getTournamentLeaderboard(tournament.ID, 5, 0);
            getUserTournamentProgression(tournament.ID);
        }
    }, [tournament]);

    return (
        <>
            {tournament && tournamentLeaderboard.length > 0 ? (
                <S.MostPlayedGamesPopup>
                    <S.MostPlayedGamesPopupHeader
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    >
                        <S.MostPlayedGamesTitle>
                            Leaderboard
                        </S.MostPlayedGamesTitle>
                    </S.MostPlayedGamesPopupHeader>
                    <S.MostPlayedGamesPopupContent>
                        <TournamentLeaderBoard
                            data={tournamentLeaderboard}
                            title={''}
                            showPlaceColor={true}
                            currentUserProgression={userProgression}
                        />
                    </S.MostPlayedGamesPopupContent>
                </S.MostPlayedGamesPopup>
            ) : null}
        </>
    );
};

export default TournamentLeaderboardWidget;
