import styled from 'styled-components';
import Box from '@mui/material/Box';

export const SportFooter = styled(Box)({
    position: 'absolute',
    zIndex: '999999999',
    bottom: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

export const FooterToggle = styled(Box)({
    background: 'rgb(32, 35, 48) !important',
    width: '50px',
    left: 'calc(50vw - 25px)',
    display: 'flex',
    justifyContent: 'center',
    height: '20px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    border: '1px solid rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.5)',
    cursor: 'pointer',
    '&:hover': {
        color: 'rgba(255, 255, 255, 1)'
    }
});
