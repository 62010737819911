import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './AppRouter';
import { LoadingProvider } from './layout/context/loading';
import AuthProvider from './layout/context/auth';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import Contextloading from './pages/base/loadingComponent';

import './config/i18n';
import './config/web3wallet'
import './assets/index.scss';
import 'swiper/css/bundle';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider } from './theme/ThemeProvider';
import { testWebpSupport } from './helpers';
import GlobalStyles from './components/GlobalStyles';

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <React.StrictMode>
        <LoadingProvider>
            <AuthProvider>
                <ThemeProvider>
                    <StyledEngineProvider injectFirst>
                        <ToastContainer />
                        <Contextloading />
                        <App />
                        <GlobalStyles />
                    </StyledEngineProvider>
                </ThemeProvider>
            </AuthProvider>
        </LoadingProvider>
    </React.StrictMode>
);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
}

reportWebVitals();
testWebpSupport();
