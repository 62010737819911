import { FC, useContext, useEffect, useState } from 'react';
import { AppInstallCloseButtonWrapper, AppInstallWrapper, AppInstallContent } from './style';
import Close from '@mui/icons-material/Close';
import { RegisterBtn } from '../../layout/component/Header/style';
import { ThemeContext } from '../../context/ThemeContext';
import IosInstallModal from './iosInstallModal';

const APP_INSTALL_HIDDEN_KEY = 'appInstallHidden';

interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;

    prompt(): Promise<void>;
}

interface IProps {
    page: string;
}

const AppInstall: FC<IProps> = ({ page }) => {
    const appInstallHidden = sessionStorage.getItem(APP_INSTALL_HIDDEN_KEY);
    const [isShown, setShown] = useState(false);
    const [isIosModalOpen, setIosModalOpen] = useState(false);
    const { setHeaderHeight } = useContext(ThemeContext);
    const isOpenedInFullScreen = (navigator as any).standalone === true || window.matchMedia('(display-mode: standalone)').matches;
    const isSafariBrowser = navigator.userAgent.match(/(safari)/i) && !navigator.userAgent.match(/chrome|chromium|crios/i);
    const [beforeInstallEvent, setBeforeInstallEvent] = useState<IBeforeInstallPromptEvent>((window as any).beforeInstallEvent);

    useEffect(() => {
        if (beforeInstallEvent) {
            return;
        }
        const eventHandler = (event: IBeforeInstallPromptEvent) => {
            if (event) {
                event.preventDefault();
                setBeforeInstallEvent(event);
            }
        }

        window.addEventListener('beforeinstallprompt', eventHandler);

        return () => window.removeEventListener('beforeinstallprompt', eventHandler);
    }, []);

    useEffect(() => {
        if ((beforeInstallEvent || isSafariBrowser) && !appInstallHidden && !isOpenedInFullScreen) {
            setShown(true);
        }

        const handleUserChoice = async () => {
            if (beforeInstallEvent) {
                const result = await beforeInstallEvent.userChoice;
                if (result?.outcome === 'accepted') {
                    setShown(false);
                }
            }
        }
        handleUserChoice();
    }, [beforeInstallEvent]);

    useEffect(() => {
        setHeaderHeight(isShown ? '128px' : '64px');
    }, [isShown]);

    const handleCloseSection = () => {
        sessionStorage.setItem(APP_INSTALL_HIDDEN_KEY, 'true');
        setShown(false);
    }

    const handlePromptInstall = () => {
        if (isSafariBrowser) {
            return setIosModalOpen(true);
        }
        beforeInstallEvent.prompt();
    }

    if (!isShown) {
        return null;
    }

    return (
        <AppInstallWrapper page={page}>
            <AppInstallContent>
                <span>Install and play</span>
                <RegisterBtn onClick={handlePromptInstall}>
                    Install app
                </RegisterBtn>
            </AppInstallContent>
            <AppInstallCloseButtonWrapper>
                <Close onClick={handleCloseSection} />
            </AppInstallCloseButtonWrapper>
            <IosInstallModal
                open={isIosModalOpen}
                onClose={() => setIosModalOpen(false)}
            />
        </AppInstallWrapper>
    )
}

export default AppInstall;
