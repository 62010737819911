import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { Modal } from 'react-responsive-modal';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';

import { LoadingContext } from '../../../layout/context/loading';
import ProfileZustand from '../../../zustand/ProfileZustand';
import * as S from '../style';

const Register = () => {
    const { t } = useTranslation();

    const history = useHistory();
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const { sendVerifyEmailCode } = ProfileZustand((state) => state);

    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [modal, setModal] = useState(false);

    useEffect(() => {
        const stateData = queryString.parse(history.location.search);
        if (stateData.uri === 'verifyemail') {
            setEmail(String(stateData.email));
            setModal(true);
        }
        //  eslint-disable-next-line
    }, [history.location.search]);

    const register = async () => {
        if (code && String(code).length === 6) {
            showLoading();
            await sendVerifyEmailCode({ email, code });
            hideLoading();
        } else {
            toast.error(t('Please input code'));
        }
    };

    return (
        <Modal center={true} open={modal} onClose={() => setModal(false)}>
            <S.AuthContent>
                <S.Letter>
                    {t(
                        'Please enter secret code we have sent you at your email address'
                    )}{' '}
                    {email}.
                </S.Letter>
                <S.ReactCodeInputWrapp>
                    <ReactCodeInput
                        name="code"
                        type="text"
                        fields={6}
                        // inputMode="verbatim"
                        onChange={(e) => setCode(e)}
                    />
                </S.ReactCodeInputWrapp>
                <S.AccessButton1 onClick={() => register()}>
                    {t('Submit')}
                </S.AccessButton1>
                <S.Redirect
                    onClick={() => {
                        history.push('/support');
                        setModal(false);
                    }}
                >
                    {t('Need Help ?')}
                </S.Redirect>
            </S.AuthContent>
        </Modal>
    );
};

export default Register;
