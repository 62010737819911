import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';

import { useTournamentStore } from '../../../zustand/TournamentZustand';
import useBaseZustand from '../../../zustand/BaseZustand';
import useAuthZustand from '../../../zustand/AuthZustand';
import { Root } from '../../../config/config';

import {
    MainSidebarChapterWrapper,
    MainSidebarItem,
    MainSidebarWrapper,
    OpenCloseButtonWrapper,
    MainSidebarScroll
} from './style';
import { ISidebarProps } from './interface';
import { useTheme } from 'styled-components';
import { Theme } from '../../../theme';

const CollapseSidebar = ({ handleChooseItem, currentItem }: ISidebarProps) => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();
    const { sidebarTournaments } = useTournamentStore((state) => state);
    const { isDesktopSidebar, setDesktopSidebar } = useBaseZustand(
        (state) => state
    );
    const { mainMenuData } = useAuthZustand((state) => state);

    const handleOpen = () => {
        if (isDesktopSidebar === true) {
            setDesktopSidebar(false);
        } else {
            setDesktopSidebar(!isDesktopSidebar);
        }
    };

    return (
        <MainSidebarWrapper open={isDesktopSidebar}>
            <OpenCloseButtonWrapper open={isDesktopSidebar}>
                <IconButton onClick={handleOpen}>
                    <Tooltip
                        title={t('Extent Sidebar')}
                        placement="right-start"
                    >
                        <ArrowForward sx={{ color: theme.text }} />
                    </Tooltip>
                </IconButton>
            </OpenCloseButtonWrapper>
            <MainSidebarScroll open={isDesktopSidebar}>
                {mainMenuData.map((item, key) => (
                    <Fragment key={key}>
                        {item.data
                            .filter((it) => it.sidebar === true)
                            .map((menuItem, menuKey) => (
                                <Fragment key={menuKey}>
                                    <Tooltip
                                        title={t(menuItem.name)}
                                        placement="right-start"
                                    >
                                        {menuItem.name === 'Tournaments' ? (
                                            <MainSidebarItem
                                                url={
                                                    currentItem ===
                                                        menuItem.url &&
                                                    currentItem
                                                }
                                            >
                                                <div
                                                    onClick={() =>
                                                        handleChooseItem(
                                                            menuItem.url
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={`${Root.adminWebsiteUrl}${menuItem.image}`}
                                                        alt=""
                                                    />
                                                    <Badge
                                                        badgeContent={
                                                            sidebarTournaments.length
                                                        }
                                                    ></Badge>
                                                </div>
                                            </MainSidebarItem>
                                        ) : (
                                            <MainSidebarItem
                                                url={
                                                    currentItem ===
                                                        menuItem.url &&
                                                    currentItem
                                                }
                                                onClick={() =>
                                                    handleChooseItem(
                                                        menuItem.url
                                                    )
                                                }
                                            >
                                                <img
                                                    src={`${Root.adminWebsiteUrl}${menuItem.image}`}
                                                    alt=""
                                                />
                                            </MainSidebarItem>
                                        )}
                                    </Tooltip>
                                </Fragment>
                            ))}
                        <MainSidebarChapterWrapper open={isDesktopSidebar} />
                    </Fragment>
                ))}
            </MainSidebarScroll>
        </MainSidebarWrapper>
    );
};

export default CollapseSidebar;
