import styled from 'styled-components';
import { TournamentCurrentItem, TournamentItemTitle } from '../style';
import { TournamentBonusTitle } from '../TournamentBonus/TournamentBonus';

export const TournamentBonusCardWrapper = styled(TournamentCurrentItem)`
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: rgb(39, 42, 55);
`;

export const TournamentBonusCartTextWrapper = styled.div`
    width: 100%;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    margin: 20px;
    z-index: 10;
    text-align: start;
`;

export const TournamentBonusCardBonusNameWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px 0;
    & ${[TournamentItemTitle]} {
        margin: 0;
    }
    ,
    & ${TournamentBonusTitle} {
        margin: 0;
        @media (max-width: 500px) {
            font-size: 30px;
        }
    }
`;

export const TournamentBonusCardBonusName = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const TournamentBonusCardButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    gap: 10px;
    flex-wrap: wrap;
    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
