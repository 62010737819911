import { TournamentTimerItem, TournamentTimerWrapper } from './style';
import { useTimer } from 'react-timer-hook';
import { useTranslation } from 'react-i18next';

const TournamentPageTimer = ({ time, onExpire }: any) => {
    const { t } = useTranslation();

    const isInvalidDate = !time.getDate || isNaN(time.getTime());

    const { seconds, minutes, hours, days } = useTimer({
        expiryTimestamp: isInvalidDate ? new Date() : time,
        onExpire: isInvalidDate ? () => { } : onExpire
    });

    return (
        <TournamentTimerWrapper>
            <TournamentTimerItem>
                {t('DAYS')}
                <span>{days}</span>
            </TournamentTimerItem>
            <TournamentTimerItem>
                {t('HOURS')}
                <span>{hours}</span>
            </TournamentTimerItem>
            <TournamentTimerItem>
                {t('MINS')}
                <span>{minutes}</span>
            </TournamentTimerItem>
            <TournamentTimerItem>
                {t('SECS')}
                <span>{seconds}</span>
            </TournamentTimerItem>
        </TournamentTimerWrapper>
    );
};

export default TournamentPageTimer;
