import { useState } from 'react';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Footer from '../MainFooter';
import { SportFooter, FooterToggle } from './style';

const SportFooterPage = () => {
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    return (
        <SportFooter>
            <FooterToggle onClick={toggleShow}>
                {show ? <ArrowDropDown /> : <ArrowDropUp />}
            </FooterToggle>
            <Footer show={show} />
        </SportFooter>
    );
};

export default SportFooterPage;
