import styled from 'styled-components';

export const TopId = styled.div.attrs((props: any) => ({
    open: props.open
}))`
    width: 100%;
    height: calc(100% - ${({ theme }) => theme.headerHeight});
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: ${({ theme }) => theme.headerHeight};
    background: ${(props) => props.theme.background.primary};
    position: relative;
    transition: all 0.1s;
    @media only screen and (max-width: 1200px) {
        height: calc(100% - ${({ theme }) => theme.headerHeight} - 45px);
        margin-top: ${({ theme }) => theme.headerHeight};
        background: ${(props) => props.theme.background.primary};
    }
`;

export const ProfileLayout = styled.div`
    display: flex;
`;
