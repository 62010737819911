import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export const AppInstallWrapper = styled(Box)<{ page: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${(props) =>
          props.page === 'sport'
                  ? `calc(100% - ${(props) => (props.open ? '240px' : '60px')})`
                  : '100%'};
    padding: 0 7px 0 35px;
    height: 64px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.background.secondary};
`;

export const AppInstallContent = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  
    & span {
      font-family: PT Sans;
      color: ${({ theme }) => theme.text};
    }
`;

export const AppInstallCloseButtonWrapper = styled(Container)`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 7px;
    margin: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.text};
`;

export const Modal = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const ModalHeader = styled.p`
    font-size: 26px;
    user-select: none;
    text-align: center;
    font-family: PT Sans;
    color: ${({ theme }) => theme.text};
`;
export const ModalLetter = styled.p`
    display: flex;
    align-items: center;
    font-size: 14px;
    user-select: none;
    font-family: PT Sans;
    color: ${({ theme }) => theme.text};
    margin: 7px 0;
    & > svg {
      margin-left: 5px;
    }
`;
