import { Dispatch, ReactText, SetStateAction, useContext, useEffect, useState } from 'react';
import { getPerPage } from '../pages/game/helpers';
import { IGetGamesResponse } from '../types';
import { GameItemData } from '../pages/game/types';
import { LoadingContext } from '../layout/context/loading';

interface IParams {
    getStaticTournamentGames: (page, perPage, t_id) => Promise<IGetGamesResponse | ReactText>;
}

interface IResult {
    currentTournamentGames: GameItemData[];
    setCurrentPage: Dispatch<SetStateAction<number>>;
    hasMoreGames: boolean;
}

const useStaticTournamentGamesInfiniteScroll = ({ getStaticTournamentGames }: IParams, t_id): IResult => {
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = getPerPage();
    const [currentTournamentGames, setCurrentTournamentGames] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const hasMoreGames = currentTournamentGames.length < totalCount;

    useEffect(() => {
        setCurrentPage(0)
        setCurrentTournamentGames([])
    }, [t_id]);

    useEffect(() => {
        const refreshTournamentGames = async () => {
            showLoading();
            const result = await getStaticTournamentGames(currentPage, perPage, t_id);
            if (typeof result === 'object' && result.data && result.count) {
                setCurrentTournamentGames(
                    currentPage === 0
                        ? result.data
                        : (prevGames) => [...prevGames, ...result.data]
                );
                setTotalCount(result.count);
            }
            hideLoading();
        }
        refreshTournamentGames();
    }, [currentPage, perPage, t_id]);

    return {
        currentTournamentGames,
        setCurrentPage,
        hasMoreGames
    }
}

export default useStaticTournamentGamesInfiniteScroll;
