import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const getBackgroundColor = (place?: any) => {
    if (place === 1) {
        return 'rgba(255, 203, 0, 0.79)';
    } else if (place === 2) {
        return 'rgb(119, 118, 118)';
    } else if (place === 3) {
        return 'rgb(133, 40, 40)';
    } else if (place > 3) {
        return 'rgb(71, 78, 106)';
    } else {
        return '';
    }
};
export const TournamentLeaderBoardTableWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

export const TournamentLeaderBoardWrapper = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 10;
    padding: 0 20px;

    @media (max-width: 500px) {
        max-width: unset;
        padding: 0;
    }
`;

export const TournamentLeaderBoardTableCell = styled(TableCell)<{
    place?: any;
}>`
    background-color: ${({ place }) => getBackgroundColor(place)};
    font-weight: ${({ place }) => (place <= 3 ? 800 : 500)};
    font-size: ${({ place }) =>
        place <= 3 ? '23px !important' : '14px !important'};
    color: #ffffff;
    padding: 0;

    &.head {
        background-color: rgb(39, 42, 55) !important;
        border-top: 1px solid rgba(255, 255, 255, 0.12);
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        color: white;
    }

    &.body {
        padding: 0;
    }
`;

export const TournamentLeaderBoardTableRow = styled(TableRow)`
    &:last-child td,
    &:last-child th {
        border: 0;
        padding: 0;
    }
`;

export const TournamentLeaderboardPaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: #ffffff;

    .paginationWrapper {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        & li {
            font-family: 'PT Sans', sans-serif;
            list-style: none;
            margin: 0;
            color: #ffffff;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & .active {
            background: ${({ theme }) => theme.primaryButton};
            border-radius: 50%;
        }
    }
`;

export const CurrentUserProgression = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    background: ${({ theme }) => theme.primaryButton};
    padding: 5px 0;
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;

    p {
        width: calc(100% / 3.5);
        margin: 0;
    }
`;
